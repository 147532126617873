import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import PreviewDocument from "customs/components/ScriptStep/PreviewDocument.jsx";
import helpers from "customs/helpers/helpers";
import axios from "../../axios/client-axios";
import axiosHelper from "axios/axiosHelper";
import ChangeApplicationStatusDialog from "customs/components/ChangeApplicationStatusDialog";
import AddApplicationPageNoteDialog from "customs/components/AddApplicationPageNoteDialog";
import FlagApplicationPageDialog from "customs/components/FlagApplicationPageDialog";
import ConfirmDialog from "customs/components/ConfirmDialog";
import AsicConfirmWithInputDialog from "customs/components/AsicConfirmWithInputDialog";
import ConfirmWithInputDialog from "customs/components/ConfirmWithInputDialog";
import CustomSignatoriesDialog from "customs/components/CustomSignatoriesDialog";
import moment from "moment";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import validations from "customs/helpers/validations";
import Select from "@material-ui/core/Select";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import EmojiFlags from "@material-ui/icons/EmojiFlags";

class ViewApplicationStatusDialog extends React.Component {

    constructor (props) {

        super(props);

        let initState = {
            "applicationView": false,
            "currentTab": 0,
            "previewDocumentModal": false,
            "previewDocumentValue": null,
            "previewDocumentName": null,
            "changeStatus": false,
            "application_status": null,
            "selected_application_id": null,
            "current_status": null,
            "application_details": "",
            "tr_color": "success",
            "confirmWithInputModal": false,
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": "",
            "verify_page": "",
            "verify_ubo_idx": "",
            "current_verified": "",
            "page_notes": null,
            "approval_message": [],
            "addNote": false,
            "new_note": "",
            "add_note_page": null,
            "flagPage": false,
            "flag_message": "",
            "is_flagged": "",
            "flag_page": null,
            "verify_pages": [],
            "pages_count": 0,
            "downloading_match_report": false,
            "match_report_viewed": false,

            "show_message_dialog": false,
            "message_dialog_title": "Message",
            "message_dialog_contents": null,
            "is_new_message": false,
            "show_new_message": null,
            "new_message_subject": '',
            "new_message_body": '',
            "new_message_attachments": null,
            "side_file_open": false,
            "request_fields": this.initRequestableFields(),
            "view_app_selected_request_fields": [],
            "changeStatusBtnDisabled": true,
            "change_status_current_status": "",
            "change_status_additional_fields_values": [],
            "change_status_message": "",
            "approved_signatory_type": 'bo1',
            "approved_signatory_email": '',
            "approved_signatory_name": '',
            "previewable_file_extensions": ["pdf", "png", "bmp", "jpg", "jpeg", "docx"],
            "img_file_extensions": ["png", "bmp", "jpg", "jpeg"],
            "application_message_sort": "received",
            "application_message_direction": "desc",

            "onboarding_name": "Nexxtap",
            "verified_fields": null,
            "edit_field": false,
            "edit_field_key": "",
            
            "customSignatoriesModal": false,
            "customSignatoriesModalTitle": "",
            "customSignatoriesModalMsg": "Are you sure?",

            "application_file_name": "Webgate_Merchant_Application_",
            "mccs_selected": null,
            "mccs_open": false,
            "filter": "",

            "checkStatusTimer": ''
        };
        let admin_app_id_state = this.getApplicationValues(initState);

        this.state = {
            ...initState,
            ...admin_app_id_state
        };
        this.changeStatus = this.changeStatus.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleStatusChangeClose = this.handleStatusChangeClose.bind(this);
        this.handleAddNote = this.handleAddNote.bind(this);
        this.checkIfVerified = this.checkIfVerified.bind(this);
        this.checkIfAllPagesAreVerified = this.checkIfAllPagesAreVerified.bind(this);
        this.checkIfMatchReportViewed = this.checkIfMatchReportViewed.bind(this);
        
        this.changeAppStatus = React.createRef();
        
        this.updateComponent = this.updateComponent.bind(this);
        window.updateComponent = this.updateComponent;
        this.callbackComponent = this.callbackComponent.bind(this);
        window.callbackComponent = this.callbackComponent;
        this.getApplicationValues = this.getApplicationValues.bind(this);
        this.callParentMethod = this.callParentMethod.bind(this);
        this.updateState = this.updateState.bind(this);
        this.handleFlagPage = this.handleFlagPage.bind(this);
        this.checkIfFlagged = this.checkIfFlagged.bind(this);
        this.checkIfPageIsFlagged = this.checkIfPageIsFlagged.bind(this);
        this.hasFlaggedPage = this.hasFlaggedPage.bind(this);
        this.renderValue = this.renderValue.bind(this);
        this.getRegistryValue = this.getRegistryValue.bind(this);
        this.getRegistry = this.getRegistry.bind(this);
        this.requestSignatureStatus = this.requestSignatureStatus.bind(this);
        this.requestProcessingSignatures = this.requestProcessingSignatures.bind(this);
        this.downloadDocument = this.downloadDocument.bind(this);
        this.previewDocument = this.previewDocument.bind(this);
        this.requestCreateCredentials = this.requestCreateCredentials.bind(this);
        this.checkSignificantName = this.checkSignificantName.bind(this);
        this.setUsersNotification = this.setUsersNotification.bind(this);
        this.showUsersNotification = this.showUsersNotification.bind(this);
        this.saveEditedFieldFromStatus = this.saveEditedFieldFromStatus.bind(this);
    }

    callParentMethod(action, params) {
        try {
            if (this.state.applicationView) window.opener.parentComponent(action, params);
        } catch(e) {
            this.setUsersNotification('Unable to process your action, please try again.', "danger");
        }
    }

    callbackComponent(callback, notification_state = null) {
        if (notification_state !== null) {
            this.setState(notification_state);
            this.showUsersNotification();
        }
        if (callback !== null) try {callback();} catch(e) {}
    }

    updateComponent() {
        this.updateState();
    }

    getApplicationValues(state) {
        var app_id = '';
        if (window.location.pathname.startsWith('/admin/application/')) {
            let id = window.location.pathname.replace('/admin/application/', '').replace('/' ,'');
            if (/^-?\d+$/.test(id)) app_id = id;
        }

        let props = localStorage.getItem('admin_app_' + app_id + '_state');
        props = props ? JSON.parse(props) : {};
        // localStorage.removeItem('admin_app_' + app_id + '_state');
        
        if (Object.keys(props).length > 0) {
            try {
                if (document.getElementById('view-application-dialog') !== null) {
                    this.setViewApplicationDialog(state.side_file_open);
                }
            } catch(e) {}

            if ((props.application_status ? props.application_status : null) !== state.application_status) {
                props['application_status'] = props.application_status ? props.application_status : state.application_status;
            } else {
                props['application_status'] = state.application_status;
            }
            if ((props.selected_application_id ? props.selected_application_id : null) !== state.selected_application_id) {
                props['selected_application_id'] = props.selected_application_id ? props.selected_application_id : state.selected_application_id;
                props['currentTab'] = 0;
            } else {
                props['selected_application_id'] = state.selected_application_id;
            }
            props["current_status"] = props.application_status ? props.application_status : state.current_status;
            props["application_details"] = props.application_details ? props.application_details : state.application_details;
            props["page_notes"] = props.page_notes ? props.page_notes : state.page_notes;
            props["approval_message"] = props.approval_message ? props.approval_message : state.approval_message;
            props["applicationView"] = props.applicationView ? props.applicationView : state.applicationView;
            if ((props.applicationView ? props.applicationView : null) === false) {
                try {
                    clearTimeout(this.timer);
                    this.timer = null;
                } catch(e) {}
            } else {
                if (this.timer === null || this.timer === undefined) {
                    this.timer = setTimeout(() => this.fetchApplication(false, 'messages'), 15000);
                }
            }

            let application_history = props.application_history ? props.application_history : state.application_history;
            application_history = application_history
                ? application_history.filter((history) => history.details !== null && history.details.hasOwnProperty('request_field_values'))
                : [];
            props['application_history'] = application_history;
            let has_requests = application_history.length > 0;
            let pages = [1, 2, 3, 4, 5, 6];
            let has_flagged_page = this.hasFlaggedPage();
            let escalated = this.isEscalated(has_flagged_page);
            if (has_flagged_page) {
                if (this.props.auth.user.role === 'admin' && escalated.escalated_to !== 'admin') {
                    has_flagged_page = false; // Not escalated for admins
                } else if (this.props.auth.user.role === 'tl' && escalated.escalated_to !== 'tl' && escalated.escalated_to !== 'admin') {
                    has_flagged_page = false; // Not escalated for tls
                }
            }
            if (has_flagged_page) pages.push('escalation');
            if (has_requests) pages.push('requested');
            props["verify_pages"] = pages;
            props["pages_count"] = pages.length + 2 + (props.updateApplication ? 1 : 0);

            if (state.downloading_match_report === true) {
                props["downloading_match_report"] = false;
            } else {
                props['match_report_viewed'] = false;
                try {
                    if ((props.selected_approval ? props.selected_approval : null) && (props.selected_approval.details ? props.selected_approval.details : null)) {
                        if (props.selected_approval.details.hasOwnProperty('match_report_viewed')) {
                            let viewers = Array.isArray(props.selected_approval.details.match_report_viewed)
                                ? props.selected_approval.details.match_report_viewed
                                : JSON.parse(props.selected_approval.details.match_report_viewed);
                            props['match_report_viewed'] = viewers.includes(this.props.auth.user.id);
                        }
                    }
                } catch(e) {}
            }
        }

        return props;
    }

    updateState() {
        let admin_app_id_state = this.getApplicationValues(this.state);
        this.setState({...admin_app_id_state});
    }

    setUsersNotification (msg, color) {
        this.setState({"doneModalMsg": msg, "tr_color": color});
        this.showUsersNotification();
    }

    hideNotification;
    showUsersNotification () {
        if (!this.state.tr) {
            this.setState({"tr": true});
        } else {
            clearTimeout(this.hideNotification);
        }
        this.setHideNotificationTimeout();
    }

    setHideNotificationTimeout () {
        this.hideNotification = setTimeout(
            () => {
                this.handleCloseNotification();
            },
            this.state.tr_color === "success" ? 5000 : 10000
        );
    }

    handleCloseNotification () {
        clearTimeout(this.hideNotification);
        this.setState({"tr": false,
            "doneModalMsg": ""});
    }

    onUpdateStatusClick = (e) => {
        if (this.state.approved_signatory_type === 'custom') {
            if (!this.state.approved_signatory_name || this.state.approved_signatory_name.length === 0) {
                this.setUsersNotification("Signatory name is invalid.", "danger");
                return;
            } else if (!validations.validateEmail(this.state.approved_signatory_email)) {
                this.setUsersNotification("Signatory email is invalid.", "danger");
                return;
            }
        }

        this.changeAppStatus.current.callUpdateStatus(e);
    };

    initRequestableFields() {
        let fields = [
            {'key': 'page_1', 'checked': false, 'name': 'Page 1', 'type': 'page_label'},
            // {'key': 'request_abn', 'checked': false, 'name': 'Australian Business Number (ABN)'},
            // {'key': 'request_business_type', 'checked': false, 'name': 'Entity Type'},
            {'key': 'request_legal_name', 'checked': false, 'name': 'Business Name'},
            // {'key': 'request_mcc', 'checked': false, 'name': 'Describe Business'},
            {'key': 'request_product_or_service_type', 'checked': false, 'name': 'Business Type'},
            {'key': 'request_product_or_service_value', 'checked': false, 'name': 'Business Category'},
            {'key': 'request_website', 'checked': false, 'name': 'Business Website'},
            {'key': 'request_facebook_profile', 'checked': false, 'name': 'Facebook Profile'},
            {'key': 'request_instagram_profile', 'checked': false, 'name': 'Instagram Profile'},
            {'key': 'request_legal_address_label', 'checked': false, 'name': 'Registered Business Address', 'type': 'label'},
            {'key': 'request_legal_address', 'checked': false, 'name': 'Address'},
            {'key': 'request_suburb', 'checked': false, 'name': 'City'},
            {'key': 'request_state', 'checked': false, 'name': 'State'},
            {'key': 'request_post_code', 'checked': false, 'name': 'Post Code'},
            {'key': 'request_other_address_label', 'checked': false, 'name': 'Mailing Address', 'type': 'label'},
            {'key': 'request_other_address', 'checked': false, 'name': '(MA) Address'},
            {'key': 'request_other_suburb', 'checked': false, 'name': '(MA) City'},
            {'key': 'request_other_state', 'checked': false, 'name': '(MA) State'},
            {'key': 'request_other_post_code', 'checked': false, 'name': '(MA) Post Code'},
            {'key': 'page_2', 'checked': false, 'name': 'Page 2', 'type': 'page_label'},
            {'key': 'request_bas_statements_key', 'checked': false, 'name': 'Most Recent Business Activity Statements'},
            {'key': 'request_tax_returns_key', 'checked': false, 'name': 'Most Recent Tax Return'},
            {'key': 'request_trust_fields', 'checked': false, 'name': 'Does your company structure have a trust?'},
            {'key': 'request_trust_deeds', 'checked': false, 'name': 'Trust Deed/s'},
            {'key': 'request_trust_name', 'checked': false, 'name': 'Trust Name'},
            {'key': 'request_terminated_merchant_facilities', 'checked': false, 'name': 'Have you ever had a merchant facility terminated?'},
            {'key': 'request_merchant_facilities', 'checked': false, 'name': 'Do you currently have any merchant facilities?'},
            {'key': 'request_recent_merchant_statements_key', 'checked': false, 'name': 'Last 3 months merchant statements'},
            {'key': 'request_estimated_annual_turnover', 'checked': false, 'name': 'Estimated Annual Card Revenue'},
            {'key': 'request_average_number_of_credit_card_sales_per_month', 'checked': false, 'name': 'Average number of credit card sales per month'},
            {'key': 'page_3', 'checked': false, 'name': 'Page 3', 'type': 'page_label'},
            {'key': 'request_card_present', 'checked': false, 'name': 'Percentage breakdown of card payments'},
            {'key': 'request_do_you_take_deposit', 'checked': false, 'name': 'Do you take deposits from your customer?'},
            {'key': 'request_how_long_from_the_time_of_deposit', 'checked': false, 'name': 'How long from the time of deposit payment are the goods/services received?'},
            {'key': 'page_4', 'checked': false, 'name': 'Page 4', 'type': 'page_label'},
            {'key': 'request_subscription_membership_transactions', 'checked': false, 'name': 'Do you take subscription/membership transactions?'},
            {'key': 'request_term_of_your_subscription', 'checked': false, 'name': 'Term of Subscription'},
            {'key': 'request_frequency_of_subscriptions', 'checked': false, 'name': 'Frequency of Subscriptions'},
            {'key': 'request_transaction_information_notes', 'checked': false, 'name': 'Subscription/Membership transaction notes'},
            {'key': 'request_facility_required', 'checked': false, 'name': 'Facility Required'},
            {'key': 'request_surcharge', 'checked': false, 'name': 'Surcharge'},
            {'key': 'page_5', 'checked': false, 'name': 'Page 5', 'type': 'page_label'},
            {'key': 'request_dc_funding_account_name_label', 'checked': false, 'name': 'Settlement Bank Account Information', 'type': 'label'},
            {'key': 'request_dc_funding_account_name', 'checked': false, 'name': 'Account Name'},
            {'key': 'request_dc_funding_bsb', 'checked': false, 'name': 'BSB'},
            {'key': 'request_dc_funding_account_number', 'checked': false, 'name': 'Account Number'},
            {'key': 'request_dc_funding_bank_name', 'checked': false, 'name': 'Bank Name'},
            {'key': 'request_dc_branch_address_label', 'checked': false, 'name': 'Bank Address', 'type': 'label'},
            {'key': 'request_dc_branch_address', 'checked': false, 'name': 'Address'},
            {'key': 'request_dc_city', 'checked': false, 'name': 'City'},
            {'key': 'request_dc_state', 'checked': false, 'name': 'State'},
            {'key': 'request_dc_post_code', 'checked': false, 'name': 'Post Code'},
            {'key': 'request_bank_statements_key', 'checked': false, 'name': 'Most Recent Bank Statement'},
            {'key': 'page_6', 'checked': false, 'name': 'Page 6', 'type': 'page_label'}
        ];
        for(let i=0; i<4; i++) {
            fields.push({
                'key': 'request_bo' + (i + 1).toString() + '_beneficial_owner_label', 'checked': false, 'name': 'Beneficial Owner ' + (i + 1).toString(), 'type': 'label',
                'action': 'checkAllBeneficials', 'index': i + 1
            });
            fields.push({
                'key': 'request_bo' + (i + 1).toString() + '_first_name', 'checked': false, 'name': '(BO' + (i + 1).toString() + ') First Name',
                'action': 'areAllBeneficialsChecked', 'index': i + 1
            });
            fields.push({
                'key': 'request_bo' + (i + 1).toString() + '_last_name', 'checked': false, 'name': '(BO' + (i + 1).toString() + ') Last Name',
                'action': 'areAllBeneficialsChecked', 'index': i + 1
            });
            fields.push({
                'key': 'request_bo' + (i + 1).toString() + '_position', 'checked': false, 'name': '(BO' + (i + 1).toString() + ') Position',
                'action': 'areAllBeneficialsChecked', 'index': i + 1
            });
            fields.push({
                'key': 'request_bo' + (i + 1).toString() + '_residential_address', 'checked': false, 'name': '(BO' + (i + 1).toString() + ') Address',
                'action': 'areAllBeneficialsChecked', 'index': i + 1
            });
            fields.push({
                'key': 'request_bo' + (i + 1).toString() + '_suburb', 'checked': false, 'name': '(BO' + (i + 1).toString() + ') City',
                'action': 'areAllBeneficialsChecked', 'index': i + 1
            });
            fields.push({
                'key': 'request_bo' + (i + 1).toString() + '_state', 'checked': false, 'name': '(BO' + (i + 1).toString() + ') State',
                'action': 'areAllBeneficialsChecked', 'index': i + 1
            });
            fields.push({
                'key': 'request_bo' + (i + 1).toString() + '_post_code', 'checked': false, 'name': '(BO' + (i + 1).toString() + ') Post Code',
                'action': 'areAllBeneficialsChecked', 'index': i + 1
            });
            fields.push({
                'key': 'request_bo' + (i + 1).toString() + '_mobile_number', 'checked': false, 'name': '(BO' + (i + 1).toString() + ') Contact Number',
                'action': 'areAllBeneficialsChecked', 'index': i + 1
            });
            fields.push({
                'key': 'request_bo' + (i + 1).toString() + '_email', 'checked': false, 'name': '(BO' + (i + 1).toString() + ') Email Address',
                'action': 'areAllBeneficialsChecked', 'index': i + 1
            });
        }

        return fields;
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }
    
    handleConfirmClick (message = '', data = null) {
        if (this.state.action === "set_verified") {
            this.doSetVerifyPage(this.state.verify_page, this.state.current_verified);
        } else if (this.state.action === 'generate_match_report') {
            this.doGenerateMatchReport();
        } else if (this.state.action === 'delete_message') {
            this.doDeleteMessage();
        } else if (this.state.action === 'send_message') {
            this.doSendMessage();
        } else if (this.state.action === 'send_id_verification_request') {
            this.doSendVerifyIdRequest();
        } else if (this.state.action === 'check_id_verification_status') {
            this.doCheckVerifyIdStatus();
        } else if (this.state.action === 'request_asic_search') {
            this.doRequestASICSearch(message, data);
        } else if (this.state.action === 'generate_application') {
            this.doRequestSignatures();
        } else if (this.state.action === 'generate_processing') {
            this.doRequestProcessingSignatures();
        } else if (this.state.action === 'request_create_credentials') {
            this.doRequestCreateCredentials();
        } else if (this.state.action === 'set_status' || this.state.action === 'add_note' || this.state.action === 'flag_note') {
            helpers.showLoading();
            this.setState({"confirmModal": false});
            let parent_params = this.state.parent_params;
            try {parent_params[1].push(message);} catch(e) {}
            this.callParentMethod(...parent_params);
        } else if (this.state.action === 'escalate_application') {
            this.doEscalateApplication();
        } else if (this.state.action === 'deescalate_application') {
            this.doDeescalateApplication();
        } else if (this.state.action === 'save_field_changes') {
            this.doSaveEditedField();
        }
    }

    composeStatusMessage(status, additional_fields, selected_request_fields) {
        let message = [];
        if (status === 'awaiting_customer_response') {
            message.push("Requested Fields:");
            this.state.available_fields.forEach(field => {
                additional_fields.forEach(request => {
                    if (field.registry_key === request.key) {
                        message.push("  " + field.name);
                    }
                });
            });
        } else {
            message.push("Incomplete Fields:");
            let page = 'Page 1', page_added = false;
            this.state.request_fields.forEach(field => {
                if (field.type === "page_label") {
                    page = field.name;
                    page_added = false;
                }
                for (var key of Object.keys(selected_request_fields)) {
                    if (field.key === selected_request_fields[key]) {
                        if (!page_added) {
                            message.push(page);
                            page_added = true;
                        }
                        if (field.type === 'label') {
                            message.push(" " + field.name);
                        } else {
                            message.push("  " + field.name);
                        }
                    }
                }
            });
        }
        return message.length > 0 ? message.join("\n") : "";
    }

    handleStatusChange(id, status, message, additional_fields_values, selected_request_fields, approved_signatory_type, approved_signatory_email, approved_signatory_name) {
        let additional_fields = [];
        for (var key of Object.keys(additional_fields_values)) {
            if (additional_fields_values[key] === 1) additional_fields.push({'key': key});
        }
        if (selected_request_fields && selected_request_fields.length > 0 && this.state.verified_fields) {
            Object.keys(this.state.verified_fields).forEach((field, index) => {
                if (selected_request_fields.includes('request_' + field) && this.state.verified_fields.hasOwnProperty(field) && parseInt(this.state.verified_fields[field]) === 1) {
                    selected_request_fields.splice(selected_request_fields.indexOf('request_' + field), 1);
                }
            });
        }
        if (status === 'awaiting_customer_response' && additional_fields.length === 0) {
            this.setUsersNotification("Please select at least one field to request from the merchant.", "danger");
        } else if (status === 'incomplete_-_please_provide_more_information' && selected_request_fields.length === 0) {
            this.setUsersNotification("Please select at least one field to request from the merchant.", "danger");
        } else {
            let request_fields = {};
            if (selected_request_fields.length > 0) {
                let initRequestableFields = this.initRequestableFields();
                initRequestableFields.forEach((field, index) => {
                    if (selected_request_fields.includes(field.key) && field.type !== 'label' && field.type !== 'page_label') {
                        request_fields[field.key] = {'label': field.name};
                    }
                });
            }

            if (status === 'awaiting_customer_response' || status === 'incomplete_-_please_provide_more_information') {
                this.setState({
                    "confirmModal": false,
                    "confirmWithInputModal": true,
                    "confirmModalMsg":  'Change status to ' + helpers.fixStatusLabel(status) + ".",
                    "confirmModalTitle": "Change Application Status",
                    "action": "set_status",
                    "confirmModalMsgBoxLbl": "Message",
                    "confirmModalMsgBoxOptional": false,
                    "confirmModalMsgBody": this.composeStatusMessage(status, additional_fields, selected_request_fields),
                    "confirmModalMsgRows": 10,
                    "confirmModalMinWidth": "70vw",
                    "confirmModalYesBtn": "Continue",
                    "confirmModalNoBtn": "Cancel",
                    "parent_params": ['handleStatusChange', [id, status, message, additional_fields_values, request_fields, approved_signatory_type, approved_signatory_email, approved_signatory_name, ()=>{
                        this.handleStatusChangeClose();
                        this.setState({
                            "currentTab": 0,
                            "changeStatusBtnDisabled": true,
                            "change_status_current_status": "",
                            "change_status_additional_fields_values": [],
                            "change_status_message": "",
                            "approved_signatory_type": 'bo1',
                            "approved_signatory_email": '',
                            "approved_signatory_name": '',
                            "additional_fields_values": [],
                            "message": "",
                            "side_file_open": false,
                            "view_app_selected_request_fields": [],
                            "request_fields": this.initRequestableFields(),
                            "confirmModalMsgBoxLbl": null,
                            "confirmModalMsgBoxOptional": true,
                            "confirmModalMsgBody": "",
                            "confirmModalYesBtn": "Yes",
                            "confirmModalNoBtn": "No",
                            "confirmModal": false,
                            "confirmWithInputModal": false,
                        }, function () {
                            helpers.showLoading();
                            clearTimeout(this.timer);
                            this.timer = null;
                            this.fetchApplication(true, 'messages');
                        });
                    }]]
                });
            } else {
                this.setState({
                    "confirmModal": true,
                    "confirmModalMsg": 'Change status to ' + helpers.fixStatusLabel(status) + ". Are you sure?",
                    "confirmModalTitle": "Change Application Status",
                    "action": "set_status",
                    "parent_params": ['handleStatusChange', [id, status, message, additional_fields_values, request_fields, approved_signatory_type, approved_signatory_email, approved_signatory_name, ()=>{
                        this.handleStatusChangeClose();
                        this.setState({
                            "currentTab": 0,
                            "changeStatusBtnDisabled": true,
                            "change_status_current_status": "",
                            "change_status_additional_fields_values": [],
                            "change_status_message": "",
                            "approved_signatory_type": 'bo1',
                            "approved_signatory_email": '',
                            "approved_signatory_name": '',
                            "additional_fields_values": [],
                            "message": "",
                            "side_file_open": false,
                            "view_app_selected_request_fields": [],
                            "request_fields": this.initRequestableFields(),
                            "confirmModal": false,
                            "confirmWithInputModal": false
                        }, function () {
                            helpers.showLoading();
                            clearTimeout(this.timer);
                            this.timer = null;
                            this.fetchApplication(true, 'messages');
                        });
                        // clearTimeout(this.timer);
                        // this.timer = null;
                        // window.close();
                    }]]
                });
            }
        }
    }

    handleStatusChangeClose () {
        this.setState({
            "changeStatus": false,
            "application_status": this.state.application_status,
            "selected_application_id": this.state.selected_application_id,
            "select_new_status": ''
        });
    }

    handlePageNoteClose () {
        this.setState({
            "addNote": false,
            "new_note": "",
            "add_note_page": null
        });
    }

    handleAddNote(id, message, page) {
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": "Are you sure?",
            "confirmModalTitle": "Add Note",
            "action": "add_note",
            "parent_params": ['handleAddNote', [id, message, page, ()=>{
                this.handlePageNoteClose();
                this.setState({"new_note": "", "add_note_page": null});
                helpers.hideLoading();
            }]]
        });
    }

    setViewApplicationDialog(full = false) {
        try {
            if (document.getElementById('view-application-dialog') !== null) {
                document.getElementById('view-application-dialog').querySelector('.MuiDialog-paperWidthSm').style.maxWidth = full ? '100%' : '950px';
                document.getElementById('view-application-dialog').querySelector('.MuiDialog-paperWidthSm').style.height = '95vh';
            }
        } catch(e) {}
    }

    componentWillReceiveProps(props) {
        this.updateState();
    }
    
    fetchApplication(hideLoading = false, action = null) {
        if (this.state.applicationView === true) {
            this.callParentMethod('viewApplication', [action, this.state.selected_approval, this.state.application_status, false, () => {
                clearTimeout(this.timer);
                this.timer = null;
                this.timer = setTimeout(() => this.fetchApplication(false, 'messages'), 15000);
                if (hideLoading) helpers.hideLoading();
            }, this.state.application_message_direction, this.state.application_message_sort]);
        } else {
            try {
                clearTimeout(this.timer);
                this.timer = null;
            } catch(e) {}
        }
    }

    changeStatus (event, select_new_status = '') {
        event.preventDefault();
        this.setState({
            "changeStatus": true,
            "application_status": this.state.application_status,
            "selected_application_id": this.state.selected_application_id,
            "select_new_status": select_new_status
        });
    }

    previewDocumentDialog(id, file_name) {
        this.setState({
            "previewDocumentModal": true,
            "previewDocumentValue": id,
            "previewDocumentName": file_name
        });
    }

    previewDocument(id, file_name, url = null, callback = null, updateFieldCheckbox = null) {
        helpers.showLoading();
        axios(this.props)
          .get(
                url === null ? 'client/' + this.state.selected_client_id + '/file/' + id + '/download?convert=1&application_file=1' : url,
                {
                    "responseType": "blob",
                    "timeout": 30000
                }
            )
            .then((response) => {
                var urlCreator = window.URL || window.webkitURL;
                var blob;
                var file;
                if (this.state.img_file_extensions.includes(file_name.split('.').pop().toLowerCase())) {
                    var image = urlCreator.createObjectURL(response.data);
                    blob = new Blob(['<img width="100%" src="' + image + '" style="margin: 0;">'], {type: "text/html"});
                    file = urlCreator.createObjectURL(blob);
                } else {
                    file = urlCreator.createObjectURL(response.data);
                }
                // document.querySelector("iframe").src = file;
                document.getElementById("preview").src = file;
                helpers.hideLoading();
                
                if (callback !== null) try {callback();} catch(e) {}
                if (updateFieldCheckbox !== null) try {updateFieldCheckbox();} catch(e) {}
                this.setState({side_file_open: true});
                this.setViewApplicationDialog(true);
            })
            .catch((error) => {
                helpers.hideLoading();
            });
    }

    downloadDocument(id, filename, url = null, callback = null, updateFieldCheckbox = null) {
        const FileDownload = require("js-file-download");
        helpers.showLoading();
        axios(this.props)
          .get(
                url === null ? 'client/' + this.state.selected_client_id + '/file/' + id + '/download?application_file=1' : url,
                {
                    "responseType": "blob",
                    "timeout": 30000
                }
            )
            .then((response) => {
                helpers.hideLoading();
                if (this.state.previewable_file_extensions.includes(filename.split('.').pop().toLowerCase())) {
                    var fileURL = window.URL.createObjectURL(response.data);
                    let tab = window.open();
                    tab.location.href = fileURL;
                } else {
                    FileDownload(
                        response.data,
                        filename
                    );
                }
                if (callback !== null) try {callback();} catch(e) {}
                if (updateFieldCheckbox !== null) try {updateFieldCheckbox();} catch(e) {}
            })
            .catch((error) => axiosHelper.processError(
                this.isUnmounted,
                null,
                error,
                (state) => {this.setState(state);},
                () => {}
            ));
    }

    setCurrentTab(tab) {
        if (tab === 6 && this.state.currentTab !== tab) {
            let has_flagged_page = this.hasFlaggedPage();
            let escalated = this.isEscalated(has_flagged_page);
            if (has_flagged_page) {
                if (this.props.auth.user.role === 'admin' && escalated.escalated_to !== 'admin') {
                    has_flagged_page = false; // Not escalated for admins
                } else if (this.props.auth.user.role === 'tl' && escalated.escalated_to !== 'tl' && escalated.escalated_to !== 'admin') {
                    has_flagged_page = false; // Not escalated for tls
                }
            }
            if (has_flagged_page) {
                let details = this.state.selected_approval !== null && this.state.selected_approval.hasOwnProperty('details') ? this.state.selected_approval.details : {};
                details = details !== null && details.hasOwnProperty('escalation') && details.escalation.hasOwnProperty('messages') ? JSON.parse(JSON.stringify(details.escalation.messages)) : {};
                let latest = null;
                try {latest = details.reduce((a, b) => {return a.id > b.id ? a : b;});} catch(e) {latest = null;}
                let latest_add_message = latest !== null && latest.hasOwnProperty('add_message') && latest.add_message !== null && latest.add_message !== 'null' ? (latest.add_message + "\n") : '';
                if (!this.state.escalation_message || this.state.escalation_message.length === 0 || this.state.escalation_message === latest_add_message) {
                    this.setState({escalation_message: latest_add_message});
                }
            }
        }

        this.setState({currentTab: tab}, function() {
            let tabs = [
                'page1_fields','page2_fields','page3_fields','page4_fields','page5_fields','page6_fields','escalation_page_fields',
                'request_page_fields','notes_page_fields','messages_page_fields','change_status_page_fields'
            ];
            tabs.forEach(function(tab) {
                try {
                    if (document.getElementById(tab)) {
                        document.getElementById(tab).parentElement.parentElement.scrollTop = 0;
                    }
                } catch(e) {}
            });
        });
    }

    updateRegistry(registry_key, registry) {
        let registry_entries = this.state.registry_entries;
        let found = false;
        registry_entries.forEach(field => {
            if (field.registry_key === registry_key) {
                field.updated_at = registry.updated_at;
                field.client_assigned = registry.client_assigned;
                field.value = registry.value;
                found = true;
            }
        });
        if (!found) {
            registry_entries.push(registry);
        }
        this.setState({"registry_entries": registry_entries});
    }

    updateMultipleRegistry(registries) {
        let registry_entries = this.state.registry_entries;
        let found = false;
        registries.forEach(registry => {
            found = false;
            registry_entries.forEach(field => {
                if (field.registry_key === registry.registry_key) {
                    field.updated_at = registry.updated_at;
                    field.client_assigned = registry.client_assigned;
                    field.value = registry.value;
                    found = true;
                }
            });
            if (!found) {
                registry_entries.push(registry);
            }
        });
        this.setState({"registry_entries": registry_entries});
    }

    getRegistry(registry_key) {
        let registry = null;
        this.state.registry_entries.forEach(field => {
            if (field.registry_key === registry_key) {
                registry = field;
            }
        });
        return registry;
    }

    getRegistryValue(registry_key) {
        let registry = this.getRegistry(registry_key);
        if (registry !== null) {
            registry = {
                'value': registry.value,
                'client_assigned': registry.client_assigned
            };
        }
        return registry;
    }

    renderCustomFieldLabel(key) {
        let label = '';
        this.state.available_fields.forEach(field => {
            if (field.registry_key === key) {
                label = field.configuration.properties.title;
            }
        });
        return label;
    }

    renderValue(key) {
        let registry = this.getRegistryValue(key);
        return registry ? registry['value'] : '';
    }

    renderMCC(key) {
        let registry = this.getRegistryValue(key);
        let mcc = this.state.mccs.filter((mcc) => mcc.code === registry['value']);
        return mcc.length > 0 ? ('(' + registry['value'] + ') ' + mcc[0]['title']) : '';
    }

    getMCCCode(key) {
        let registry = this.getRegistryValue(key);
        if (registry === null) return '';
        let mcc = this.state.mccs.filter((mcc) => mcc.code === registry['value']);
        return mcc.length > 0 ? mcc[0].code : ''
    }

    setMccTooltip(mcc) {
        let tooltip = '', addNL = '';
        if (mcc.description !== undefined && mcc.description !== null && mcc.description.length > 0) {
            tooltip = 'Description: ' + mcc.description;
            addNL = '\n\n';
        }
        if (mcc.inclusions !== undefined && mcc.inclusions !== null && mcc.inclusions.length > 0) {
            tooltip += addNL + 'Inclusions: ' + mcc.inclusions;
        }
        if (mcc.similar !== undefined && mcc.similar !== null && mcc.similar.length > 0) {
            tooltip += addNL + 'Similar: ' + mcc.similar;
        }
        return tooltip;
    }

    filterMCCs() {
      return this.state.filter.length > 0
          ? this.state.mccs.filter((mcc) => {
              let filter = this.state.filter.toLowerCase();
              return mcc.code.toLowerCase().includes(filter) ||
                  mcc.title.toLowerCase().includes(filter) ||
                  mcc.description.toLowerCase().includes(filter) ||
                  mcc.inclusions.toLowerCase().includes(filter) ||
                  mcc.similar.toLowerCase().includes(filter)
          })
          : this.state.mccs;
    }

    // Checkbox now used to mark if field is verified, not for request anymore
    renderRequestFieldCheckbox(key, classes) {
        let disabled = false;
        if (this.state.application_status === 'approved' || this.state.application_status === 'conditional_approved' || this.state.application_status === 'declined') {
            disabled = true;
        }

        let field_key = key.replace('request_', '');
        let verified_fields = this.state.verified_fields;
        if (verified_fields === null) {
            verified_fields = this.getRegistryValue('verified_fields');
            verified_fields = verified_fields ? verified_fields['value'] : '{}';
            setTimeout(() => this.setState({'verified_fields': JSON.parse(verified_fields)}), 100);
        }
        let field_verified = verified_fields.hasOwnProperty(field_key) && parseInt(verified_fields[field_key]) === 1;

        return <input style={{marginLeft: "12px", width: "20px", height: "20px"}} className="tick-box-field-completion"
            type="checkbox" name={key} id={key}
            checked={field_verified}
            disabled={disabled}
            onChange={(e) => {
                this.saveRequestFieldCheckboxValue(field_key, e.target.checked);
            }}
            />;

        // let request_field = null, request_field_index = null;
        // this.state.request_fields.forEach((field, index) => {
        //     if (field.key === key) {
        //         request_field = field;
        //         request_field_index = index;
        //     }
        // });

        // return <input style={{marginLeft: "12px"}}
        //     type="checkbox" name={key} id={key} title="Request answer to this field"
        //     checked={request_field !== null && request_field.hasOwnProperty('checked') && request_field.checked}
        //     onChange={(e) => {
        //         if (request_field !== null) {
        //             var requests = this.state.request_fields;
        //             requests[request_field_index]['checked'] = e.target.checked;
        //             let view_app_selected_request_fields = [];
        //             requests.forEach((field, index) => {
        //                 if (field.checked) view_app_selected_request_fields.push(field.key);
        //             });
        //             this.setState({"request_fields": requests, "view_app_selected_request_fields": view_app_selected_request_fields});
        //         }
        //     }}
        //     />;
    }

    // setMccRiskFlag(selected_mcc) {
    //     if (!selected_mcc) {
    //         return <span title={'N/A'}>
    //             <EmojiFlags style={{fontSize: "18px", color: 'silver', marginLeft: "11px"}} />
    //         </span>;
    //     }

    //     let data = this.state.mccs.filter((mcc) => mcc.code === selected_mcc);
    //     if (data.length === 0) return '';
    //     data = data[0];
    //     let risk = data.restriction === 'prohibited' ? 'red' : (
    //         data.restriction === 'restricted' ? 'orange' : (
    //             data.restriction === 'restricted' ? 'orange' : 'green'
    //         )
    //     );
    //     return <span title={risk === 'red' ? 'High Risk' : (risk === 'orange' ? 'Medium Risk' : (risk === 'green' ? 'Low Risk' : 'N/A'))}>
    //         <EmojiFlags style={{fontSize: "18px", color: risk ? risk : 'green', marginLeft: "11px"}} />
    //     </span>;
    // }

    checkToSaveRequestFieldCheckboxValue(field_key, value) {
        let verified_fields = this.state.verified_fields;
        if (verified_fields === null) {
            verified_fields = this.getRegistryValue('verified_fields');
            verified_fields = verified_fields ? verified_fields['value'] : '{}';
            setTimeout(() => this.setState({'verified_fields': JSON.parse(verified_fields)}), 100);
        }
        let field_verified = verified_fields.hasOwnProperty(field_key) && parseInt(verified_fields[field_key]) === 1;
        if (!field_verified) this.saveRequestFieldCheckboxValue(field_key, true);
    }

    saveRequestFieldCheckboxValue(key, value) {
        this.setState({"confirmModal": false});
        const url = "/applications/" + this.state.selected_application_id + "/page/field/verification",
            postObject = {"field": key, "verified" : value ? 1 : 0};

        helpers.showLoading();
        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then((response) => {
                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('registry')) {
                    this.setState({'verified_fields': typeof response.data.registry === 'string' ? JSON.parse(response.data.registry) : response.data.registry});
                }
                helpers.hideLoading();
            })
            .catch((error) => {
                console.log(error);
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    escalateApplication() {
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": "Are you sure?",
            "confirmModalTitle": "Escalate Application",
            "action": "escalate_application"
        });
    }

    doEscalateApplication() {
        this.setState({"confirmModal": false});
        const url = "/applications/" + this.state.selected_application_id + '/escalate';
        helpers.showLoading();
        let message = this.collateFlagReasons();
        return axios(this.props)
            .post(
                url,
                {
                    'message': message.join("\n"),
                    'add_message': this.state.escalation_message ? this.state.escalation_message : ''
                }
            )
            .then((response) => {
                this.setState({
                    "confirmModal": false,
                    "action": ""
                });
                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('error')) {
                    this.setUsersNotification(
                        typeof response.data.error === 'object' || Array.isArray(response.data.error)
                        ? (
                            response.data.error.hasOwnProperty('message')
                            ? response.data.error.message
                            : "Unable to send Verify ID request, please try again."
                        )
                        : (
                            typeof response.data.error === 'string'
                            ? response.data.error
                            : "Unable to send Verify ID request, please try again."
                        ),
                        "danger"
                    );
                } else {
                    if (response.hasOwnProperty('data') && response.data.hasOwnProperty('details')) {
                        let approval = this.state.selected_approval;
                        approval['details'] = response.data.details;
                        this.setState({'selected_approval': approval});
                    }
                    this.setUsersNotification("Application escalated.", "success");
                }
                this.fetchApplication(true, 'escalate_application');
            })
            .catch((error) => {
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    deescalateApplication() {
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": "Are you sure?",
            "confirmModalTitle": this.props.auth.user.role === 'admin' ? "De-escalate Application" : "De-escalate & Unflag Application",
            "action": "deescalate_application"
        });
    }

    doDeescalateApplication() {
        this.setState({"confirmModal": false});
        const url = "/applications/" + this.state.selected_application_id + '/deescalate';
        helpers.showLoading();
        let message = this.collateFlagReasons();
        return axios(this.props)
            .post(
                url,
                {
                    'message': message.join("\n"),
                    'add_message': this.state.escalation_message ? this.state.escalation_message : ''
                }
            )
            .then((response) => {
                this.setState({
                    "confirmModal": false,
                    "action": ""
                });
                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('error')) {
                    this.setUsersNotification(
                        typeof response.data.error === 'object' || Array.isArray(response.data.error)
                        ? (
                            response.data.error.hasOwnProperty('message')
                            ? response.data.error.message
                            : "Unable to send Verify ID request, please try again."
                        )
                        : (
                            typeof response.data.error === 'string'
                            ? response.data.error
                            : "Unable to send Verify ID request, please try again."
                        ),
                        "danger"
                    );
                } else {
                    if (response.hasOwnProperty('data') && response.data.hasOwnProperty('details')) {
                        let approval = this.state.selected_approval;
                        approval['details'] = response.data.details;
                        this.setState({'selected_approval': approval});
                    }
                    this.setUsersNotification("Application deescalated.", "success");
                }
                this.fetchApplication(true, 'deescalate_application');
            })
            .catch((error) => {
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    saveEditedFieldFromStatus(key, value, old_value, callback) {
        let allow = this.props.auth.user.role === 'tl' || this.props.auth.user.role === 'admin';
        if (allow) {
            if (old_value === value) {
                this.setUsersNotification("The value did not change.", "warning");
                return false;
            }

            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Save field changes",
                "action": "save_field_changes",
                "save_field_change_key": key,
                "save_field_change_key_value": typeof value === 'object' || Array.isArray(value) ? value.join(',') : value,
                "save_field_change_key_callback": callback
            });
        }
    }

    saveEditedField(key, type) {
        let value = this.state.edit_field_key_value;
        let allow = this.props.auth.user.role === 'tl' || this.props.auth.user.role === 'admin' ||  
            (this.props.auth.user.role === 'vc' && ["mcc", "other_mcc", "product_or_service_type", "product_or_service_value", "applicant_importance_grade"].includes(key));
        if (allow) {
            if (type === 'CustomInput') {
                if (this.renderValue(key) === value) {
                    this.setUsersNotification("The value did not change.", "warning");
                    return false;
                }
                
                this.setState({
                    "confirmModal": true,
                    "confirmModalMsg": "Are you sure?",
                    "confirmModalTitle": "Save field changes",
                    "action": "save_field_changes",
                    "save_field_change_key": key,
                    "save_field_change_key_value": value,
                    "save_field_change_key_callback": null
                });
            }
        }
    }

    doSaveEditedField() {
        this.setState({"confirmModal": false});
        const url = "/applications/" + this.state.selected_application_id + '/update/field';
        helpers.showLoading();
        return axios(this.props)
            .post(
                url,
                {
                    'key': this.state.save_field_change_key ? this.state.save_field_change_key : '',
                    'value': this.state.save_field_change_key_value ? this.state.save_field_change_key_value : ''
                }
            )
            .then((response) => {
                this.setState({
                    "confirmModal": false,
                    "action": "",
                    "edit_field": false,
                    "edit_field_key": ""
                });
                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('error')) {
                    this.setUsersNotification(
                        typeof response.data.error === 'object' || Array.isArray(response.data.error)
                        ? (
                            response.data.error.hasOwnProperty('message')
                            ? response.data.error.message
                            : "Unable to update field value, please try again."
                        )
                        : (
                            typeof response.data.error === 'string'
                            ? response.data.error
                            : "Unable to update field value, please try again."
                        ),
                        "danger"
                    );
                } else {
                    if (response.hasOwnProperty('data') && response.data.hasOwnProperty('registry') && response.data.registry !== null) {
                        this.updateRegistry(this.state.save_field_change_key, response.data.registry);
                    }
                    if (response.hasOwnProperty('data') && response.data.hasOwnProperty('registries') && response.data.registries !== null) {
                        this.updateMultipleRegistry(response.data.registries);
                    }
                    try {
                        if (this.state.save_field_change_key_callback !== undefined && this.state.save_field_change_key_callback !== null) {
                            this.state.save_field_change_key_callback();
                            this.setState({"save_field_change_key_callback": null});
                        }
                    } catch(e) {}
                }
                helpers.hideLoading();
            })
            .catch((error) => {
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    renderEditField(key, value, type) {
        if (this.state.application_status === 'approved' || this.state.application_status === 'conditional_approved' || this.state.application_status === 'declined') {
            return '';
        }
        let allow = this.props.auth.user.role === 'tl' || this.props.auth.user.role === 'admin' ||  
            (this.props.auth.user.role === 'vc' && ["mcc", "other_mcc", "product_or_service_type", "product_or_service_value", "applicant_importance_grade"].includes(key));
        if (allow) {
            if (type === 'CustomInput') {
                return this.state.edit_field && this.state.edit_field_key === key
                    ? <span><i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-save"} title="Save changes" onClick={() => {
                        this.saveEditedField(key, type);
                    }} /><i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-times"} title="Cancel edit" onClick={() => {
                        this.setState({'edit_field': false, 'edit_field_key': '', 'edit_field_key_value': '', 'mccs_selected': null})
                    }} /></span>
                    : <i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-edit"} title="Edit this field" onClick={() => {
                        let new_value = '';
                        let old_value = '';
                        if (this.state.edit_field === 'mcc') {
                            new_value = this.state.mccs_selected;
                            old_value = this.getMCCCode('mcc');
                        } else {
                            new_value = this.state.edit_field_key_value;
                            old_value = this.renderValue(this.state.edit_field_key);
                        }

                        if (this.state.edit_field === true && this.state.edit_field_key.length !== 0 && new_value !== old_value) {
                            this.setUsersNotification("Please save changes first or choose cancel edit.", "danger");
                        } else {
                            this.setState({'edit_field': true, 'edit_field_key': key, 'edit_field_key_value': value})
                        }
                    }} />;
                }
            }
        return '';
    }

    renderBusinessCategories(classes, selected_type) {
        if (selected_type.length !== 0 && Object.keys(this.state.business_types).length > 0 && this.state.business_types.hasOwnProperty('categories')) {
            let value = this.state.edit_field && this.state.edit_field_key === 'product_or_service_value' ? this.state.edit_field_key_value : this.renderValue('product_or_service_value');
            if (!Object.keys(this.state.business_types.categories[selected_type]).includes(value)) value = '';
            return <GridItem md={6}>
                <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top"/*, paddingTop: "10px"*/}}>
                {this.renderRequestFieldCheckbox("request_product_or_service_value", classes)}
                </td><td>
                <InputLabel
                    className={classes.selectLabel}
                    htmlFor="product_or_service_value"
                >
                    Business Type
                </InputLabel>
                <Select
                    style={{width: "100%"}}
                    classes={{"select": classes.select}}
                    inputProps={{
                        "name": "product_or_service_value",
                        "id": "product_or_service_value"
                    }}
                    MenuProps={{"className": classes.selectMenu}}
                    onChange={(event) => {this.setState({"edit_field_key_value": event.target.value});}}
                    value={value}
                    disabled={this.state.edit_field && this.state.edit_field_key === 'product_or_service_value' ? false : true}
                >
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={'business_categories_non'} value="">Choose an option</MenuItem>
                    {
                        Object.keys(this.state.business_types.categories[selected_type]).map((key) => <MenuItem
                            classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}}
                            key={'business_categories_' + key}
                            value={key}
                        >
                            { this.state.business_types.categories[selected_type][key] }
                        </MenuItem>)
                    }
                </Select>
                </td><td width="50px;">{this.renderEditField('product_or_service_value', this.renderValue('product_or_service_value'), 'CustomInput')}</td></tr></tbody></table>
            </GridItem>;
        } else {
            return <GridItem md={6}></GridItem>;
        }
    }

    renderPage1(classes) {
        let business_address_same_as_mailing = parseInt(this.renderValue('business_address_same_as_mailing'));
        if (isNaN(business_address_same_as_mailing)) business_address_same_as_mailing = 1;
        let new_company = parseInt(this.renderValue('new_company'));
        if (isNaN(new_company)) new_company = null;
        let business_types = [<MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={'business_types_non'} value="">Choose an option</MenuItem>];
        if (Object.keys(this.state.business_types).length > 0 && this.state.business_types.hasOwnProperty('types')) {
            Object.keys(this.state.business_types.types).forEach((key) => {
                business_types.push(
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={'business_types_' + key} value={key}>{this.state.business_types.types[key]}</MenuItem>
                );
            });
        }

        return (
            <GridContainer
                key="details_grid_1"
            >
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {/* {this.renderRequestFieldCheckbox("request_abn", classes)} */}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": helpers.abnFormat(this.renderValue('abn')),
                            "name": "abn",
                            "disabled": true
                        }}
                        labelText="Australian Business Number (ABN)"
                    />
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}></GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {/* {this.renderRequestFieldCheckbox("request_business_type", classes)} */}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('business_type'),
                            "name": "business_type",
                            "disabled": true
                        }}
                        labelText="Entity Type"
                    />
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}></GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {/* {this.renderRequestFieldCheckbox("request_merchant_name", classes)} */}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'merchant_name' ? this.state.edit_field_key_value : this.renderValue('merchant_name'),
                            "name": "merchant_name",
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'merchant_name' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="Merchant Name"
                    />
                    </td><td width="50px;">{this.renderEditField('merchant_name', this.renderValue('merchant_name'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_legal_name", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'legal_name' ? this.state.edit_field_key_value : this.renderValue('legal_name'),
                            "name": "legal_name",
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'legal_name' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="Business Name"
                    />
                    </td><td width="50px;">{this.renderEditField('legal_name', this.renderValue('legal_name'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}></GridItem>
                <GridItem md={12}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_new_company", classes)}
                    </td><td style={{paddingLeft: "10px"}}>
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={new_company === 1}
                                    value={new_company === 1}
                                    name={'new_company'}
                                    aria-label={'new_company'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                New Business
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    <FormControlLabel
                        disabled={true}
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                        checked={new_company === 0}
                                        value={new_company === 0}
                                        name={'existing_company'}
                                        aria-label={'existing_company'}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                            disabled: classes.disabledCheckboxAndRadio
                                        }}
                                />
                                Existing Business
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    </td></tr></tbody></table>
                </GridItem>
                <style>{".force-padding-left {padding-left: 0px !important;} .force-padding-right {padding-right: 0px !important;}"}</style>
                <GridItem md={12}>
                    <GridContainer key="details_grid_business_description" className="force-padding-left force-padding-right">
                        <GridItem md={6}>
                            <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top"/*, paddingTop: "10px"*/}}>
                            {this.renderRequestFieldCheckbox("request_product_or_service_type", classes)}
                            </td><td>
                            <InputLabel
                                className={classes.selectLabel}
                                htmlFor="product_or_service_type"
                            >
                                Business Type
                            </InputLabel>
                            <Select
                                style={{width: "100%"}}
                                classes={{"select": classes.select}}
                                inputProps={{
                                    "name": "product_or_service_type",
                                    "id": "product_or_service_type"
                                }}
                                MenuProps={{"className": classes.selectMenu}}
                                onChange={(event) => {this.setState({"edit_field_key_value": event.target.value});}}
                                value={this.state.edit_field && this.state.edit_field_key === 'product_or_service_type' ? this.state.edit_field_key_value : this.renderValue('product_or_service_type')}
                                disabled={this.state.edit_field && this.state.edit_field_key === 'product_or_service_type' ? false : true}
                            >
                                { business_types }
                            </Select>
                            </td><td width="50px;">{this.renderEditField('product_or_service_type', this.renderValue('product_or_service_type'), 'CustomInput')}</td></tr></tbody></table>
                        </GridItem>
                        {
                            this.renderBusinessCategories(classes, this.state.edit_field && this.state.edit_field_key === 'product_or_service_type' ? this.state.edit_field_key_value : this.renderValue('product_or_service_type'))
                        }
                        <GridItem md={6}>
                            <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                            {this.renderRequestFieldCheckbox("request_mcc", classes)}
                            {/* {this.setMccRiskFlag(this.state.mccs_selected === null ? this.getMCCCode('mcc') : this.state.mccs_selected)} */}
                            </td><td>
                                {/* <CustomInput
                                    formControlProps={{"fullWidth": true}}
                                    inputProps={{
                                        "value": this.renderMCC('mcc'),
                                        "name": "mcc",
                                        "disabled": true
                                    }}
                                    labelText="Describe Business"
                                /> */}

                                <FormControl
                                    className={classes.selectFormControl}
                                    fullWidth
                                >
                                    <InputLabel
                                        className={classes.selectLabel}
                                        htmlFor={"mcc_code"}
                                    >
                                        Describe Business
                                    </InputLabel>
                                    <Select
                                        style={{width: "100%"}}
                                        classes={{"select": classes.select}}
                                        inputProps={{
                                            "name": "mcc_code",
                                            "id": "mcc_code"
                                        }}
                                        MenuProps={{
                                            autoFocus: false,
                                            "className": classes.selectMenu,
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        onChange={(event) => {
                                            if (!event.target.value) {
                                                this.setState({mccs_open: true});
                                                return;
                                            }
                                            this.setState({"edit_field_key_value": event.target.value, "mccs_selected": event.target.value});}
                                        }
                                        onClose={() => {this.setState({"filter": "", mccs_open: false})}}
                                        onOpen={() => {this.setState({"filter": "", mccs_open: true})}}
                                        value={this.state.mccs_selected === null ? this.getMCCCode('mcc') : this.state.mccs_selected}
                                        open={this.state.mccs_open}
                                        disabled={this.state.edit_field && this.state.edit_field_key === 'mcc' ? false : true}
                                    >
                                        <ListSubheader>
                                            <input type="text"
                                                // value={this.state.filter}
                                                style={{"width": "100%", "padding": "10px 5px"}}
                                                placeholder={"Search MCC"}
                                                onKeyDown={(e) => {
                                                    if (e.key !== "Escape") {e.stopPropagation();}
                                                }}
                                                onChange={(event) => {this.setState({"filter": event.target.value})}} />
                                        </ListSubheader>
                                        {/* <MenuItem
                                            classes={{"root": classes.selectMenuItem}}
                                            value="filter"
                                        >
                                            <input type="text"
                                                style={{"width": "100%", "padding": "10px 5px"}}
                                                placeholder={"Search MCC"}
                                                onChange={(event) => {this.setState({"filter": event.target.value})}} />
                                        </MenuItem> */}
                                        {this.state.mccs
                                            ? this.filterMCCs().map((prop, key) => {
                                                let risk = prop.restriction === 'prohibited' ? 'red' : (
                                                    prop.restriction === 'restricted' ? 'orange' : (
                                                        prop.restriction === 'restricted' ? 'orange' : 'green'
                                                    )
                                                );
                                                return <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelectedMultiple
                                                    }}
                                                    key={key}
                                                    value={prop.code}
                                                    title={prop.code.toString() === '999999' ? '' : this.setMccTooltip(prop)}
                                                >
                                                    <span title={risk === 'red' ? 'High Risk' : (risk === 'orange' ? 'Medium Risk' : (risk === 'green' ? 'Low Risk' : 'N/A'))}>
                                                        <EmojiFlags style={{fontSize: "18px", color: risk ? risk : 'green', marginRight: "5px"}} />
                                                    </span>
                                                    { prop.code.toString() === '999999' ? 'Others' : ('(' + prop.code + ') ' + prop.title.slice(0, 80)) }
                                                </MenuItem>;
                                                })
                                            : ""}
                                    </Select>
                                </FormControl>
                            </td><td width="50px;">{this.renderEditField('mcc', this.renderValue('mcc'), 'CustomInput')}</td></tr></tbody></table>
                        </GridItem>
                        {
                            (this.state.mccs_selected === null ? this.getMCCCode('mcc') : this.state.mccs_selected).toString() === '999999'
                            ? <GridItem md={6}>
                                <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                                {/* {this.renderRequestFieldCheckbox("request_other_mcc", classes)} */}
                                </td><td>
                                <CustomInput
                                    formControlProps={{"fullWidth": true}}
                                    inputProps={{
                                        "value": this.state.edit_field && this.state.edit_field_key === 'other_mcc' ? this.state.edit_field_key_value : this.renderValue('other_mcc'),
                                        "name": "other_mcc",
                                        "disabled": this.state.edit_field && this.state.edit_field_key === 'other_mcc' ? false : true,
                                        "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                                    }}
                                    labelText="Please add a description"
                                />
                                </td><td width="50px;">{this.renderEditField('other_mcc', this.renderValue('other_mcc'), 'CustomInput')}</td></tr></tbody></table>
                            </GridItem>
                            : <GridItem md={6}>{/* Spacer */}</GridItem>
                        }
                    </GridContainer>
                </GridItem>
                {/* <GridItem md={6}> */}
                    {/* Spacer for websites */}
                {/* </GridItem> */}
                <GridItem md={4}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_website", classes)}
                    {
                        !(this.state.edit_field && this.state.edit_field_key === 'website') && this.renderValue('website').length > 0
                        ? <i style={{marginLeft: "11px", cursor: "pointer"}} className={"fas fa-external-link-alt"} onClick={() => {
                            window.open(this.renderValue('website'));
                        }} />
                        : ""
                    }
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'website' ? this.state.edit_field_key_value : this.renderValue('website'),
                            "name": "website",
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'website' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="Business Website or Social Profile"
                    />
                    </td><td width="50px;">{this.renderEditField('website', this.renderValue('website'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={4}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_facebook_profile", classes)}
                    {
                        !(this.state.edit_field && this.state.edit_field_key === 'facebook_profile') && this.renderValue('facebook_profile').length > 0
                        ? <i style={{marginLeft: "11px", cursor: "pointer"}} className={"fas fa-external-link-alt"} onClick={() => {
                            window.open(this.renderValue('facebook_profile'));
                        }} />
                        : ""
                    }
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'facebook_profile' ? this.state.edit_field_key_value : this.renderValue('facebook_profile'),
                            "name": "facebook_profile",
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'facebook_profile' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="Facebook Profile"
                    />
                    </td><td width="50px;">{this.renderEditField('facebook_profile', this.renderValue('facebook_profile'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={4}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_instagram_profile", classes)}
                    {
                        !(this.state.edit_field && this.state.edit_field_key === 'instagram_profile') && this.renderValue('instagram_profile').length > 0
                        ? <i style={{marginLeft: "11px", cursor: "pointer"}} className={"fas fa-external-link-alt"} onClick={() => {
                            window.open(this.renderValue('instagram_profile'));
                        }} />
                        : ""
                    }
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'instagram_profile' ? this.state.edit_field_key_value : this.renderValue('instagram_profile'),
                            "name": "instagram_profile",
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'instagram_profile' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="Instagram Profile"
                    />
                    </td><td width="50px;">{this.renderEditField('instagram_profile', this.renderValue('instagram_profile'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_legal_address", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'legal_address' ? this.state.edit_field_key_value : this.renderValue('legal_address'),
                            "name": "legal_address",
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'legal_address' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="Registered Business Address"
                    />
                    </td><td width="50px;">{this.renderEditField('legal_address', this.renderValue('legal_address'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_suburb", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'suburb' ? this.state.edit_field_key_value : this.renderValue('suburb'),
                            "name": "suburb",
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'suburb' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="City"
                    />
                    </td><td width="50px;">{this.renderEditField('suburb', this.renderValue('suburb'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_state", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'state' ? this.state.edit_field_key_value : this.renderValue('state'),
                            "name": "state",
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'state' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="State"
                    />
                    </td><td width="50px;">{this.renderEditField('state', this.renderValue('state'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_post_code", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'post_code' ? this.state.edit_field_key_value : this.renderValue('post_code'),
                            "name": "post_code",
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'post_code' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="Post Code"
                    />
                    </td><td width="50px;">{this.renderEditField('post_code', this.renderValue('post_code'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={12}>
                    <div style={{padding: "0 5px"}}>
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={business_address_same_as_mailing === 1}
                                    value={business_address_same_as_mailing === 1}
                                    name={'business_address_same_as_mailing'}
                                    aria-label={'business_address_same_as_mailing'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Is the mailing address the same as the registered business address?
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    </div>
                </GridItem>
                {
                    business_address_same_as_mailing !== 1
                    ? <GridItem md={6}>
                        <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                        {this.renderRequestFieldCheckbox("request_other_address", classes)}
                        </td><td>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.state.edit_field && this.state.edit_field_key === 'other_address' ? this.state.edit_field_key_value : this.renderValue('other_address'),
                                "name": "other_address",
                                "disabled": this.state.edit_field && this.state.edit_field_key === 'other_address' ? false : true,
                                "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                            }}
                            labelText="Mailing Address"
                        />
                        </td><td width="50px;">{this.renderEditField('other_address', this.renderValue('other_address'), 'CustomInput')}</td></tr></tbody></table>
                    </GridItem>
                    : ""
                }
                {
                    business_address_same_as_mailing !== 1
                    ? <GridItem md={6}>
                        <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                        {this.renderRequestFieldCheckbox("request_other_suburb", classes)}
                        </td><td>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.state.edit_field && this.state.edit_field_key === 'other_suburb' ? this.state.edit_field_key_value : this.renderValue('other_suburb'),
                                "name": "other_suburb",
                                "disabled": this.state.edit_field && this.state.edit_field_key === 'other_suburb' ? false : true,
                                "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                            }}
                            labelText="City"
                        />
                        </td><td width="50px;">{this.renderEditField('other_suburb', this.renderValue('other_suburb'), 'CustomInput')}</td></tr></tbody></table>
                    </GridItem>
                    : ""
                }
                {
                    business_address_same_as_mailing !== 1
                    ? <GridItem md={6}>
                        <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                        {this.renderRequestFieldCheckbox("request_other_state", classes)}
                        </td><td>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.state.edit_field && this.state.edit_field_key === 'other_state' ? this.state.edit_field_key_value : this.renderValue('other_state'),
                                "name": "other_state",
                                "disabled": this.state.edit_field && this.state.edit_field_key === 'other_state' ? false : true,
                                "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                            }}
                            labelText="State"
                        />
                        </td><td width="50px;">{this.renderEditField('other_state', this.renderValue('other_state'), 'CustomInput')}</td></tr></tbody></table>
                    </GridItem>
                    : ""
                }
                {
                    business_address_same_as_mailing !== 1
                    ? <GridItem md={6}>
                        <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                        {this.renderRequestFieldCheckbox("request_other_post_code", classes)}
                        </td><td>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.state.edit_field && this.state.edit_field_key === 'other_post_code' ? this.state.edit_field_key_value : this.renderValue('other_post_code'),
                                "name": "other_post_code",
                                "disabled": this.state.edit_field && this.state.edit_field_key === 'other_post_code' ? false : true,
                                "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                            }}
                            labelText="Post Code"
                        />
                        </td><td width="50px;">{this.renderEditField('other_post_code', this.renderValue('other_post_code'), 'CustomInput')}</td></tr></tbody></table>
                    </GridItem>
                    : ""
                }
            </GridContainer>
        );
    }
    
    formatNumber(value) {
        if (!value) return value;
        var n = value.toString().replace(/^(-)|[^0-9.,]+/g, '$1').replace(/,/g, '').split(".");
        n[0] = n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return n.join(".");
    }

    renderFiles(classes, key, title, highlight_empty = false) {
        let registry = this.getRegistry(key);
        let value = this.renderValue(key);
        if (registry === null || value.length === 0) {
            return <GridItem key={'grid_' + key} md={12}>
                <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top"}}>
                {this.renderRequestFieldCheckbox("request_" + key, classes)}
                </td><td>
                <p key={'grid_' + key + '_1'} style={{color: ""}}>{title}</p>
                <p key={'grid_' + key + '_2'} style={{paddingLeft: "15px", color: highlight_empty ? "red" : ""}}>No uploaded file(s)</p>
                </td></tr></tbody></table>
            </GridItem>;
        }
        
        return <GridItem key={'grid_' + key} md={12}>
            <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top"}}>
            {this.renderRequestFieldCheckbox("request_" + key, classes)}
            </td><td>
            <p key={'grid_' + key + '_1'}>{title}</p>
            {
                value.split(',').map((id, k) => {
                    let file = null;
                    try {
                        this.state.client_files.forEach(field => {
                            if (parseInt(field.id) === parseInt(id)) {
                                file = field;
                            }
                        });
                    } catch(e) {}
                    return file === null
                        ? ''
                        : <p key={'grid_' + key + '_2_' + k} style={{paddingLeft: "15px"}}>
                            <i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-external-link-alt"} onClick={() => {
                                return this.downloadDocument(id, file.filename, null, null, () => {this.checkToSaveRequestFieldCheckboxValue(key, true);});
                            }} /><span style={{cursor: "pointer"}} onClick={() => {
                                return this.state.previewable_file_extensions.includes(file.filename.split('.').pop().toLowerCase())
                                    ? this.previewDocument(id, file.filename, null, null, () => {this.checkToSaveRequestFieldCheckboxValue(key, true);})
                                    : this.downloadDocument(id, file.filename, null, null, () => {this.checkToSaveRequestFieldCheckboxValue(key, true);});
                            }}>{file.filename}</span>
                        </p>;
                })
            }
            </td></tr></tbody></table>
        </GridItem>;
    }

    renderPage2(classes) {
        let trust_fields = parseInt(this.renderValue('trust_company'));
        if (isNaN(trust_fields)) trust_fields = 1;
        let terminated_merchant_facilities = parseInt(this.renderValue('terminated_merchant_facilities'));
        if (isNaN(terminated_merchant_facilities)) terminated_merchant_facilities = 1;
        let merchant_facilities = parseInt(this.renderValue('merchant_facilities'));
        if (isNaN(merchant_facilities)) merchant_facilities = 1;
        let match_report = null;
        try {this.state.client_files.forEach(field => {if (field.is_match_report === true) match_report = field;});} catch(e) {}
        return (
            <GridContainer
                key="details_grid_2"
            >
                {this.renderFiles(classes, 'bas_statements_key', 'Upload Most Recent Business Activity Statements (BAS)', true)}
                {this.renderFiles(classes, 'tax_returns_key', 'Upload Most Recent Tax Return', true)}
                <GridItem md={12}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_trust_fields", classes)}
                    </td><td>
                    Does your company structure have a trust?
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={trust_fields === 1}
                                    value={trust_fields === 1}
                                    name={'trust_fields'}
                                    aria-label={'trust_fields'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Yes
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    <FormControlLabel
                        disabled={true}
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                        checked={trust_fields === 0}
                                        value={trust_fields === 0}
                                        name={'trust_fields_no'}
                                        aria-label={'trust_fields_no'}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                            disabled: classes.disabledCheckboxAndRadio
                                        }}
                                />
                                No
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    </td></tr></tbody></table>
                </GridItem>
                {
                    trust_fields === 1
                    ? this.renderFiles(classes, 'trust_deeds', 'Upload Trust Deed/s', true)
                    : ''
                }
                {
                    trust_fields === 1
                    ? <GridItem md={12}>
                        <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                        {this.renderRequestFieldCheckbox("request_trust_name", classes)}
                        </td><td>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('trust_name'),
                                "name": "trust_name",
                                "disabled": true,
                                style: {borderBottom: this.renderValue('trust_name').toString().length > 0 ? "" : "solid red 1px"}
                            }}
                            labelText="Trust Name"
                        />
                        </td></tr></tbody></table>
                    </GridItem>
                    : ''
                }
                <GridItem md={12}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_terminated_merchant_facilities", classes)}
                    </td><td>
                    Have you ever had a merchant facility terminated?
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={terminated_merchant_facilities === 1}
                                    value={terminated_merchant_facilities === 1}
                                    name={'terminated_merchant_facilities'}
                                    aria-label={'terminated_merchant_facilities'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Yes
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    <FormControlLabel
                        disabled={true}
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                        checked={terminated_merchant_facilities === 0}
                                        value={terminated_merchant_facilities === 0}
                                        name={'terminated_merchant_facilities_no'}
                                        aria-label={'terminated_merchant_facilities_no'}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                            disabled: classes.disabledCheckboxAndRadio
                                        }}
                                />
                                No
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={12}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "0px"}}>
                    {this.renderRequestFieldCheckbox("request_match_report", classes)}
                    </td><td>
                    <p>Match Report</p>
                    {
                        match_report !== null
                        ? <p style={{margin: "15px"}}>
                            <i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-external-link-alt"} onClick={() => {
                                this.setState({'downloading_match_report': true});
                                return this.downloadDocument(
                                    match_report.id,
                                    match_report.filename,
                                    'client/' + this.state.selected_client_id + '/application/' + this.state.selected_application_id + '/file/' + match_report.id + '/download',
                                    () => {this.setState({'match_report_viewed': true})},
                                    () => {this.checkToSaveRequestFieldCheckboxValue('match_report', true);}
                                );
                            }} /><span style={{cursor: "pointer"}} onClick={() => {
                                return this.state.previewable_file_extensions.includes(match_report.filename.split('.').pop().toLowerCase())
                                    ? this.previewDocument(match_report.id, match_report.filename, 'client/' + this.state.selected_client_id + '/application/' + this.state.selected_application_id + '/file/' + match_report.id + '/download?convert=1', () => {this.setState({'match_report_viewed': true})}, () => {this.checkToSaveRequestFieldCheckboxValue('match_report', true);})
                                    : this.downloadDocument(match_report.id, match_report.filename, 'client/' + this.state.selected_client_id + '/application/' + this.state.selected_application_id + '/file/' + match_report.id + '/download', () => {this.setState({'match_report_viewed': true})}, () => {this.checkToSaveRequestFieldCheckboxValue('match_report', true);});
                            }}>{match_report.filename}</span>
                        </p>
                        : <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color={"success"}
                            onClick={(event) => this.generateMatchReport()}
                            disabled={!this.state.updateApplication}
                            >
                                Generate Match Report
                        </Button>
                    }
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={12}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_merchant_facilities", classes)}
                    </td><td>
                    Do you currently have any merchant facilities?
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={merchant_facilities === 1}
                                    value={merchant_facilities === 1}
                                    name={'merchant_facilities'}
                                    aria-label={'merchant_facilities'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Yes
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    <FormControlLabel
                        disabled={true}
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                        checked={merchant_facilities === 0}
                                        value={merchant_facilities === 0}
                                        name={'merchant_facilities_no'}
                                        aria-label={'merchant_facilities_no'}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                            disabled: classes.disabledCheckboxAndRadio
                                        }}
                                />
                                No
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    </td></tr></tbody></table>
                </GridItem>
                {
                    merchant_facilities
                    ? this.renderFiles(classes, 'recent_merchant_statements_key', 'Upload last 3 months merchant statements', true)
                    : ''
                }
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_estimated_annual_turnover", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": '$' + this.formatNumber(this.renderValue('estimated_annual_turnover')),
                            "name": "estimated_annual_turnover",
                            "disabled": true
                        }}
                        labelText="Estimated Annual Card Revenue"
                    />
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_average_number_of_credit_card_sales_per_month", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('average_number_of_credit_card_sales_per_month'),
                            "name": "average_number_of_credit_card_sales_per_month",
                            "disabled": true
                        }}
                        labelText="Average number of credit card sales per month"
                    />
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <div style={{padding: "0 15px"}}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": "$" + this.formatNumber(((this.renderValue('estimated_annual_turnover').toString().replace(/\D/g, "") / 12) / this.renderValue('average_number_of_credit_card_sales_per_month').toString().replace(/\D/g, "")).toFixed(2)),
                            "name": "average_number_of_credit_card_sales_per_month",
                            "disabled": true
                        }}
                        labelText="Average Transaction Amount"
                    />
                    </div>
                </GridItem>
                <GridItem md={6}>
                    {/* Spacer */}
                </GridItem>
            </GridContainer>
        );
    }

    renderPage3(classes) {
        let do_you_take_deposit = parseInt(this.renderValue('do_you_take_deposit'));
        if (isNaN(do_you_take_deposit)) do_you_take_deposit = 1;
        return (
            <GridContainer
                key="details_grid_3"
                // style={{padding: '0 15px'}}
            >
                <GridItem md={12}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top"}}>
                    {this.renderRequestFieldCheckbox("request_card_present", classes)}
                    </td><td>
                    Percentage breakdown of card payments
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={4}>
                    <div style={{padding: "0 15px"}}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('card_present') + '%',
                            "name": "card_present",
                            "disabled": true
                        }}
                        labelText="Card present/Face-to-face"
                    />
                    </div>
                </GridItem>
                <GridItem md={4}>
                    <div style={{padding: "0 15px"}}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('moto') + '%',
                            "name": "moto",
                            "disabled": true
                        }}
                        labelText="Mail Order/Telephone Order"
                    />
                    </div>
                </GridItem>
                <GridItem md={4}>
                    <div style={{padding: "0 15px"}}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('internet') + '%',
                            "name": "internet",
                            "disabled": true
                        }}
                        labelText="Internet/E-commerce"
                    />
                    </div>
                </GridItem>
                <GridItem md={12}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_do_you_take_deposit", classes)}
                    </td><td>
                    Do you take deposits from your customer?
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={do_you_take_deposit === 1}
                                    value={do_you_take_deposit === 1}
                                    name={'do_you_take_deposit'}
                                    aria-label={'do_you_take_deposit'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Yes
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    <FormControlLabel
                        disabled={true}
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                        checked={do_you_take_deposit === 0}
                                        value={do_you_take_deposit === 0}
                                        name={'do_you_take_deposit_no'}
                                        aria-label={'do_you_take_deposit_no'}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                            disabled: classes.disabledCheckboxAndRadio
                                        }}
                                />
                                No
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    </td></tr></tbody></table>
                </GridItem>
                {
                    do_you_take_deposit
                    ? <GridItem md={12}>
                        <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top"}}>
                        {this.renderRequestFieldCheckbox("request_how_long_from_the_time_of_deposit", classes)}
                        </td><td>
                        How long from the time of deposit payment are the goods/services received?
                        </td></tr></tbody></table>
                    </GridItem>
                    : ''
                }
                {
                    do_you_take_deposit
                    ? <GridItem md={4}>
                        <div style={{padding: "0 15px"}}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('zero_days') + '%',
                                "name": "zero_days",
                                "disabled": true
                            }}
                            labelText="0 Days"
                        />
                        </div>
                    </GridItem>
                    : ''
                }
                {
                    do_you_take_deposit
                    ? <GridItem md={4}>
                        <div style={{padding: "0 15px"}}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('seven_days') + '%',
                                "name": "seven_days",
                                "disabled": true
                            }}
                            labelText="1-7 Days"
                        />
                        </div>
                    </GridItem>
                    : ''
                }
                {
                    do_you_take_deposit
                    ? <GridItem md={4}>
                        <div style={{padding: "0 15px"}}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('fourteen_days') + '%',
                                "name": "fourteen_days",
                                "disabled": true
                            }}
                            labelText="8-14 Days"
                        />
                        </div>
                    </GridItem>
                    : ''
                }
                {
                    do_you_take_deposit
                    ? <GridItem md={4}>
                        <div style={{padding: "0 15px"}}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('thirty_days') + '%',
                                "name": "thirty_days",
                                "disabled": true
                            }}
                            labelText="15-30 Days"
                        />
                        </div>
                    </GridItem>
                    : ''
                }
                {
                    do_you_take_deposit
                    ? <GridItem md={4}>
                        <div style={{padding: "0 15px"}}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('sixty_days') + '%',
                                "name": "sixty_days",
                                "disabled": true
                            }}
                            labelText="31-60 Days"
                        />
                        </div>
                    </GridItem>
                    : ''
                }
                {
                    do_you_take_deposit
                    ? <GridItem md={4}>
                        <div style={{padding: "0 15px"}}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('more') + '%',
                                "name": "more",
                                "disabled": true
                            }}
                            labelText="61 Days+"
                        />
                        </div>
                    </GridItem>
                    : ''
                }
            </GridContainer>
        );
    }

    renderPage4(classes) {
        let subscription_membership_transactions = this.renderValue('subscription_membership_transactions');
        if (!subscription_membership_transactions || subscription_membership_transactions.length === 0 || subscription_membership_transactions.toString().toLowerCase() === 'no') subscription_membership_transactions = 0;
        if (subscription_membership_transactions.toString().toLowerCase() === 'yes') subscription_membership_transactions = 1;
        if (isNaN(subscription_membership_transactions)) subscription_membership_transactions = 1;
        let frequency_of_subscriptions_value = this.renderValue('weekly');
        let frequency_of_subscriptions = 'Weekly';
        if (parseInt(frequency_of_subscriptions_value) === 0) {
            frequency_of_subscriptions_value = this.renderValue('monthly');
            frequency_of_subscriptions = 'Monthly';
        }
        if (parseInt(frequency_of_subscriptions_value) === 0) {
            frequency_of_subscriptions_value = this.renderValue('bi_annually');
            frequency_of_subscriptions = 'Bi-annually';
        }
        if (parseInt(frequency_of_subscriptions_value) === 0) {
            frequency_of_subscriptions_value = this.renderValue('annually');
            frequency_of_subscriptions = 'Annually';
        }
        if (parseInt(frequency_of_subscriptions_value) === 0) {
            frequency_of_subscriptions_value = this.renderValue('other');
            frequency_of_subscriptions = 'Other';
        }
        let facility_required_nexxtap = parseInt(this.renderValue('facility_required_nexxtap'));
        if (isNaN(facility_required_nexxtap)) facility_required_nexxtap = 1;
        let facility_required_online_ecomm = parseInt(this.renderValue('facility_required_online_ecomm'));
        if (isNaN(facility_required_online_ecomm)) facility_required_online_ecomm = 1;
        let absorb_surcharge = parseInt(this.renderValue('absorb_surcharge'));
        if (isNaN(absorb_surcharge)) absorb_surcharge = 1;
        let customer_pays_surcharge = parseInt(this.renderValue('customer_pays_surcharge'));
        if (isNaN(customer_pays_surcharge)) customer_pays_surcharge = 1;
        let nexxtap_facility_device = this.renderValue('nexxtap_facility_device');
        let nexxtap_facility_device_version = this.renderValue('nexxtap_facility_device_version');
        return (
            <GridContainer
                key="details_grid_4"
            >
                <GridItem md={12}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_subscription_membership_transactions", classes)}
                    </td><td>
                    Do you take subscription/membership transactions?
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={subscription_membership_transactions === 1}
                                    value={subscription_membership_transactions === 1}
                                    name={'subscription_membership_transactions'}
                                    aria-label={'subscription_membership_transactions'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Yes
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    <FormControlLabel
                        disabled={true}
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                        checked={subscription_membership_transactions === 0}
                                        value={subscription_membership_transactions === 0}
                                        name={'subscription_membership_transactions_no'}
                                        aria-label={'subscription_membership_transactions_no'}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                            disabled: classes.disabledCheckboxAndRadio
                                        }}
                                />
                                No
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    </td></tr></tbody></table>
                </GridItem>
                {
                    subscription_membership_transactions
                    ?  <GridItem md={6}>
                        <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                        {this.renderRequestFieldCheckbox("request_term_of_your_subscription", classes)}
                        </td><td>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('term_of_your_subscription'),
                                "name": "term_of_your_subscription",
                                "disabled": true
                            }}
                            labelText="Term of Subscription"
                        />
                        </td></tr></tbody></table>
                    </GridItem>
                    : ''
                }
                {
                    subscription_membership_transactions
                    ?  <GridItem md={6}>
                        <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                        {this.renderRequestFieldCheckbox("request_frequency_of_subscriptions", classes)}
                        </td><td>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": frequency_of_subscriptions,
                                "name": "frequency_of_subscriptions",
                                "disabled": true
                            }}
                            labelText="Frequency of Subscriptions"
                        />
                        </td></tr></tbody></table>
                    </GridItem>
                    : ''
                }
                {
                    subscription_membership_transactions
                    ?  <GridItem md={12}>
                        <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                        {this.renderRequestFieldCheckbox("request_transaction_information_notes", classes)}
                        </td><td>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('transaction_information_notes'),
                                "multiline": true,
                                "rows": 3,
                                "disabled": true
                            }}
                            labelText="Notes"
                        />
                        </td></tr></tbody></table>
                    </GridItem>
                    : ''
                }
                <GridItem md={12}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top"}}>
                    {this.renderRequestFieldCheckbox("request_facility_required", classes)}
                    </td><td>
                    Facility Required
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={12}>
                    <div style={{padding: "0 15px"}}>
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={facility_required_nexxtap === 1}
                                    value={facility_required_nexxtap === 1}
                                    name={'facility_required_nexxtap'}
                                    aria-label={'facility_required_nexxtap'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                { this.state.onboarding_name && this.state.onboarding_name.length > 0 ? this.state.onboarding_name : 'Nexxtap' }
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    </div>
                </GridItem>
                {
                    facility_required_nexxtap === 1
                    ? <div style={{width: "100%", padding: "0 1rem"}}>
                        <GridItem md={12}>
                            <div style={{padding: "0 15px"}}>
                            What mobile device are you currently using?
                            <FormControlLabel
                                control={
                                    <div>
                                        <Checkbox
                                            disabled={true}
                                            checked={nexxtap_facility_device === 'android'}
                                            value={nexxtap_facility_device === 'android'}
                                            name={'android'}
                                            aria-label={'android'}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot,
                                                disabled: classes.disabledCheckboxAndRadio
                                            }}
                                        />
                                        Android
                                    </div>
                                }
                                classes={{"label": classes.label, root: classes.labelRoot}}
                            />
                            <FormControlLabel
                                disabled={true}
                                control={
                                    <div>
                                        <Checkbox
                                            disabled={true}
                                                checked={nexxtap_facility_device === 'ios'}
                                                value={nexxtap_facility_device === 'ios'}
                                                name={'ios'}
                                                aria-label={'ios'}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot,
                                                    disabled: classes.disabledCheckboxAndRadio
                                                }}
                                        />
                                        iOS
                                    </div>
                                }
                                classes={{"label": classes.label, root: classes.labelRoot}}
                            />
                            </div>
                        </GridItem>
                        {
                            <GridItem md={6}>
                                <div style={{padding: "0 15px"}}>
                                <CustomInput
                                    formControlProps={{"fullWidth": true}}
                                    inputProps={{
                                        "value": nexxtap_facility_device === 'android'
                                                ? (
                                                    nexxtap_facility_device_version === 'not_supported'
                                                    ? 'Android ' + (parseInt(this.state.nexxtap_supported_android_min_version) - 1) + ' or older'
                                                    : (nexxtap_facility_device_version.length === 0 ? '' : 'Android ' + nexxtap_facility_device_version)
                                                )
                                                : 'N/A',
                                        "name": "nexxtap_facility_device_version",
                                        "disabled": true
                                    }}
                                    labelText={ nexxtap_facility_device === 'android' ? "Android Version" : "iOS Version"}
                                />
                                </div>
                            </GridItem>
                        }
                        <GridItem md={12}>
                            <div style={{padding: "0 15px"}}>
                            <CustomInput
                                formControlProps={{"fullWidth": true}}
                                inputProps={{
                                    "value": this.renderValue('nexxtap_facility_notes'),
                                    "multiline": true,
                                    "rows": 3,
                                    "disabled": true
                                }}
                                labelText="Notes"
                            />
                            </div>
                        </GridItem>
                    </div>
                    : ''
                }
                <GridItem md={12}>
                    <div style={{padding: "0 15px"}}>
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={facility_required_online_ecomm === 1}
                                    value={facility_required_online_ecomm === 1}
                                    name={'facility_required_online_ecomm'}
                                    aria-label={'facility_required_online_ecomm'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Online processing/E-commerce
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    </div>
                </GridItem>
                <GridItem md={12}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top"}}>
                    {this.renderRequestFieldCheckbox("request_surcharge", classes)}
                    </td><td>
                    Surcharge
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={12}>
                    <div style={{padding: "0 15px"}}>
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={absorb_surcharge === 1}
                                    value={absorb_surcharge === 1}
                                    name={'absorb_surcharge'}
                                    aria-label={'absorb_surcharge'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Absorb surcharge
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    </div>
                </GridItem>
                <GridItem md={12}>
                    <div style={{padding: "0 15px"}}>
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={customer_pays_surcharge === 1}
                                    value={customer_pays_surcharge === 1}
                                    name={'customer_pays_surcharge'}
                                    aria-label={'customer_pays_surcharge'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Customer pays surcharge
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    </div>
                </GridItem>
                
                {/* Pricing Schedule */}
                <GridItem md={12}>
                    <div style={{overflowX: "auto"}}>
                        <table cellSpacing={0} style={{minWidth: "500px", marginLeft: "18px", marginTop: "10px"}}>
                            <thead>
                                <tr>
                                    <td colSpan={3} style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <b>Merchant Pricing Schedule</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <b>Item</b>
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <b>Fee Basis</b>
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <b>Fee</b>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        High Risk Registration Fee
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Once Only
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <table style={{width: "100%"}}><tbody><tr><td width="1%;"><b>$</b></td><td>
                                            <CustomInput
                                                formControlProps={{style: { width: "60px", padding: "0px", margin: "0px" },}}
                                                inputProps={{
                                                    "value": this.state.edit_field && this.state.edit_field_key === 'high_risk_merchant_account_fee' ? this.state.edit_field_key_value : this.renderValue('high_risk_merchant_account_fee'),
                                                    "name": "high_risk_merchant_account_fee",
                                                    "disabled": this.state.edit_field && this.state.edit_field_key === 'high_risk_merchant_account_fee' ? false : true,
                                                    "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                                                }}
                                            />
                                            </td><td width="50px;">
                                                {this.renderEditField('high_risk_merchant_account_fee', this.renderValue('high_risk_merchant_account_fee'), 'CustomInput')}
                                            </td></tr></tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Monthly Account Maintenance Fee
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Monthly
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <table style={{width: "100%"}}><tbody><tr><td width="1%;"><b>$</b></td><td>
                                            <CustomInput
                                                formControlProps={{style: { width: "60px", padding: "0px", margin: "0px" },}}
                                                inputProps={{
                                                    "value": this.state.edit_field && this.state.edit_field_key === 'administration_per_month' ? this.state.edit_field_key_value : this.renderValue('administration_per_month'),
                                                    "name": "administration_per_month",
                                                    "disabled": this.state.edit_field && this.state.edit_field_key === 'administration_per_month' ? false : true,
                                                    "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                                                }}
                                            />
                                            </td><td width="50px;">
                                                {this.renderEditField('administration_per_month', this.renderValue('administration_per_month'), 'CustomInput')}
                                            </td></tr></tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Merchant Discount Rate (MDR) WGT
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Per Transaction
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <table style={{width: "100%"}}><tbody><tr><td>
                                            <CustomInput
                                                formControlProps={{style: { width: "60px", padding: "0px", margin: "0px" },}}
                                                inputProps={{
                                                    "value": this.state.edit_field && this.state.edit_field_key === 'visa_mastercard_merchant_percent' ? this.state.edit_field_key_value : this.renderValue('visa_mastercard_merchant_percent'),
                                                    "name": "visa_mastercard_merchant_percent",
                                                    "disabled": this.state.edit_field && this.state.edit_field_key === 'visa_mastercard_merchant_percent' ? false : true,
                                                    "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                                                }}
                                            />
                                            </td><td width="1%;"><b>%</b></td><td width="50px;">
                                                {this.renderEditField('visa_mastercard_merchant_percent', this.renderValue('visa_mastercard_merchant_percent'), 'CustomInput')}
                                            </td></tr></tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Merchant Discount Rate (MDR) Nexxtap
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Per Transaction
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <table style={{width: "100%"}}><tbody><tr><td>
                                            <CustomInput
                                                formControlProps={{style: { width: "60px", padding: "0px", margin: "0px" },}}
                                                inputProps={{
                                                    "value": this.state.edit_field && this.state.edit_field_key === 'merchant_discount_rate_nexxtap' ? this.state.edit_field_key_value : this.renderValue('merchant_discount_rate_nexxtap'),
                                                    "name": "merchant_discount_rate_nexxtap",
                                                    "disabled": this.state.edit_field && this.state.edit_field_key === 'merchant_discount_rate_nexxtap' ? false : true,
                                                    "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                                                }}
                                            />
                                            </td><td width="1%;"><b>%</b></td><td width="50px;">
                                                {this.renderEditField('merchant_discount_rate_nexxtap', this.renderValue('merchant_discount_rate_nexxtap'), 'CustomInput')}
                                            </td></tr></tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Per Transaction Attempt
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Per Transaction
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <table style={{width: "100%"}}><tbody><tr><td width="1%;"><b>$</b></td><td>
                                            <CustomInput
                                                formControlProps={{style: { width: "60px", padding: "0px", margin: "0px" },}}
                                                inputProps={{
                                                    "value": this.state.edit_field && this.state.edit_field_key === 'credit_card_transaction' ? this.state.edit_field_key_value : this.renderValue('credit_card_transaction'),
                                                    "name": "credit_card_transaction",
                                                    "disabled": this.state.edit_field && this.state.edit_field_key === 'credit_card_transaction' ? false : true,
                                                    "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                                                }}
                                            />
                                            </td><td width="50px;">
                                                {this.renderEditField('credit_card_transaction', this.renderValue('credit_card_transaction'), 'CustomInput')}
                                            </td></tr></tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Charge Back Fee
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Per Occurrence
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <table style={{width: "100%"}}><tbody><tr><td width="1%;"><b>$</b></td><td>
                                            <CustomInput
                                                formControlProps={{style: { width: "60px", padding: "0px", margin: "0px" },}}
                                                inputProps={{
                                                    "value": this.state.edit_field && this.state.edit_field_key === 'chargeback_per_occurrence' ? this.state.edit_field_key_value : this.renderValue('chargeback_per_occurrence'),
                                                    "name": "chargeback_per_occurrence",
                                                    "disabled": this.state.edit_field && this.state.edit_field_key === 'chargeback_per_occurrence' ? false : true,
                                                    "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                                                }}
                                            />
                                            </td><td width="50px;">
                                                {this.renderEditField('chargeback_per_occurrence', this.renderValue('chargeback_per_occurrence'), 'CustomInput')}
                                            </td></tr></tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Retrieval Fee
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Per Occurrence
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <table style={{width: "100%"}}><tbody><tr><td width="1%;"><b>$</b></td><td>
                                            <CustomInput
                                                formControlProps={{style: { width: "60px", padding: "0px", margin: "0px" },}}
                                                inputProps={{
                                                    "value": this.state.edit_field && this.state.edit_field_key === 'retrieval_per_occurrence' ? this.state.edit_field_key_value : this.renderValue('retrieval_per_occurrence'),
                                                    "name": "retrieval_per_occurrence",
                                                    "disabled": this.state.edit_field && this.state.edit_field_key === 'retrieval_per_occurrence' ? false : true,
                                                    "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                                                }}
                                            />
                                            </td><td width="50px;">
                                                {this.renderEditField('retrieval_per_occurrence', this.renderValue('retrieval_per_occurrence'), 'CustomInput')}
                                            </td></tr></tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        NPP Pay in
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Per Transaction
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <table style={{width: "100%"}}><tbody><tr><td width="1%;"><b>$</b></td><td>
                                            <CustomInput
                                                formControlProps={{style: { width: "60px", padding: "0px", margin: "0px" },}}
                                                inputProps={{
                                                    "value": this.state.edit_field && this.state.edit_field_key === 'npp_pay_in_per_transaction_fee' ? this.state.edit_field_key_value : this.renderValue('npp_pay_in_per_transaction_fee'),
                                                    "name": "npp_pay_in_per_transaction_fee",
                                                    "disabled": this.state.edit_field && this.state.edit_field_key === 'npp_pay_in_per_transaction_fee' ? false : true,
                                                    "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                                                }}
                                            />
                                            </td><td width="50px;">
                                                {this.renderEditField('npp_pay_in_per_transaction_fee', this.renderValue('npp_pay_in_per_transaction_fee'), 'CustomInput')}
                                            </td></tr></tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        NPP Pay out
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Per Transaction
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <table style={{width: "100%"}}><tbody><tr><td width="1%;"><b>$</b></td><td>
                                            <CustomInput
                                                formControlProps={{style: { width: "60px", padding: "0px", margin: "0px" },}}
                                                inputProps={{
                                                    "value": this.state.edit_field && this.state.edit_field_key === 'npp_pay_out_per_transaction_fee' ? this.state.edit_field_key_value : this.renderValue('npp_pay_out_per_transaction_fee'),
                                                    "name": "npp_pay_out_per_transaction_fee",
                                                    "disabled": this.state.edit_field && this.state.edit_field_key === 'npp_pay_out_per_transaction_fee' ? false : true,
                                                    "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                                                }}
                                            />
                                            </td><td width="50px;">
                                                {this.renderEditField('npp_pay_out_per_transaction_fee', this.renderValue('npp_pay_out_per_transaction_fee'), 'CustomInput')}
                                            </td></tr></tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        NPP Set up Fee
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        Once Only
                                    </td>
                                    <td style={{borderBottom: "1px solid", padding: "5px"}}>
                                        <table style={{width: "100%"}}><tbody><tr><td width="1%;"><b>$</b></td><td>
                                            <CustomInput
                                                formControlProps={{"fullWidth": true, style: { width: "50px", padding: "0px", margin: "0px" },}}
                                                inputProps={{
                                                    "value": this.state.edit_field && this.state.edit_field_key === 'npp_set_up_fee_fee' ? this.state.edit_field_key_value : this.renderValue('npp_set_up_fee_fee'),
                                                    "name": "npp_set_up_fee_fee",
                                                    "disabled": this.state.edit_field && this.state.edit_field_key === 'npp_set_up_fee_fee' ? false : true,
                                                    "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                                                }}
                                            />
                                            </td><td width="50px;">
                                                {this.renderEditField('npp_set_up_fee_fee', this.renderValue('npp_set_up_fee_fee'), 'CustomInput')}
                                            </td></tr></tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={3} style={{fontSize: "12px", lineHeight: "15px", paddingTop: "5px"}}>
                                        * All fees excluding GST
                                        <br/>
                                        * All fees are in AUD
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </GridItem>
                {/* Pricing Schedule */}
            </GridContainer>
        );
    }

    renderPage5(classes) {
        return (
            <GridContainer
                key="details_grid_5"
            >
                <GridItem md={12}>
                    <div style={{padding: "0 15px"}}>
                    Settlement Bank Account Information
                    </div>
                </GridItem>
                <GridItem md={12}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_dc_funding_account_name", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_funding_account_name'),
                            "name": "dc_funding_account_name",
                            "disabled": true
                        }}
                        labelText="Account Name"
                    />
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_dc_funding_bsb", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_funding_bsb'),
                            "name": "dc_funding_bsb",
                            "disabled": true
                        }}
                        labelText="BSB"
                    />
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_dc_funding_account_number", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_funding_account_number'),
                            "name": "dc_funding_account_number",
                            "disabled": true
                        }}
                        labelText="Account Number"
                    />
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={12}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_dc_funding_bank_name", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_funding_bank_name'),
                            "name": "dc_funding_bank_name",
                            "disabled": true
                        }}
                        labelText="Bank Name"
                    />
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_dc_branch_address", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_branch_address'),
                            "name": "dc_branch_address",
                            "disabled": true
                        }}
                        labelText="Bank Address"
                    />
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_dc_city", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_city'),
                            "name": "dc_city",
                            "disabled": true
                        }}
                        labelText="City"
                    />
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_dc_state", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_state'),
                            "name": "dc_state",
                            "disabled": true
                        }}
                        labelText="State"
                    />
                    </td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_dc_post_code", classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_post_code'),
                            "name": "dc_post_code",
                            "disabled": true
                        }}
                        labelText="Post Code"
                    />
                    </td></tr></tbody></table>
                </GridItem>
                {this.renderFiles(classes, 'bank_statements_key', 'Upload Most Recent Bank Statement', true)}
            </GridContainer>
        );
    }

    renderUbo(number, classes, application_form) {
        let verify_id = this.renderValue('bo' + number.toString() + '_verify_id_transaction_id');
        let verify = {
            'status': this.renderValue('bo' + number.toString() + '_verify_id_status'),
            'label': this.renderValue('bo' + number.toString() + '_verify_id_status_label'),
            'tooltip': this.renderValue('bo' + number.toString() + '_verify_id_status_tooltip'),
        };
        return <GridItem md={12}>   
            <GridContainer
                key={"ubo_grid_" + number.toString()}
            >
                <GridItem md={12}>
                    <div style={{padding: "0 15px"}}>
                    {"Beneficial Owner " + number.toString()}
                    </div>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_bo" + number.toString() + '_first_name', classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_first_name' ? this.state.edit_field_key_value : this.renderValue('bo' + number.toString() + '_first_name'),
                            "name": 'bo' + number.toString() + '_first_name',
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_first_name' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="First Name"
                    />
                    </td><td width="50px;">{this.renderEditField('bo' + number.toString() + '_first_name', this.renderValue('bo' + number.toString() + '_first_name'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_bo" + number.toString() + '_last_name', classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_last_name' ? this.state.edit_field_key_value : this.renderValue('bo' + number.toString() + '_last_name'),
                            "name": 'bo' + number.toString() + '_last_name',
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_last_name' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="Last Name"
                    />
                    </td><td width="50px;">{this.renderEditField('bo' + number.toString() + '_last_name', this.renderValue('bo' + number.toString() + '_last_name'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_bo" + number.toString() + '_position', classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_position' ? this.state.edit_field_key_value : this.renderValue('bo' + number.toString() + '_position'),
                            "name": 'bo' + number.toString() + '_position',
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_position' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="Position"
                    />
                    </td><td width="50px;">{this.renderEditField('bo' + number.toString() + '_position', this.renderValue('bo' + number.toString() + '_position'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    {/* Spacer */}
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_bo" + number.toString() + '_residential_address', classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_residential_address' ? this.state.edit_field_key_value : this.renderValue('bo' + number.toString() + '_residential_address'),
                            "name": 'bo' + number.toString() + '_residential_address',
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_residential_address' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="Address"
                    />
                    </td><td width="50px;">{this.renderEditField('bo' + number.toString() + '_residential_address', this.renderValue('bo' + number.toString() + '_residential_address'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_bo" + number.toString() + '_suburb', classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_suburb' ? this.state.edit_field_key_value : this.renderValue('bo' + number.toString() + '_suburb'),
                            "name": 'bo' + number.toString() + '_suburb',
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_suburb' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="City"
                    />
                    </td><td width="50px;">{this.renderEditField('bo' + number.toString() + '_suburb', this.renderValue('bo' + number.toString() + '_suburb'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_bo" + number.toString() + '_state', classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_state' ? this.state.edit_field_key_value : this.renderValue('bo' + number.toString() + '_state'),
                            "name": 'bo' + number.toString() + '_state',
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_state' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="State"
                    />
                    </td><td width="50px;">{this.renderEditField('bo' + number.toString() + '_state', this.renderValue('bo' + number.toString() + '_state'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_bo" + number.toString() + '_post_code', classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_post_code' ? this.state.edit_field_key_value : this.renderValue('bo' + number.toString() + '_post_code'),
                            "name": 'bo' + number.toString() + '_post_code',
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_post_code' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="Post Code"
                    />
                    </td><td width="50px;">{this.renderEditField('bo' + number.toString() + '_post_code', this.renderValue('bo' + number.toString() + '_post_code'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_bo" + number.toString() + '_mobile_number', classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_mobile_number' ? this.state.edit_field_key_value : this.renderValue('bo' + number.toString() + '_mobile_number'),
                            "name": 'bo' + number.toString() + '_mobile_number',
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_mobile_number' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="Contact Number"
                    />
                    </td><td width="50px;">{this.renderEditField('bo' + number.toString() + '_mobile_number', this.renderValue('bo' + number.toString() + '_mobile_number'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top", paddingTop: "10px"}}>
                    {this.renderRequestFieldCheckbox("request_bo" + number.toString() + '_email', classes)}
                    </td><td>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_email' ? this.state.edit_field_key_value : this.renderValue('bo' + number.toString() + '_email'),
                            "name": 'bo' + number.toString() + '_email',
                            "disabled": this.state.edit_field && this.state.edit_field_key === 'bo' + number.toString() + '_email' ? false : true,
                            "onChange": (event) => {this.setState({"edit_field_key_value": event.target.value});},
                        }}
                        labelText="Email Address"
                    />
                    </td><td width="50px;">{this.renderEditField('bo' + number.toString() + '_email', this.renderValue('bo' + number.toString() + '_email'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
                {
                    this.state.updateApplication && verify_id !== null && verify_id.length > 0
                    ? <GridItem md={12}>
                        <div style={{padding: "0 15px"}}>
                        <div style={{width: "100%"}}>
                            Verify ID status: 
                            <span style={{paddingLeft: "0.25rem", fontWeight: "bold"}} title={verify.tooltip.length > 0 ? verify.tooltip : verify.status.toUpperCase()}>
                                {verify.label.length > 0 ? verify.label : (verify.status.length > 0 ? verify.status.toUpperCase() : 'Pending')}
                            </span>
                        </div>
                        </div>
                    </GridItem>
                    : ''
                }
                {
                    this.state.updateApplication && verify_id !== null && verify_id.length > 0
                    ? <GridItem md={6}>
                        <div style={{padding: "0 15px"}}>
                        <div style={{width: "100%"}}>
                            <Button
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color={"success"}
                                onClick={(event) => this.checkVerifyIdStatus(number)}
                                >
                                    Check ID Verification Status
                            </Button>
                        </div>
                        </div>
                    </GridItem>
                    : ''
                }
                {
                    this.state.updateApplication
                    ? <GridItem md={verify_id !== null && verify_id.length > 0 ? 6 : 12}>
                        <div style={{padding: "0 15px"}}>
                        <div style={{width: "100%", paddingBottom: "1rem"}}>
                            <Button
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color={"success"}
                                onClick={(event) => this.sendVerifyId(
                                        number,
                                        this.renderValue('bo' + number.toString() + '_first_name') + ' ' + this.renderValue('bo' + number.toString() + '_last_name'),
                                        this.renderValue('bo' + number.toString() + '_email'),
                                        verify_id !== null && verify_id.length > 0,
                                        application_form
                                    )}
                                >
                                    Send Request for ID Verification
                            </Button>
                        </div>
                        </div>
                    </GridItem>
                    : ''
                }
            </GridContainer>
        </GridItem>
    }

    sendVerifyId(idx, name, email, already_requested = false, application_form) {
        if (this.state.updateApplication) {
            if (application_form === null) {
                this.setUsersNotification("Before requesting for ID Verification, please initiate the Request Signature(s) and wait for it's completion.", "danger");
                return;
            } else if (application_form.signature_status !== 'SIGNED') {
                this.setUsersNotification("Before requesting for ID Verification, please wait for the Request Signature(s)' completion. You may click on 'Check Signature Status' button to update completion state.", "danger");
                return;
            }

            this.setState({
                "confirmModal": true,
                "confirmModalMsg": already_requested
                    ? "A request for ID Verification for this beneficial owner has been requested before, do you want to request another transaction? This will send a request for ID Verification to " + name + " (" + email + ")"
                    : "Send request for ID Verification to " + name + " (" + email + ")?",
                "confirmModalTitle": "ID Verification",
                "action": "send_id_verification_request",
                "verify_ubo_idx": idx
            });
        }
    }

    doSendVerifyIdRequest() {
        this.setState({"confirmModal": false});
        const url = 'client/' + this.state.selected_client_id + '/application/' + this.state.selected_application_id + "/verify/ubo/" + this.state.verify_ubo_idx;

        helpers.showLoading();
        return axios(this.props)
            .post(
                url,
                {}
            )
            .then((response) => {
                this.setState({
                    "confirmModal": false,
                    "action": "",
                    "verify_ubo_idx": ""
                });
                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('error')) {
                    this.setUsersNotification(
                        typeof response.data.error === 'object' || Array.isArray(response.data.error)
                        ? (
                            response.data.error.hasOwnProperty('message')
                            ? response.data.error.message
                            : "Unable to send Verify ID request, please try again."
                        )
                        : (
                            typeof response.data.error === 'string'
                            ? response.data.error
                            : "Unable to send Verify ID request, please try again."
                        ),
                        "danger"
                    );
                } else {
                    this.setUsersNotification("Verify ID request sent.", "success");
                }
                this.fetchApplication(true, 'messages');
            })
            .catch((error) => {
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    checkVerifyIdStatus(idx) {
        if (this.state.updateApplication) {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Check ID Verification status?",
                "action": "check_id_verification_status",
                "verify_ubo_idx": idx
            });
        }
    }

    doCheckVerifyIdStatus() {
        this.setState({"confirmModal": false});
        const url = 'client/' + this.state.selected_client_id + '/application/' + this.state.selected_application_id + "/verify/ubo/" + this.state.verify_ubo_idx + "/status";

        helpers.showLoading();
        return axios(this.props)
            .get(url)
            .then((response) => {
                this.setState({
                    "confirmModal": false,
                    "action": "",
                    "verify_ubo_idx": ""
                });
                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('error')) {
                    this.setUsersNotification(
                        typeof response.data.error === 'object' || Array.isArray(response.data.error)
                        ? (
                            response.data.error.hasOwnProperty('message')
                            ? response.data.error.message
                            : "Unable to send Verify ID request, please try again."
                        )
                        : (
                            typeof response.data.error === 'string'
                            ? response.data.error
                            : "Unable to send Verify ID request, please try again."
                        ),
                        "danger"
                    );
                } else {
                    this.setUsersNotification("Status check completed.", "success");
                }
                this.fetchApplication(true, 'messages');
            })
            .catch((error) => {
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    requestASICSearch(already_requested = false, has_uploaded = false) {
        if (!this.checkIfAllPagesAreVerified([1,2,3,4,5])) {
            this.setUsersNotification("Please verify pages 1 through 5 first before sending an ASIC search request.", "danger");
        } else {
            this.setState({
                "confirmModal": has_uploaded ? !already_requested : true,
                "confirmWithInputModal": has_uploaded ? already_requested : false,
                "confirmModalMsg": already_requested ? "An ASIC Search for this application has been requested before, do you want to request another search?" : "Are you sure?",
                "confirmModalTitle": "Request ASIC Search",
                "action": "request_asic_search",
                "confirmModalMsgBoxLbl": null,
                "confirmModalMsgBoxOptional": true,
                "confirmModalMsgBody": "",
                "confirmModalMsgRows": 3,
                "confirmModalMinWidth": "",
                "confirmModalYesBtn": "Yes",
                "confirmModalNoBtn": "No"
            });
        }
    }

    doRequestASICSearch(message = '', data = null) {
        this.setState({"confirmModal": false, "confirmWithInputModal": false});
        const url = 'client/' + this.state.selected_client_id + '/application/' + this.state.selected_application_id + "/request/asic/search";

        helpers.showLoading();
        return axios(this.props)
            .post(url, {"message": message, "data": data})
            .then((response) => {
                this.setState({
                    "confirmModal": false,
                    "confirmWithInputModal": false,
                    "action": ""
                });

                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('error')) {
                    this.setUsersNotification(
                        typeof response.data.error === 'object' || Array.isArray(response.data.error)
                        ? (
                            response.data.error.hasOwnProperty('message')
                            ? response.data.error.message
                            : "Unable to send request, please try again."
                        )
                        : (
                            typeof response.data.error === 'string'
                            ? response.data.error
                            : "Unable to send request, please try again."
                        ),
                        "danger"
                    );
                } else {
                    this.setUsersNotification("ASIC Search request sent.", "success");
                }

                this.fetchApplication(true, 'request_asic_search');
            })
            .catch((error) => {
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    requestSignatures(already_generated = false) {
        if (!this.checkIfAllPagesAreVerified([1,2,3,4,5,6])) {
            this.setUsersNotification("Please verify pages 1 through 6 first before requesting for signatures.", "danger");
            return;
        }

        this.setState({
            "customSignatoriesModal": true,
            "customSignatoriesModalMsg": already_generated ? "An application form has been submitted for signing for this application, do you want to send another request?" : null,
            "customSignatoriesModalTitle": "Request Signature(s)",
            "action": "generate_application"
        });
    }

    doRequestSignatures() {
        this.setState({"customSignatoriesModal": false});
        const url = 'client/' + this.state.selected_client_id + '/application/' + this.state.selected_application_id + "/sign/form/request";

        helpers.showLoading();
        return axios(this.props)
            .post(url, {'signatory': this.state.agreement_payload})
            .then((response) => {
                this.setState({
                    "customSignatoriesModal": false,
                    "action": ""
                });

                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('error')) {
                    this.setUsersNotification(
                        typeof response.data.error === 'object' || Array.isArray(response.data.error)
                        ? (
                            response.data.error.hasOwnProperty('message')
                            ? response.data.error.message
                            : "Cannot request application form signing, please try again."
                        )
                        : (
                            typeof response.data.error === 'string'
                            ? response.data.error
                            : "Cannot request application form signing, please try again."
                        ),
                        "danger"
                    );
                } else {
                    this.setUsersNotification("Request to sign application form submitted.", "success");
                }

                this.fetchApplication(true, 'request_sign_form');
            })
            .catch((error) => {
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    requestSignatureStatus(file_id) {
        this.setState({"confirmModal": false});
        const url = 'client/' + this.state.selected_client_id + '/application/' + this.state.selected_application_id + "/sign/form/" + file_id + "/request/status";

        helpers.showLoading();
        return axios(this.props)
            .get(url)
            .then((response) => {
                this.setState({
                    "confirmModal": false,
                    "action": ""
                });

                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('error')) {
                    this.setUsersNotification(
                        typeof response.data.error === 'object' || Array.isArray(response.data.error)
                        ? (
                            response.data.error.hasOwnProperty('message')
                            ? response.data.error.message
                            : "Cannot request form signature status, please try again."
                        )
                        : (
                            typeof response.data.error === 'string'
                            ? response.data.error
                            : "Cannot request form signature status, please try again."
                        ),
                        "danger"
                    );
                } else {
                    this.setUsersNotification("Request signature status completed.", "success");
                }

                this.fetchApplication(true, 'request_sign_form');
            })
            .catch((error) => {
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    requestProcessingSignatures(already_generated = false, type, email, name) {
        if (type === 'custom') {
            if (!name || name.length === 0) {
                this.setUsersNotification("Signatory name is invalid.", "danger");
                return;
            } else if (!validations.validateEmail(email)) {
                this.setUsersNotification("Signatory email is invalid.", "danger");
                return;
            }
        }

        this.setState({
            "confirmModal": true,
            "confirmModalMsg": already_generated ? "A processing agreement form has been submitted for signing for this application, do you want to send another request?" : "Are you sure?",
            "confirmModalTitle": "Request Signature(s)",
            "action": "generate_processing",
            // "processing_payload": {
            //     "type": type,
            //     "email": email,
            //     "name": name
            // }
        });
    }

    doRequestProcessingSignatures() {
        this.setState({"confirmModal": false});
        const url = 'client/' + this.state.selected_client_id + '/application/' + this.state.selected_application_id + "/sign/processing/form/request";

        helpers.showLoading();
        return axios(this.props)
            .post(url, {/*'signatory': this.state.processing_payload*/})
            .then((response) => {
                this.setState({
                    "confirmModal": false,
                    "action": "",
                    "processing_payload": {}
                });

                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('error')) {
                    this.setUsersNotification(
                        typeof response.data.error === 'object' || Array.isArray(response.data.error)
                        ? (
                            response.data.error.hasOwnProperty('message')
                            ? response.data.error.message
                            : "Cannot request processing agreement form signing, please try again."
                        )
                        : (
                            typeof response.data.error === 'string'
                            ? response.data.error
                            : "Cannot request processing agreement form signing, please try again."
                        ),
                        "danger"
                    );
                } else {
                    this.setUsersNotification("Request to sign processing agreement form submitted.", "success");
                }

                this.fetchApplication(true, 'request_sign_form');
            })
            .catch((error) => {
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    requestCreateCredentials() {
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": "Are you sure?",
            "confirmModalTitle": "Request New Credentials",
            "action": "request_create_credentials"
        });
    }

    doRequestCreateCredentials() {
        this.setState({"confirmModal": false});
        const url = 'client/' + this.state.selected_client_id + '/application/' + this.state.selected_application_id + "/create/credentials";

        helpers.showLoading();
        return axios(this.props)
            .post(url, {})
            .then((response) => {
                this.setState({
                    "confirmModal": false,
                    "action": ""
                });

                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('error')) {
                    this.setUsersNotification(
                        typeof response.data.error === 'object' || Array.isArray(response.data.error)
                        ? (
                            response.data.error.hasOwnProperty('message')
                            ? response.data.error.message
                            : "Cannot request new credentials, please try again."
                        )
                        : (
                            typeof response.data.error === 'string'
                            ? response.data.error
                            : "Cannot request new credentials, please try again."
                        ),
                        "danger"
                    );
                    helpers.hideLoading();
                } else {
                    if (response.data.status === 'created') {
                        this.setUsersNotification("New merchant credentials created.", "success");
                        this.fetchApplication(true, 'request_sign_form');
                    } else {
                        helpers.hideLoading();
                        this.setUsersNotification("Request to create new credentials failed, please try again.", "danger");
                    }
                }
            })
            .catch((error) => {
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    checkSignificantName(name, callback = null) {
        this.setState({"confirmModal": false});
        const url = 'client/' + this.state.selected_client_id + '/application/' + this.state.selected_application_id + "/check/significant/name";

        helpers.showLoading();
        return axios(this.props)
            .post(url, {'significant_name' : name})
            .then((response) => {
                this.setState({
                    "confirmModal": false,
                    "action": ""
                });

                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('error')) {
                    this.setUsersNotification(
                        typeof response.data.error === 'object' || Array.isArray(response.data.error)
                        ? (
                            response.data.error.hasOwnProperty('message')
                            ? response.data.error.message
                            : "Cannot check name, please try again."
                        )
                        : (
                            typeof response.data.error === 'string'
                            ? response.data.error
                            : "Cannot check name, please try again."
                        ),
                        "danger"
                    );
                } else {
                    if (response.data.hasOwnProperty('in_use') && (response.data.in_use === 'false' || response.data.in_use === false)) {
                        this.setUsersNotification("Name check completed. Name is available", "success");
                        this.fetchApplication(true, 'request_sign_form');
                    } else {
                        helpers.hideLoading();
                        this.setUsersNotification("Name check completed. Name already in use", "danger");
                    }

                    if (callback !== null) try {callback(response.data);} catch(e) {}
                }
            })
            .catch((error) => {
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    statusCheckTimer = null;
    setStatusCheckCountdownTimer(created_at, last_poll, disable_check_status_button) {
        if (this.statusCheckTimer === null || this.statusCheckTimer === undefined) {
            if (disable_check_status_button) {
                setTimeout(() => {
                    if (!this.isUnmounted) this.setState({'checkStatusTimer': helpers.calculateStatusCheckRemainingTime(created_at, last_poll, moment, false)});
                }, 100);
                this.statusCheckTimer = setInterval(() => {
                    if (!this.isUnmounted) this.setState({'checkStatusTimer': helpers.calculateStatusCheckRemainingTime(created_at, last_poll, moment, false)});
                }, 30000);
            } else {
                if (this.statusCheckTimer !== null) {
                    clearInterval(this.statusCheckTimer);
                }
                if (!this.isUnmounted) setTimeout(() => {this.setState({'checkStatusTimer': ''});}, 100);
            }
        }
    }

    componentWillUnmount () {
        this.isUnmounted = true;
        if (this.statusCheckTimer !== null) {
            clearInterval(this.statusCheckTimer);
        }
    }

    renderPage6(classes) {
        let number_of_beneficials = parseInt(this.renderValue('number_of_beneficials'));
        if (isNaN(number_of_beneficials)) number_of_beneficials = 1;
        let asic_search_files = [], asic_file_id = this.renderValue('asic_search_file');
        if (asic_file_id.length > 0) {
            try {
                asic_file_id = asic_file_id.split(',');
                asic_file_id.sort(function(a, b) {return a - b;});
                asic_file_id = asic_file_id.map((id, key) => {return id.toString()});
                this.state.client_files.forEach(field => {
                    if (asic_file_id.includes(field.id.toString())) asic_search_files.push(field);
                });
            } catch(e) {}
        }
        let application_form = null;
        try {
            this.state.client_files.forEach(file => {
                if (file.is_application === true && file.filename.startsWith(this.state.application_file_name)) {
                    if (application_form === null || application_form.id < file.id) {
                        if (file.signature_status === 'PENDING' || file.signature_status === 'SIGNED') {
                            application_form = file;
                        }
                    }
                }}
            );
        } catch(e) {}

        let disable_check_status_button = true;
        if (application_form !== null && application_form.hasOwnProperty('application')) {
            let elapsed_days_since_creation = 0;
            let elapsed_days_since_poll = null;
            let created_at = null;
            let last_poll = null;
            if (application_form.application.hasOwnProperty('created_at') && application_form.application.created_at !== null) {
                elapsed_days_since_creation = moment.utc().diff(moment.utc(application_form.application.created_at), 'days');
                created_at = application_form.application.created_at;
            }
            if (application_form.application.hasOwnProperty('last_poll') && application_form.application.last_poll !== null) {
                elapsed_days_since_poll = moment.utc().diff(moment.utc(application_form.application.last_poll), 'minutes');
                last_poll = application_form.application.last_poll;
            }

            disable_check_status_button = elapsed_days_since_creation <= 0
                                || (elapsed_days_since_creation >= 1 && elapsed_days_since_poll !== null && elapsed_days_since_poll < 15);

            if (disable_check_status_button) this.setStatusCheckCountdownTimer(created_at, last_poll, disable_check_status_button);
        }

        return (
            <GridContainer
                key="details_grid_6"
            >
                <GridItem md={12}>
                    <div style={{padding: "0 15px"}}>
                    Ultimate Beneficial Owners
                    </div>
                </GridItem>
                {
                    asic_search_files.length > 0
                    ? <GridItem md={12}>
                        <div style={{padding: "0 15px"}}>
                        <p style={{margin: "15px 15px 0 15px"}}>ASIC Search Result PDF:</p>
                        </div>
                    </GridItem>
                    : ''
                }
                {
                    asic_search_files.length > 0
                    ? asic_search_files.map((field, key) => {
                        return <GridItem md={12} key={key}>
                            <div style={{padding: "0 15px"}}>
                            <p style={{margin: "15px 15px 0 15px"}}>
                                <i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-external-link-alt"} onClick={() => {
                                    return this.downloadDocument(field.id, field.filename);
                                }} /><span style={{cursor: "pointer"}} onClick={() => {
                                    return this.state.previewable_file_extensions.includes(field.filename.split('.').pop().toLowerCase())
                                        ? this.previewDocument(field.id, field.filename)
                                        : this.downloadDocument(field.id, field.filename);
                                }}>{field.filename}</span>
                            </p>

                            </div>
                        </GridItem>
                    })
                    : ''
                }
                <div style={{padding: "0 15px"}}>
                <Button
                    style={{margin: "15px"}}
                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                    color={"success"}
                    onClick={(event) => this.requestASICSearch(this.renderValue('abn_asic_search').toString() === '1', asic_search_files.length > 0)}
                    >
                        {this.renderValue('abn_asic_search').toString() === '1' ? 'ASIC Search Request Submitted' : 'ASIC Search'}
                </Button>
                </div>
                <GridItem md={12}>
                    {
                        application_form !== null
                        ? <GridItem md={12}>
                            <p style={{margin: "15px 15px 0 15px"}}>
                                {
                                    application_form.signature_status === 'SIGNED'
                                    ? "Signed Application Form:"
                                    : "Signature Status:"
                                }
                            </p>
                        </GridItem>
                        : ''
                    }
                    {
                        application_form !== null
                        ? (
                            application_form.signature_status === 'SIGNED'
                            ? <GridItem md={12}>
                                <p style={{margin: "15px"}}>
                                    <i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-external-link-alt"} onClick={() => {
                                        return this.downloadDocument(application_form.id, "Signed Application Form.pdf");
                                    }} /><span style={{cursor: "pointer"}} onClick={() => {
                                        return this.previewDocument(application_form.id, "Signed Application Form.pdf");
                                    }}>Signed Application Form.pdf</span>
                                </p>
                            </GridItem>
                            : <GridItem md={12}>
                                <table style={{margin: "15px 15px 0 15px"}}>
                                    <tbody>
                                        <tr>
                                            <td>Total Signatories:</td>
                                            <td>{parseInt(application_form.application.envelope_details.progress.total)}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Signed:</td>
                                            <td>{parseInt(application_form.application.envelope_details.progress.signed)}</td>
                                        </tr>
                                        {
                                            parseInt(application_form.application.envelope_details.progress.declined) > 0
                                            ? <tr>
                                                <td>Total Declined:</td>
                                                <td>{parseInt(application_form.application.envelope_details.progress.declined)}</td>
                                            </tr>
                                            : null
                                        }
                                        {
                                            parseInt(application_form.application.envelope_details.progress.error) > 0
                                            ? <tr>
                                                <td>Total Errored:</td>
                                                <td>{parseInt(application_form.application.envelope_details.progress.error)}</td>
                                            </tr>
                                            : null
                                        }
                                    </tbody>
                                </table>
                            </GridItem>
                        )
                        : ''
                    }
                    {
                        application_form === null
                        ? <Button
                            style={{margin: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color={"success"}
                            onClick={(event) => this.requestSignatures(application_form !== null)}
                            >
                                Request Signature(s)
                        </Button>
                        : (
                            application_form.signature_status === 'SIGNED'
                            ? ''
                            : (
                                (parseInt(application_form.application.envelope_details.progress.declined) + parseInt(application_form.application.envelope_details.progress.error))
                                >= 1 //parseInt(application_form.application.envelope_details.progress.total)
                                ? <Button
                                    style={{margin: "15px"}}
                                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                    color={"success"}
                                    onClick={(event) => this.requestSignatures(application_form !== null)}
                                    >
                                        Request New Signature(s)
                                </Button>
                                : <Button
                                    style={{margin: "15px"}}
                                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                    color={"success"}
                                    disabled={disable_check_status_button}
                                    onClick={(event) => {
                                        if (disable_check_status_button) {
                                            this.setUsersNotification("Request signature status update disabled, please check again later. This is to prevent multiple requests.", "danger");
                                            return;
                                        }
                                        this.requestSignatureStatus(application_form.id);
                                    }}
                                    >
                                        Check Signature Status {this.state.checkStatusTimer.length > 0 ? <br/> : ''}{this.state.checkStatusTimer}
                                </Button>
                            )
                        )
                    }
                </GridItem>
                {this.renderUbo(1, classes, application_form)}
                {number_of_beneficials > 1 ? this.renderUbo(2, classes, application_form) : ''}
                {number_of_beneficials > 2 ? this.renderUbo(3, classes, application_form) : ''}
                {number_of_beneficials > 3 ? this.renderUbo(4, classes, application_form) : ''}
                {this.renderFiles(classes, 'additional_supporting_doc_key', 'Upload Additional Information')}
                <GridItem md={6}>
                    <div style={{padding: "0 15px"}}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('how_did_you_hear_about_us'),
                            "name": "how_did_you_hear_about_us",
                            "disabled": true
                        }}
                        labelText="How did you hear about us?"
                    />
                    </div>
                </GridItem>
                <GridItem md={6}>
                    {/* Spacer */}
                </GridItem>
                <GridItem md={6}>
                    <div style={{padding: "0 15px"}}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('promo_code'),
                            "name": "promo_code",
                            "disabled": true
                        }}
                        labelText="Promo Code"
                    />
                    </div>
                </GridItem>
                <GridItem md={6}>
                    <div style={{padding: "0 15px"}}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('agent_code'),
                            "name": "agent_code",
                            "disabled": true
                        }}
                        labelText="Agent Code"
                    />
                    </div>
                </GridItem>
                <GridItem md={12}>
                    <div style={{padding: "0 15px"}}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('additional_comments_notes'),
                            "multiline": true,
                            "rows": 3,
                            "disabled": true
                        }}
                        labelText="Additional Comments"
                    />
                    </div>
                </GridItem>
                <GridItem md={6}>
                    <table style={{width: "100%"}}><tbody><tr><td style={{width: "1%", verticalAlign: "top"/*, paddingTop: "10px"*/}}>
                    {this.renderRequestFieldCheckbox("applicant_importance_grade", classes)}
                    </td><td>
                    <InputLabel
                        className={classes.selectLabel}
                        htmlFor="applicant_importance_grade"
                    >
                        Applicant Importance/Size
                    </InputLabel>
                    <Select
                        style={{width: "100%"}}
                        classes={{"select": classes.select}}
                        inputProps={{
                            "name": "applicant_importance_grade",
                            "id": "applicant_importance_grade"
                        }}
                        MenuProps={{"className": classes.selectMenu}}
                        onChange={(event) => {this.setState({"edit_field_key_value": event.target.value});}}
                        value={this.state.edit_field && this.state.edit_field_key === 'applicant_importance_grade' ? this.state.edit_field_key_value : this.renderValue('applicant_importance_grade')}
                        disabled={this.state.edit_field && this.state.edit_field_key === 'applicant_importance_grade' ? false : true}
                    >
                        <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={'applicant_importance_grade_0'} value="">Choose an option</MenuItem>
                        <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={'applicant_importance_grade_l'} value={'large'}>Large</MenuItem>
                        <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={'applicant_importance_grade_m'} value={'medium'}>Medium</MenuItem>
                        <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={'applicant_importance_grade_s' } value={'small'}>Small</MenuItem>
                    </Select>
                    </td><td width="50px;">{this.renderEditField('applicant_importance_grade', this.renderValue('applicant_importance_grade'), 'CustomInput')}</td></tr></tbody></table>
                </GridItem>
            </GridContainer>
        );
    }

    renderEscalationPage(classes, has_flagged_page) {
        let escalated = this.isEscalated(has_flagged_page);
        let can_escalate = true;
        let can_deescalate = false;
        if (escalated.escalated === 0) {
            if (this.props.auth.user.role === 'vc') {} else {
                can_escalate = false;
            }
        } else {
            // Escalation
            if (this.props.auth.user.role === 'vc') {
                can_escalate = escalated.escalated_to === 'vc' || escalated.escalated_to === 'tl'; // should be able to update current escalation
            } else if (this.props.auth.user.role === 'tl') {
                can_escalate = escalated.escalated_to === 'tl' || escalated.escalated_to === 'admin'; // should be able to update current escalation
            } else if (this.props.auth.user.role === 'admin') {
                can_escalate = false;
            }
            // Deescalation
            if (this.props.auth.user.role === 'admin') {
                can_deescalate =  escalated.escalated_to === 'admin';
            } else if (this.props.auth.user.role === 'tl') {
                can_deescalate = escalated.escalated_to === 'tl';
            } else if (this.props.auth.user.role === 'vc') {
                can_deescalate = escalated.escalated_to === 'vc' || escalated.escalated_to === 'tl';
            }
        }

        let reasons = this.collateFlagReasons();
        return (
            <GridContainer
                key="details_grid_escalation"
                style={{padding: '0 15px'}}
            >
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": reasons.join("\n"),
                            "name": 'escalation_reasons',
                            "multiline": true,
                            "rows": 16,
                            "disabled": true
                        }}
                        labelText={"Flag Reasons"}
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.state.escalation_message ? this.state.escalation_message : '',
                            "name": 'escalation_message',
                            "multiline": true,
                            "rows": 16,
                            "disabled": false,
                            "onChange": (event) => {
                                this.setState({"escalation_message": event.target.value});
                            },
                        }}
                        labelText={"Additional Details"}
                    />
                </GridItem>
                {
                    this.props.auth.user.role === 'admin' ? '' : 
                    <Button
                        style={{float: "left"}}
                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                        color={"success"}
                        disabled={!can_escalate}
                        onClick={(event) => {
                            this.escalateApplication();
                        }}
                        simple
                        >
                        Escalate
                    </Button>
                }
                <Button
                    style={{float: "left"}}
                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                    color={"warning"}
                    disabled={!can_deescalate}
                    onClick={(event) => {
                        this.deescalateApplication();
                    }}
                    simple
                    >
                    De-escalate & Unflag
                </Button>
            </GridContainer>
        );
    }

    renderPageRequested(classes, application_history) {
        let fields = [];
        application_history.forEach((application) => {
            application.details.request_field_values.forEach((field) => {
                if (!fields.includes(field['key'])) fields.push(field['key']);
            });
        });
        return (
            <GridContainer
                key="details_grid_requested"
                style={{padding: '0 15px'}}
            >
            {
                fields.map((field, key) => {
                    let field_config = null;
                    this.state.available_fields.forEach(f => {
                        if (f.registry_key === field) {
                            field_config = f;
                        }
                    });

                    let value = this.renderValue(field);
                    if (field_config === null) return '';
                    if (field_config.configuration.ui['ui:widget'] === 'CustomCheckbox') {
                        value = parseInt(value);
                        if (isNaN(value)) value = null;
                        return <GridItem key={key} md={12}>
                            <FormControlLabel
                                control={
                                    <div>
                                        <Checkbox
                                            disabled={true}
                                            checked={value === 1}
                                            value={value === 1}
                                            name={'cb_value_' + key}
                                            aria-label={'cb_value)' + key}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot,
                                                disabled: classes.disabledCheckboxAndRadio
                                            }}
                                        />
                                        {field_config.configuration.properties.title}
                                    </div>
                                }
                                classes={{"label": classes.label, root: classes.labelRoot}}
                            />
                        </GridItem>;
                    } else if (field_config.configuration.ui['ui:widget'] === 'CustomRadio') {
                        return <GridContainer key={"details_grid_" + key}>
                            <p key={key} style={{paddingLeft: "15px"}}>{field_config.configuration.properties.title}</p>
                            {
                                field_config.configuration.properties.enum.map((item, key) => {
                                    let checked = value === item;
                                    let label = item;
                                    if (field_config.configuration.properties.hasOwnProperty('enumNames') && field_config.configuration.properties.enumNames.hasOwnProperty(key)) {
                                        label = field_config.configuration.properties.enumNames[key];
                                    }

                                    return <GridItem key={key} md={12}>
                                        <FormControlLabel
                                            control={
                                                <div>
                                                    <Checkbox
                                                        disabled={true}
                                                        checked={checked}
                                                        value={checked}
                                                        name={'rd_value_' + key}
                                                        aria-label={'rd_value_' + key}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        classes={{
                                                            checked: classes.radio,
                                                            root: classes.radioRoot,
                                                            disabled: classes.disabledCheckboxAndRadio
                                                        }}
                                                    />
                                                    {label}
                                                </div>
                                            }
                                            classes={{"label": classes.label, root: classes.labelRoot}}
                                        />
                                    </GridItem>;
                                })
                            }
                        </GridContainer>;
                    } else if (field_config.configuration.ui['ui:widget'] === 'LabelWidget') {
                        return <p key={key} style={{paddingLeft: "15px"}}>{field_config.configuration.properties.title}</p>;
                    } else if (field_config.configuration.ui['ui:widget'] === 'textareaWidget') {
                        return <GridItem key={key} md={12}>
                            <CustomInput
                                formControlProps={{"fullWidth": true}}
                                inputProps={{
                                    "value": this.renderValue(field),
                                    "name": field,
                                    "multiline": true,
                                    "rows": 3,
                                    "disabled": true
                                }}
                                labelText={this.renderCustomFieldLabel(field)}
                            />
                        </GridItem>;
                    } else if (field_config.configuration.ui['ui:widget'] === 'labelledFileField') {
                        let registry = this.getRegistry(field_config.registry_key);
                        let value = this.renderValue(field_config.registry_key);
                        return <GridItem key={key} md={12}>
                            {
                                registry === null || value.length === 0
                                ? <div style={{borderBottom: "1px dotted #D2D2D2", margin: "1rem 0"}}>
                                        <p key={'grid_' + key + '_1'} style={{color: ""}}>{field_config.name}</p>
                                        <p key={'grid_' + key + '_2'} style={{paddingLeft: "15px", color: "red"}}>No uploaded file(s)</p>
                                    </div>
                                : <div style={{borderBottom: "1px dotted #D2D2D2", margin: "1rem 0"}}>
                                    <p key={'grid_' + key + '_1'}>{field_config.name}</p>
                                    {
                                        value.split(',').map((id, k) => {
                                            let file = null;
                                            try {
                                                this.state.client_files.forEach(field => {
                                                    if (parseInt(field.id) === parseInt(id)) {
                                                        file = field;
                                                    }
                                                });
                                            } catch(e) {}
                                            return file === null
                                                ? ''
                                                : <p key={'grid_' + key + '_2_' + k} style={{paddingLeft: "15px"}}>
                                                    <i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-external-link-alt"} onClick={() => {
                                                        return this.downloadDocument(id, file.filename);
                                                    }} /><span style={{cursor: "pointer"}} onClick={() => {
                                                        return this.state.previewable_file_extensions.includes(file.filename.split('.').pop().toLowerCase())
                                                            ? this.previewDocument(id, file.filename)
                                                            : this.downloadDocument(id, file.filename);
                                                    }}>{file.filename}</span>
                                                </p>;
                                        })
                                    }
                                </div>
                            }
                        </GridItem>;
                    } else if (field_config.configuration.ui['ui:widget'] === 'selectWidget') {
                        if (field_config.configuration.properties.hasOwnProperty('enum') && field_config.configuration.properties.hasOwnProperty('enumNames')) {
                            field_config.configuration.properties.enum.forEach((item, key) => {
                                if (value === item && field_config.configuration.properties.enumNames.hasOwnProperty(key)) {
                                    value = field_config.configuration.properties.enumNames[key];
                                }
                            });
                        }
                    }
                    
                    return <GridItem key={key} md={12}>
                            <CustomInput
                                formControlProps={{"fullWidth": true}}
                                inputProps={{
                                    "value": value,
                                    "name": field,
                                    "disabled": true
                                }}
                                labelText={this.renderCustomFieldLabel(field)}
                            />
                        </GridItem>;
                })
            }
            </GridContainer>
        );
    }

    setVerified(page, current_verified) {
        if (!current_verified && this.checkIfPageIsFlagged(page)) {
            this.setUsersNotification("Cannot change to verified. Page has an active flag.", "danger");
            return;
        }
        
        let tick_boxes = document.getElementById("page" + page + "_fields");
        let msg = "Are you sure?";
        if (!current_verified && tick_boxes) {
            if (tick_boxes.getElementsByClassName("tick-box-field-completion").length !== tick_boxes.querySelectorAll('input[class="tick-box-field-completion"]:checked').length) {
                msg = "There are fields that are NOT marked as verified.\n\nDo you want to continue?";
            }
        }

        if (this.state.updateApplication) {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": msg,
                "confirmModalTitle": "Set Page as " + (current_verified ? 'Unverified' : 'Verified') + "?",
                "action": "set_verified",
                "verify_page": page,
                "current_verified": current_verified
            });
        }
    }

    checkIfMatchReportViewed() {
        if (this.state.match_report_viewed !== false) return true;

        try {
            let viewers = Array.isArray(this.state.selected_approval.details.match_report_viewed)
                ? this.state.selected_approval.details.match_report_viewed
                : JSON.parse(this.state.selected_approval.details.match_report_viewed);
            return viewers.includes(this.props.auth.user.id);
        } catch(e) {}
        return false;
    }

    checkIfVerified(page) {
        if (page === 'escalation') {return true;}
        let details = this.state.selected_approval !== null && this.state.selected_approval.hasOwnProperty('details') ? this.state.selected_approval.details : {};
        details = details !== null && details.hasOwnProperty('verified') ? details.verified : {};
        return details.hasOwnProperty(page) ? (parseInt(details[page]) === 1 ? true : false) : false;
    }

    checkIfAllPagesAreVerified(pages = [1,2,3,4,5]) {
        let all_required_pages_verified = true;
        try {
            let details = this.state.selected_approval !== null && this.state.selected_approval.hasOwnProperty('details') ? this.state.selected_approval.details : {};
            if (details !== null && details.hasOwnProperty('verified')) {
                details = details.verified;
                pages.forEach(page => {
                    if (details.hasOwnProperty(page)) {
                        if (details[page] !== 1) all_required_pages_verified = false;
                    } else {
                        all_required_pages_verified = false;
                    }
                });
            } else {
                all_required_pages_verified = false;
            }
        } catch(e) {all_required_pages_verified = false;}
        return all_required_pages_verified;
    }

    checkIfFlagged(page) {
        let state = false;
        let message = null;
        try {
            let details = this.state.selected_approval !== null && this.state.selected_approval.hasOwnProperty('details') ? this.state.selected_approval.details : {};
            details = details !== null && details.hasOwnProperty('flags') ? details.flags : {};
            if (details.hasOwnProperty(page)) {
                state = parseInt(details[page]['state']) === 1 ? true : false;
                message = details[page]['reason'];
            }
        } catch (e) {}
        return {'flagged': state, 'message': message};
    }

    checkIfPageIsFlagged(page) {
        let flag = this.checkIfFlagged(page);
        return flag['flagged'];
    }

    hasFlaggedPage() {
        let has_flagged_page = false;
        [1, 2, 3, 4, 5, 6].forEach((page) => {
            if (this.checkIfPageIsFlagged(page)) {
                has_flagged_page = true;
            }
        });
        return has_flagged_page;
    }

    isEscalated(has_flagged_page = null) {
        if (has_flagged_page === null) {
            has_flagged_page = this.hasFlaggedPage();
        }
        let escalated = 0;
        let escalated_to = '';
        let escalated_by = '';
        let messages = null;
        if (has_flagged_page) {
            try {
                let details = this.state.selected_approval !== null && this.state.selected_approval.hasOwnProperty('details') ? this.state.selected_approval.details : {};
                details = details !== null && details.hasOwnProperty('escalation') ? details.escalation : null;
                if (details !== null) {
                    escalated = details.hasOwnProperty('escalated') ? parseInt(details.escalated) : 0;
                    escalated_to = details.hasOwnProperty('escalated_to') ? details.escalated_to : '';
                    escalated_by = details.hasOwnProperty('escalated_by') ? details.escalated_to : '';
                    messages = details.hasOwnProperty('messages') ? details.messages : null;
                }
            } catch (e) {}
        }
        return {'escalated': escalated, 'escalated_to': escalated_to, 'escalated_by': escalated_by, 'messages': messages};
    }

    collateFlagReasons() {
        let reasons = [];
        [1, 2, 3, 4, 5, 6].forEach((page) => {
            let flag = this.checkIfFlagged(page);
            if (flag.flagged === true) {
                reasons.push("Page " + page);
                reasons.push(flag.message);
                reasons.push("");
            }
        });
        return reasons;
    }

    setVerifiedButton(page, classes, has_flagged_page = false) {
        let details = this.state.selected_approval !== null && this.state.selected_approval.hasOwnProperty('details') ? this.state.selected_approval.details : {};
        details = details !== null && details.hasOwnProperty('verified') ? details.verified : {};
        let page_verified = this.checkIfVerified(page);
        let page_verified_by = '';
        if (this.state.selected_approval !== null && this.state.selected_approval.hasOwnProperty('details')) {
            details = this.state.selected_approval !== null && this.state.selected_approval.hasOwnProperty('details') ? this.state.selected_approval.details : {};
            details = details !== null && details.hasOwnProperty('verified_by_user') ? details.verified_by_user : {};
            if (details.hasOwnProperty(page)) {
                let verified_by = details[page];
                verified_by.reverse().forEach(user => {
                    let verified_state = parseInt(user.state) === 1 ? true : false;
                    let reason = '';
                    try {
                        if (user.hasOwnProperty('reason') && user.reason.toString().length > 0) {
                            reason = "\r\n     - " + user.reason;
                        }
                    } catch(e) {}
                    page_verified_by += user.date + " - Marked as " + (verified_state ? "verified" : "not verified") + " by " + user.author + " (" + user.author_type + ")" + reason +"\r\n";
                });
            }
        }

        let flag = page === 'requested' ? {'flagged': false, 'message': ''} : this.checkIfFlagged(page);
        return <span>
            <Button
                style={{float: "left"}}
                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                color={page_verified ? "success" : "danger"}
                disabled={!this.state.updateApplication}
                onClick={(event) => {
                    if (page === 2) {
                        if (!page_verified && !this.checkIfMatchReportViewed()) {
                            this.setUsersNotification("Please view the match report before verifying the page.", "danger");
                        } else {
                            this.setVerified(page, page_verified);
                        }
                    } else {
                        this.setVerified(page, page_verified);
                    }
                }}
                simple
                title={page_verified_by}
                >
                <span>{page_verified ? <i className="fas fa-check-circle"></i> : <i className="fas fa-times-circle"></i>} {page_verified ? "Verified" : "Not verified"}</span>
            </Button>
            {
                (this.props.auth.user.role !== 'vc' && !has_flagged_page) || page === 'requested' ? '' :
                <Button
                    style={{float: "left"}}
                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                    color={!flag['flagged'] ? "success" : "danger"}
                    disabled={!this.state.updateApplication}
                    onClick={(event) => this.showFlagPage(event, page, flag['flagged'], flag['message'])}
                    simple
                    title={flag['message']}
                    >
                    <span><i className="fas fa-flag"></i> {flag['flagged'] ? "Flagged" : "Flag"}</span>
                </Button>
            }
        </span>;
    }

    doSetVerifyPage(page, current_verified) {
        this.setState({"confirmModal": false});
        const url = "/applications/" + this.state.selected_application_id + "/page/verification",
            postObject = {"page": page, "verified" : current_verified ? 0 : 1};

        helpers.showLoading();
        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then(() => {
                this.setState({
                    "confirmModal": false,
                    "action": "",
                    "verify_page": "",
                    "current_verified": ""
                });

                this.callParentMethod('refreshApprovalsTable', [null, () => {helpers.hideLoading();}]);
            })
            .catch((error) => {
                console.log(error);
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    showFlagPage(event, page, flagged, reason) {
        event.preventDefault();

        let escalated = this.isEscalated(null);
        if (escalated.escalated === 1) {
            this.setUsersNotification("Application has a pending case escalation.", "danger");
            return;
        }

        this.setState({
            "flagPage": true,
            "flag_message": reason,
            "is_flagged": flagged,
            "flag_page": page
        });
    }

    handleFlagPageClose () {
        this.setState({
            "flagPage": false,
            "flag_message": "",
            "is_flagged": false,
            "flag_page": null
        });
    }

    handleFlagPage(id, message, page, action) {
        if (action === 1 && (!message || message.length === 0)) {
            this.setUsersNotification("Please add reason for flagging.", "danger");
        } else {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": action !== 1 ? "Remove Page Flag" : "Flag Page",
                "action": "flag_note",
                "parent_params": ['handleFlagPage', [id, message, page, action, ()=>{
                    this.handleFlagPageClose();
                    this.setState({"flag_message": "", "is_flagged": false, "flag_page": null});
                    helpers.hideLoading();
                }]]
            });
        }
    }

    generateMatchReport() {
        if (this.state.updateApplication) {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Generate Match Report?",
                "action": "generate_match_report"
            });
        }
    }

    doGenerateMatchReport(page, current_verified) {
        this.setState({"confirmModal": false});
        const url = "/client/" + this.state.selected_client_id + '/script/' + this.state.selected_client_script_id + '/step/' + this.state.selected_client_script_step_id;
        let data = {'form_data': {'generate' : 'match_report'}};

        helpers.showLoading();
        return axios(this.props)
            .post(url, data)
            .then(() => {
                this.setState({
                    "confirmModal": false,
                    "action": ""
                });

                this.callParentMethod('viewApplication', ['generate_match_report', this.state.selected_approval, this.state.application_status, true, () => {helpers.hideLoading();}, this.state.application_message_direction, this.state.application_message_sort]);
            })
            .catch((error) => {
                helpers.hideLoading();
            });
    }

    showAddNote(event, page) {
        event.preventDefault();
        this.setState({
            "addNote": true,
            "new_note": "",
            "add_note_page": page
        });
    }

    renderNotes(page, classes, header = '', showAdd = true) {
        let notes = this.state.page_notes && this.state.page_notes.hasOwnProperty(page.toString()) ? this.state.page_notes[page] : null;
        if (notes === null) {} else {
            notes.sort(function(a,b) {
                return a['id'] - b['id'];
            });
        }

        return (
            <GridContainer
                key={"details_grid_notes_page_" + page}
                style={{padding: '0 15px 15px 15px'}}
            >
                <table style={{padding: "0 15px", width: "100%"}}>
                    <thead>
                        <tr key={"tr_head1"}>
                            <th colSpan={2} style={{"textAlign": "left", "paddingBottom": "10px"}}>
                                {header.length > 0 ? header : "Notes"}
                            </th>
                            <th style={{"textAlign": "right", "paddingBottom": "10px"}}>
                                &nbsp;
                                {
                                    this.state.updateApplication && showAdd
                                    ? <Button
                                        style={{padding: "5px"}}
                                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                        color={"success"}
                                        disabled={!this.state.updateApplication}
                                        onClick={(event) => this.showAddNote(event, page)}
                                        simple
                                        >
                                        <i style={{paddingRight: "3px"}} className="fa fa-plus fa-sm" /> Add Note
                                    </Button>
                                    : null
                                }
                            </th>
                        </tr>
                        <tr key={"tr_head"}>
                            <td style={{"textAlign": "left", "paddingBottom": "10px", width: "50%"}}>Message</td>
                            <td style={{"textAlign": "left", "paddingBottom": "10px", width: "25%"}}>Author</td>
                            <td style={{"textAlign": "left", "paddingBottom": "10px", width: "25%"}}>Date</td>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        notes !== null
                        ? notes.map((note, key) => {
                            return <tr key={"tr_" + key}>
                                <td style={{"textAlign": "left", "paddingBottom": "10px"}}>{helpers.addLineBreaks(note.notes.replace(/[\n]/g, '<br>'))}</td>
                                <td style={{"textAlign": "left", "paddingBottom": "10px"}}>{note.author + " (" + note.author_type + ")"}</td>
                                <td style={{"textAlign": "left", "paddingBottom": "10px"}}>{moment.utc(note.date).format(helpers.date_format() + ' HH:mm:ss')}</td>
                            </tr>
                        })
                        : <tr key={"tr_body"}>
                            <td colSpan={3} style={{"textAlign": "center"}}>No notes</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </GridContainer>
        );
    }

    renderPageNotes(classes, pages) {
        return pages.map((page, key) => {
            return this.renderNotes(page, classes, (page === 'requested' ? 'Requested Page' : 'Page ' + page.toString()), false)
        });
    }

    renderPageMassages(classes) {
        let messages = this.state.approval_message && this.state.approval_message.hasOwnProperty('data') ? this.state.approval_message.data : [];

        return <GridContainer
            key={"details_grid_messages_page"}
            style={{padding: '0 15px 15px 15px'}}
        >
            <table style={{padding: "0 15px", width: "100%"}}>
                <thead>
                    <tr key={"tr_head1"}>
                        <th style={{"textAlign": "left", "paddingBottom": "10px"}}>
                            Messages
                        </th>
                        <th colSpan={2} style={{"textAlign": "right", "paddingBottom": "10px"}}>
                            &nbsp;
                            {
                                this.state.updateApplication
                                ? <Button
                                    style={{padding: "5px"}}
                                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                    color={"success"}
                                    disabled={!this.state.updateApplication}
                                    onClick={(event) => this.showMessageBox('New Message', null, true, true)}
                                    simple
                                    >
                                    <i style={{paddingRight: "3px"}} className="fa fa-plus fa-sm" /> New Message
                                </Button>
                                : null
                            }
                        </th>
                    </tr>
                    <tr key={"tr_head"}>
                        <th style={{"textAlign": "left", "paddingBottom": "10px", width: "50%"}}>
                            <span style={{ cursor: "pointer", paddingRight: '3px' }} onClick={()=>{
                                helpers.showLoading();
                                let direction = this.state.application_message_sort === 'subject' ? (this.state.application_message_direction === 'desc' ? 'asc' : 'desc') : 'desc';
                                this.callParentMethod('viewApplication', ['messages', this.state.selected_approval, this.state.application_status, true, () => {this.setState({'application_message_direction' : direction, 'application_message_sort': 'subject'}); helpers.hideLoading();}, direction, 'subject']);
                            }}>Subject</span>
                            {
                                this.state.application_message_sort === 'subject'
                                ? (this.state.application_message_direction === 'desc' ? <i className="fas fa-sort-up"></i> : <i className="fas fa-sort-down"></i>)
                                : ""
                            }
                        </th>
                        <th style={{"textAlign": "left", "paddingBottom": "10px", width: "25%"}}>Sender</th>
                        <th style={{"textAlign": "left", "paddingBottom": "10px", width: "25%"}}>
                            <span style={{ cursor: "pointer", paddingRight: '3px' }} onClick={()=>{
                                helpers.showLoading();
                                let direction = this.state.application_message_sort === 'received' ? (this.state.application_message_direction === 'desc' ? 'asc' : 'desc') : 'desc';
                                this.callParentMethod('viewApplication', ['messages', this.state.selected_approval, this.state.application_status, true, () => {this.setState({'application_message_direction' : direction, 'application_message_sort': 'received'}); helpers.hideLoading();}, direction, 'received']);
                            }}>Date</span>
                            {
                                this.state.application_message_sort === 'received'
                                ? (this.state.application_message_direction === 'desc' ? <i className="fas fa-sort-up"></i> : <i className="fas fa-sort-down"></i>)
                                : ""
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                {
                    messages !== null && messages.length > 0
                    ? messages.map((message, key) => {
                        return <tr key={"tr_" + key}>
                            <td style={{"textAlign": "left", "paddingBottom": "10px"}}>
                                { message.attachments.length > 0 ? <i style={{paddingRight: "3px"}} className="fas fa-paperclip fa-sm" /> : '' }<span style={{ cursor: "pointer" }} onClick={()=>{this.showMessageBox('Message', message);}}>{message.subject}</span>
                            </td>
                            <td style={{"textAlign": "left", "paddingBottom": "10px"}}>{message.sender_details.sender + (message.sender_details.sender_type.toString().length > 0 ? ' (' + message.sender_details.sender_type + ')' : '')}</td>
                            <td style={{"textAlign": "left", "paddingBottom": "10px"}}>{moment.utc(message.created_at).format(helpers.date_format() + ' HH:mm:ss')}</td>
                        </tr>
                    })
                    : <tr key={"tr_body"}>
                        <td colSpan={3} style={{"textAlign": "center"}}>No messages</td>
                    </tr>
                }
                </tbody>
            </table>
        </GridContainer>
    }

    setupTabs(classes) {
        let application_history = this.state.application_history;
        application_history = application_history.filter((history) => history.details !== null && history.details.hasOwnProperty('request_field_values'));
        let has_requests = application_history.length > 0;
        let has_flagged_page = this.hasFlaggedPage();
        let escalated = this.isEscalated(has_flagged_page);
        if (has_flagged_page) {
            if (this.props.auth.user.role === 'admin' && escalated.escalated_to !== 'admin') {
                has_flagged_page = false; // Not escalated for admins
            } else if (this.props.auth.user.role === 'tl' && escalated.escalated_to !== 'tl' && escalated.escalated_to !== 'admin') {
                has_flagged_page = false; // Not escalated for tls
            }
        }
        let pages = [1, 2, 3, 4, 5, 6];
        if (has_flagged_page) pages.push('escalation');
        if (has_requests) pages.push('requested');

        let tabs = [
            {
                "tabName": "Page 1",
                "tabStyle": this.checkIfPageIsFlagged(1) ? { backgroundColor: "rgba(244, 67, 54, 0.9)" } : (this.checkIfVerified(1) ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {}),
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}} id="page1_fields">
                    {this.renderPage1(classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    {this.renderNotes(1, classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {this.setVerifiedButton(1, classes, has_flagged_page)}
                        <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="success"
                            disabled={this.state.btnDisabled}
                            onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                            simple
                            >
                            Next&#8250;
                        </Button>
                    </div>
                </GridContainer>
            },
            {
                "tabName": "Page 2",
                "tabStyle": this.checkIfPageIsFlagged(2) ? { backgroundColor: "rgba(244, 67, 54, 0.9)" } : (this.checkIfVerified(2) ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {}),
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}} id="page2_fields">
                    {this.renderPage2(classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    {this.renderNotes(2, classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {this.setVerifiedButton(2, classes, has_flagged_page)}
                        <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="success"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                            simple
                            >
                            Next&#8250;
                        </Button>
                    </div>
                </GridContainer>
            },
            {
                "tabName": "Page 3",
                "tabStyle": this.checkIfPageIsFlagged(3) ? { backgroundColor: "rgba(244, 67, 54, 0.9)" } : (this.checkIfVerified(3) ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {}),
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}} id="page3_fields">
                    {this.renderPage3(classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    {this.renderNotes(3, classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {this.setVerifiedButton(3, classes, has_flagged_page)}
                        <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="success"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                            simple
                            >
                            Next&#8250;
                        </Button>
                    </div>
                </GridContainer>
            },
            {
                "tabName": "Page 4",
                "tabStyle": this.checkIfPageIsFlagged(4) ? { backgroundColor: "rgba(244, 67, 54, 0.9)" } : (this.checkIfVerified(4) ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {}),
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}} id="page4_fields">
                    {this.renderPage4(classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    {this.renderNotes(4, classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {this.setVerifiedButton(4, classes, has_flagged_page)}
                        <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="success"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                            simple
                            >
                            Next&#8250;
                        </Button>
                    </div>
                </GridContainer>
            },
            {
                "tabName": "Page 5",
                "tabStyle": this.checkIfPageIsFlagged(5) ? { backgroundColor: "rgba(244, 67, 54, 0.9)" } : (this.checkIfVerified(5) ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {}),
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}} id="page5_fields">
                    {this.renderPage5(classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    {this.renderNotes(5, classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {this.setVerifiedButton(5, classes, has_flagged_page)}
                        <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="success"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                            simple
                            >
                            Next&#8250;
                        </Button>
                    </div>
                </GridContainer>
            },
            {
                "tabName": "Page 6",
                "tabStyle": this.checkIfPageIsFlagged(6) ? { backgroundColor: "rgba(244, 67, 54, 0.9)" } : (this.checkIfVerified(6) ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {}),
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}} id="page6_fields">
                    {this.renderPage6(classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    {this.renderNotes(6, classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {this.setVerifiedButton(6, classes, has_flagged_page)}
                        <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        {/* {
                            has_requests
                            ? */}
                            <Button
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color="success"
                                onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                                simple
                                >
                                Next&#8250;
                            </Button>
                            {/* :""
                        } */}
                    </div>
                </GridContainer>
            },
        ];

        if (has_flagged_page) {
            tabs.push(
                {
                    "tabName": "Escalation",
                    "tabStyle": {},
                    "tabContent":
                    <GridContainer style={{"minHeight": "150px"}} id="escalation_page_fields">
                        {this.renderEscalationPage(classes, has_flagged_page)}
                        <hr style={{width: "100%", margin: "5px 15px"}}/>
                        {/* {this.renderNotes('requested', classes)} */}
                        <hr style={{width: "100%", margin: "5px 15px"}}/>
                        <div style={{width: '100%', textAlign: 'right'}}>
                            {/* {this.setVerifiedButton('requested', classes)} */}
                            <Button
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color="info"
                                onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                                simple
                            >
                                &#8249;Back
                            </Button>
                            <Button
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color="success"
                                onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                                simple
                                >
                                Next&#8250;
                            </Button>
                        </div>
                    </GridContainer>
                }
            );
        }

        if (has_requests) {
            tabs.push(
                {
                    "tabName": "Requested",
                    "tabStyle": this.checkIfVerified('requested') ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {},
                    "tabContent":
                    <GridContainer style={{"minHeight": "150px"}} id="request_page_fields">
                        {this.renderPageRequested(classes, application_history)}
                        <hr style={{width: "100%", margin: "5px 15px"}}/>
                        {this.renderNotes('requested', classes)}
                        <hr style={{width: "100%", margin: "5px 15px"}}/>
                        <div style={{width: '100%', textAlign: 'right'}}>
                            {this.setVerifiedButton('requested', classes, has_flagged_page)}
                            <Button
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color="info"
                                onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                                simple
                            >
                                &#8249;Back
                            </Button>
                            <Button
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color="success"
                                onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                                simple
                                >
                                Next&#8250;
                            </Button>
                        </div>
                    </GridContainer>
                }
            );
        }

        tabs.push(
            {
                "tabName": "Notes",
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}} id="notes_page_fields">
                    {this.renderPageNotes(classes, pages)}
                    <div style={{width: '100%', textAlign: 'right'}}>
                        <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="success"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                            simple
                            >
                            Next&#8250;
                        </Button>
                    </div>
                </GridContainer>
            }
        );

        tabs.push(
            {
                "tabName": "Messages",
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}} id="messages_page_fields">
                    {this.renderPageMassages(classes, pages)}
                    <div style={{width: '100%', textAlign: 'right'}}>
                        <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        {
                            this.state.updateApplication
                            ? <Button
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color="success"
                                onClick={(event) => {
                                    let to_tab = this.state.currentTab+1;
                                    this.setCurrentTab(to_tab);
                                }}
                                simple
                                >
                                Next&#8250;
                            </Button>
                            : ''
                        }
                    </div>
                </GridContainer>
            }
        );

        if (this.state.updateApplication) {
            tabs.push(
                {
                    "tabName": "Change Status",
                    "tabContent":
                    <GridContainer style={{"minHeight": "150px"}} id="change_status_page_fields">
                        <ChangeApplicationStatusDialog
                            hasFlaggedPage={this.hasFlaggedPage}
                            checkIfVerified={this.checkIfVerified}
                            applicationView={this.state.applicationView}
                            onClose={() => this.handleStatusChangeClose()}
                            handleStatusChange={this.handleStatusChange}
                            available_fields={this.state.available_fields}
                            canApprove={this.state.canApprove}
                            mcc={this.getRegistryValue('mcc')}
                            renderValue={this.renderValue}
                            high_risk_mccs={this.state.high_risk_mccs}
                            btnDisabled={this.state.changeStatusBtnDisabled}
                            // request_fields={this.state.request_fields}
                            selected_request_fields={this.state.view_app_selected_request_fields}
                            verified_fields={this.state.verified_fields}
                            number_of_beneficials={parseInt(this.renderValue('number_of_beneficials'))}
                            {...this.state}
                            ref={this.changeAppStatus}
                            handleButtonState={(state) => {this.setState({"changeStatusBtnDisabled": state});}}
                            requestSignatureStatus={this.requestSignatureStatus}
                            requestProcessingSignatures={this.requestProcessingSignatures}
                            requestCreateCredentials={this.requestCreateCredentials}
                            checkSignificantName={this.checkSignificantName}
                            setUsersNotification={this.setUsersNotification}
                            downloadDocument={this.downloadDocument}
                            previewDocument={this.previewDocument}
                            saveEditedFieldFromStatus={this.saveEditedFieldFromStatus}
                            updateSelectedRequestFields={(requests) => {
                                let request_fields = this.initRequestableFields();
                                if (requests.length > 0) {
                                    request_fields.forEach((field, index) => {
                                        if (requests.includes(field.key)) {
                                            request_fields[index].checked = true;
                                        }
                                    });
                                }
                                this.setState({"view_app_selected_request_fields": requests, "request_fields": request_fields});
                            }}
                            current_status={this.state.change_status_current_status}
                            additional_fields_values={this.state.change_status_additional_fields_values}
                            message={this.state.change_status_message}
                            updateChangeStatusState={(current_status, additional_fields_values, message, approved_signatory_type, approved_signatory_email, approved_signatory_name) => {
                                this.setState({
                                    "change_status_current_status": current_status,
                                    "change_status_additional_fields_values": additional_fields_values,
                                    "change_status_message": message,
                                    "approved_signatory_type": approved_signatory_type,
                                    "approved_signatory_email": approved_signatory_email,
                                    "approved_signatory_name": approved_signatory_name
                                });
                            }}
                        />
                        <div style={{width: '100%', textAlign: 'right'}}>
                            <Button
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color="info"
                                onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                                simple
                            >
                                &#8249;Back
                            </Button>
                        </div>
                    </GridContainer>
                }
            );
        }

        return tabs;
    }

    showMessageBox(title, contents = null, is_new_message = false, show_new_message = false) {
        let new_message_subject = '';
        if (this.state.show_new_message === null || this.state.show_new_message === false || (this.state.show_new_message !== show_new_message && contents !== null)) {
            new_message_subject = is_new_message ? "" : "Re: " + contents.subject;
        }

        this.setState({
            "show_message_dialog": true,
            "message_dialog_title": title,
            "message_dialog_contents": contents,
            "is_new_message": is_new_message,
            "show_new_message": show_new_message,
            "new_message_subject": new_message_subject,
            "new_message_body": '',
            "new_message_attachments": null
        });
    }

    renderMessageBody(body) {
        if (body !== null) {
            let parts = body.split('\\n');
            if (parts.length < 1) {
                parts = body.split('\n');
            }
            return parts.map((part, key) => {
                return <span key={key}>{part.replaceAll('&nbsp;', '\u00A0')}<br/></span>;
            });
        }
        return '';
    }

    renderMessageAttachments(attachments) {
        return attachments.map((attachment, key) => {
            let file = null;
            try {
                this.state.client_files.forEach(field => {
                    if (parseInt(field.id) === parseInt(attachment.file_id)) {
                        file = field;
                    }
                });
            } catch(e) {}
            return file === null
                ? ''
                : <p key={'grid_message_attachment_' + key}>
                    <i style={{paddingRight: "5px", cursor: "pointer"}} className={"fas fa-external-link-alt"} onClick={() => {
                        return this.downloadDocument(attachment.file_id, file.filename);
                    }} /><span style={{cursor: "pointer"}} onClick={() => {
                        return this.state.previewable_file_extensions.includes(file.filename.split('.').pop().toLowerCase())
                            ? this.previewDocumentDialog(attachment.file_id, file.filename)
                            : this.downloadDocument(attachment.file_id, file.filename);
                    }}>{file.filename}</span>
                </p>;
        });
    }

    deleteMessage() {
        if (this.state.updateApplication) {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Delete Message",
                "action": "delete_message"
            });
        }
    }

    doDeleteMessage() {
        this.setState({"confirmModal": false});
        const url = 'applications/' + this.state.selected_application_id + '/message/' + this.state.message_dialog_contents.id + '/delete';

        helpers.showLoading();
        return axios(this.props)
            .post(url, {})
            .then(() => {
                this.setState({
                    "confirmModal": false,
                    "action": "",
                    "show_message_dialog": false
                });
                this.callParentMethod('viewApplication', ['messages', this.state.selected_approval, this.state.application_status, true, () => {helpers.hideLoading();}, this.state.application_message_direction, this.state.application_message_sort]);
                this.setUsersNotification("Message deleted.", "success");
            })
            .catch((error) => {
                this.processErrorAxios(error);
                helpers.hideLoading();
            });
    }

    showReplyForm() {
        this.setState({
            "is_new_message": false,
            "show_new_message": true
        });
    }

    sendMessage() {
        if (this.state.updateApplication) {
            if (this.state.new_message_subject.length === 0) {
                this.setUsersNotification("Message subject is required.", "danger");
                return;
            }
            if (this.state.new_message_body.length === 0 && (this.state.new_message_attachments === null || this.state.new_message_attachments.length === 0)) {
                this.setUsersNotification("Message body is required.", "danger");
                return;
            }

            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Send Message",
                "action": "send_message"
            });
        }
    }

    doSendMessage() {
        this.setState({"confirmModal": false});
        const url = 'applications/' + this.state.selected_application_id + '/message';
        helpers.showLoading();

        let fd = new FormData();
        fd.append('subject', this.state.new_message_subject);
        fd.append('body', this.state.new_message_body);
        fd.append('reply_to', this.state.is_new_message ? '' : (this.state.message_dialog_contents ? this.state.message_dialog_contents.id : ''));
        if (this.state.new_message_attachments !== null && this.state.new_message_attachments.length > 0) {
            for (let i=0; i<this.state.new_message_attachments.length; i++) {
                const file = this.state.new_message_attachments[i];
                if (file.size > 4194304 || file.fileSize > 4194304) {
                } else {
                    fd.append('files[]', file);
                }
            };
        }

        return axios(this.props)
            .post(url, fd, {headers: {
                'Content-Type': 'multipart/form-data'
              }})
            .then(() => {
                this.setState({
                    "confirmModal": false,
                    "action": "",
                    "show_message_dialog": false
                });
                this.callParentMethod('viewApplication', ['messages', this.state.selected_approval, this.state.application_status, true, () => {helpers.hideLoading();}, this.state.application_message_direction, this.state.application_message_sort]);
                this.setUsersNotification("Message sent.", "success");
            })
            .catch((error) => {
                this.processErrorAxios(error);
                helpers.hideLoading();
            });
    }

    processErrorAxios (error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            (msg, color) => {
                this.setUsersNotification(
                    typeof msg === 'string' ? msg : msg.join('<br>'),
                    color
                );
            }
        );
    }

  render () {
      const {classes} = this.props;
      return (
        <div>
            {this.state.doneModalMsg
                ? <Snackbar
                    close
                    closeNotification={() => {
                        this.handleCloseNotification();
                    }}
                    color={this.state.tr_color}
                    icon={AddAlert}
                    message={this.state.doneModalMsg}
                    open={this.state.tr}
                    place="tr"
                />
                : ""}
              <DialogTitle
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  style={{"paddingBottom": "0px"}}
              >
                  Application {this.state.selected_approval.id} {this.state.current_status !== null ? <span style={{"color": helpers.application_status_colors()[this.state.current_status] }}>({helpers.fixStatusLabel(this.state.current_status ? this.state.current_status : '')})</span> : ''}
                  <br/>
                  {this.state.application_details}
              </DialogTitle>
              <DialogContent
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  id="update-profile-confirm-modal-slide-description"
                  style={{overflow: "hidden"}}
              >
                <GridContainer>
                    <GridItem md={this.state.side_file_open ? 6 : 12}>
                        <CustomTabs
                            marginBottom={"5px"}
                            changeTabCallBack={(tab) => {
                                this.setCurrentTab(tab);
                            }}
                            headerColor="primary"
                            tabs={this.setupTabs(classes)}
                            value={this.state.currentTab}
                            bodyStyle={{"overflow": "hidden auto", "height": "72vh" , "paddingBottom": "4rem"}}
                        />
                    </GridItem>
                    <GridItem md={this.state.side_file_open ? 6 : 12} style={{display: this.state.side_file_open ? "" : "none"}}>
                        <div style={{width: '100%', display: "flex", justifyContent: "flex-end"}}>
                            <span style={{cursor: "pointer"}} onClick={() => {
                                this.setState({side_file_open: false});
                                this.setViewApplicationDialog(false);
                            }}>X</span>
                        </div>
                        <div style={{width: '100%', "border": "1px solid rgba(0, 0, 0, 0.08)", "borderRadius": "5px"}}>
                            <iframe title="preview" id="preview" name="preview" src="" style={{"border": "none", "width": "100%", "height": "74.4vh"}}>
                            </iframe>
                        </div>
                    </GridItem>
                </GridContainer>
                {
                    this.state.previewDocumentModal
                    ? <PreviewDocument
                        value={this.state.previewDocumentValue}
                        name={this.state.previewDocumentName}
                        client_id={this.state.selected_client_id}
                        onClose={() => {
                            this.setState({
                                "previewDocumentModal": false,
                                "previewDocumentValue": null,
                                "previewDocumentName": null
                            });
                        }}
                        auth={this.props.auth}
                        previewDocumentModal={this.state.previewDocumentModal}
                    />
                    : ''
                }
                <AddApplicationPageNoteDialog
                    onClose={() => this.handlePageNoteClose()}
                    handleAddNote={this.handleAddNote}
                    {...this.state}
                />
                <FlagApplicationPageDialog
                    onClose={() => this.handleFlagPageClose()}
                    handleFlagPage={this.handleFlagPage}
                    {...this.state}
                />
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.handleClose("confirmModal")}
                    onYesClick={() => this.handleConfirmClick()}
                />
                <CustomSignatoriesDialog
                    customSignatoriesModal={this.state.customSignatoriesModal}
                    customSignatoriesModalMsg={this.state.customSignatoriesModalMsg}
                    customSignatoriesModalTitle={this.state.customSignatoriesModalTitle}
                    onClose={() => this.handleClose("customSignatoriesModal")}
                    onYesClick={(signatories) => {
                        this.setState({"agreement_payload": signatories}, function() {this.handleConfirmClick()});
                    }}
                    renderValue={this.renderValue}
                    signatories={this.renderValue('custom_signatories')}
                    number_of_beneficials={parseInt(this.renderValue('number_of_beneficials'))}
                    setUsersNotification={(msg, color) => {this.setUsersNotification(msg, color);}}
                />
                {
                    this.state.confirmWithInputModal
                    ? (
                        this.state.action === 'request_asic_search' ?
                        <AsicConfirmWithInputDialog
                            confirmWithInputModal={this.state.confirmWithInputModal}
                            confirmModalMsg={this.state.confirmModalMsg}
                            confirmModalTitle={this.state.confirmModalTitle}
                            onClose={() => this.handleClose("confirmWithInputModal")}
                            onYesClick={(message, data) => this.handleConfirmClick(message, data)}
                            messageLabel={this.state.confirmModalMsgBoxLbl ? this.state.confirmModalMsgBoxLbl : "Message (optional)"}
                            messageOptional={this.state.confirmModalMsgBoxOptional !== undefined && this.state.confirmModalMsgBoxOptional !== null ? this.state.confirmModalMsgBoxOptional : true}
                            setUsersNotification={(msg, color) => {this.setUsersNotification(msg, color);}}
                            message={this.state.confirmModalMsgBody ? this.state.confirmModalMsgBody : ""}
                            rows={this.state.confirmModalMsgRows}
                            minWidth={this.state.confirmModalMinWidth}
                            yesLbl={this.state.confirmModalYesBtn ? this.state.confirmModalYesBtn : "Yes"}
                            noLbl={this.state.confirmModalNoBtn ? this.state.confirmModalNoBtn : "No"}
                            auth={this.props.auth}
                        />
                        :<ConfirmWithInputDialog
                            confirmWithInputModal={this.state.confirmWithInputModal}
                            confirmModalMsg={this.state.confirmModalMsg}
                            confirmModalTitle={this.state.confirmModalTitle}
                            onClose={() => this.handleClose("confirmWithInputModal")}
                            onYesClick={(message, data) => this.handleConfirmClick(message, data)}
                            messageLabel={this.state.confirmModalMsgBoxLbl ? this.state.confirmModalMsgBoxLbl : "Message (optional)"}
                            messageOptional={this.state.confirmModalMsgBoxOptional !== undefined && this.state.confirmModalMsgBoxOptional !== null ? this.state.confirmModalMsgBoxOptional : true}
                            setUsersNotification={(msg, color) => {this.setUsersNotification(msg, color);}}
                            message={this.state.confirmModalMsgBody ? this.state.confirmModalMsgBody : ""}
                            rows={this.state.confirmModalMsgRows}
                            minWidth={this.state.confirmModalMinWidth}
                            yesLbl={this.state.confirmModalYesBtn ? this.state.confirmModalYesBtn : "Yes"}
                            noLbl={this.state.confirmModalNoBtn ? this.state.confirmModalNoBtn : "No"}
                            auth={this.props.auth}
                        />
                    )
                    : ''
                }

                <Dialog
                    aria-describedby="message-modal-slide-description"
                    aria-labelledby="small-modal-slide-title"
                    classes={{
                        "root": `${classes.center} ${classes.modalRoot}`,
                        "paper": `${classes.modal} ${classes.modalSmall}`
                    }}
                    fullWidth
                    keepMounted
                    onClose={() => {this.setState({"show_message_dialog": false});}}
                    open={this.state.show_message_dialog}
                    id="message-dialog"
                >
                    <DialogTitle
                        className={`${classes.modalBody} ${classes.modalSmallBody}`}
                        style={{"paddingBottom": "0px"}}
                    >
                        {this.state.message_dialog_title}
                    </DialogTitle>
                    <DialogContent
                        className={`${classes.modalBody} ${classes.modalSmallBody}`}
                        id="message-modal-slide-description"
                    >

                        <GridContainer style={{display: this.state.is_new_message ? "none" : ""}}>
                            <GridItem md={12}>
                                <b>Subject:</b> {this.state.message_dialog_contents !== null ? this.state.message_dialog_contents.subject : ''}
                            </GridItem>
                            <GridItem md={12}>
                                <div style={{minHeight: "150px", margin: "10px 15px", maxHeight: "85vh", overflowY: "auto"}}>
                                    {this.state.message_dialog_contents !== null ? this.renderMessageBody(this.state.message_dialog_contents.body) : ''}
                                </div>
                            </GridItem>
                            {
                                this.state.message_dialog_contents !== null && this.state.message_dialog_contents.attachments !== null && this.state.message_dialog_contents.attachments.length > 0
                                ? <GridItem md={12}>
                                    <p><b>Attachments:</b></p>
                                    {this.renderMessageAttachments(this.state.message_dialog_contents.attachments)}
                                </GridItem>
                                : ''
                            }
                        </GridContainer>
                        
                        <GridContainer style={{display: this.state.show_new_message ? "" : "none"}}>
                            <GridItem md={12}>
                                <b>Compose Message</b>
                            </GridItem>
                            <GridItem md={12}>
                                <CustomInput
                                    formControlProps={{"fullWidth": true}}
                                    inputProps={{
                                        "onChange": (event) => {
                                            this.setState({"new_message_subject": event.target.value});
                                        },
                                        "value": this.state.new_message_subject,
                                        "name": "new_message_subject",
                                    }}
                                    labelText="Subject"
                                />
                            </GridItem>
                            <GridItem md={12}>
                                <CustomInput
                                    formControlProps={{"fullWidth": true}}
                                    inputProps={{
                                        "onChange": (event) => {
                                            this.setState({"new_message_body": event.target.value});
                                        },
                                        "value": this.state.new_message_body,
                                        "name": "new_message_body",
                                        "multiline": true,
                                        "rows": 3
                                    }}
                                    labelText="Body"
                                />
                            </GridItem>
                            <GridItem md={12}>
                                <div
                                    className="upload-btn-wrapper"
                                    style={{"width": "100%"}}
                                >
                                    <button
                                        className="btn"
                                        style={{"marginRight": "10px"}}
                                    >
                                        <i className="fas fa-upload"></i> Choose file(s) to upload
                                    </button>
                                    <input
                                        accept=".pdf, .txt, .doc, .docx, .xls, .xlsx, .gif, .png, .bmp, .jpg, .jpeg"
                                        name="new_message_attachments"
                                        id="new_message_attachments"
                                        onChange={(event) => {
                                            let attachments = [];
                                            let errored_files = [];
                                            try {
                                                event.target.files.forEach(file => {
                                                    if (Math.round(file.size / 1024) >= 4096) {
                                                        errored_files.push(file);
                                                    } else {
                                                        attachments.push(file);
                                                    }
                                                });
                                            } catch (e) {}

                                            if (errored_files.length > 0) {
                                                this.setUsersNotification(
                                                    "One or more selected files exceeds the limit of 4MB per file and will not be uploaded",
                                                    "danger"
                                                );
                                            }

                                            this.setState({"new_message_attachments" : attachments});
                                        }}
                                        type="file"
                                        value=""
                                        multiple
                                    />
                                </div>
                                <div style={{display: this.state.new_message_attachments !== null && this.state.new_message_attachments.length > 0 ? "" : "none"}}>
                                    <p
                                        htmlFor="document"
                                        style={{"color": "#495057"}}
                                    >
                                        {this.state.new_message_attachments !== null && this.state.new_message_attachments.length > 0
                                            ? helpers.addLineBreaks(this.state.new_message_attachments.map((file, k) => {
                                                return file.name.toString();
                                            }).join("<br>"))
                                            : ""}
                                        <span style={{cursor: "pointer"}} onClick={() => {this.setState({new_message_attachments: null})}}>[Clear Attachment(s)]</span>
                                    </p>
                                </div>
                            </GridItem>
                            <GridItem md={12}>
                                <Button
                                    className={classes.modalSmallFooterFirstButton}
                                    color="transparent"
                                    onClick={() => {this.state.is_new_message ? this.setState({"show_message_dialog": false}) : this.setState({"show_new_message": false});}}
                                    style={{float: "right"}}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={classes.modalSmallFooterFirstButton}
                                    color="success"
                                    onClick={() => this.sendMessage()}
                                    style={{float: "right"}}
                                    simple
                                >
                                    Send
                                </Button>
                            </GridItem>
                        </GridContainer>

                    </DialogContent>
                    <DialogActions
                        className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                        style={{display: this.state.is_new_message ? "none" : ""}}
                    >
                        {/* {
                            this.state.updateApplication && !this.state.is_new_message
                            ? <Button
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color={"danger"}
                                simple
                                onClick={(event) => this.deleteMessage()}
                                >
                                    Delete
                            </Button>
                            : ""
                        } */}
                        {
                            this.state.updateApplication && !this.state.is_new_message && !this.state.show_new_message &&
                                (this.state.message_dialog_contents && this.state.message_dialog_contents.sender_details.sender !== 'System Notification')
                            ? <Button
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color={"success"}
                                simple
                                onClick={(event) => this.showReplyForm()}
                                >
                                    Reply
                            </Button>
                            : ""
                        }
                        <Button
                            className={classes.modalSmallFooterFirstButton}
                            color="transparent"
                            onClick={() => {this.setState({"show_message_dialog": false, "show_new_message": false});}}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

              </DialogContent>
              <DialogActions
                  className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                {
                    this.state.pages_count === (this.state.currentTab+1) && this.state.updateApplication
                    ? <Button
                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                        color="success"
                        onClick={(event) => {this.onUpdateStatusClick(event);}}
                        disabled={this.state.changeStatusBtnDisabled}
                    >
                        <b>Save Status</b>
                    </Button>
                    : ""
                }
                <Button
                    className={classes.modalSmallFooterFirstButton}
                    color="transparent"
                    onClick={() => {
                        this.setState({
                            "currentTab": 0,
                            "changeStatusBtnDisabled": true,
                            "additional_fields_values": [],
                            "change_status_current_status": "",
                            "change_status_additional_fields_values": [],
                            "change_status_message": "",
                            "approved_signatory_type": 'bo1',
                            "approved_signatory_email": '',
                            "approved_signatory_name": '',
                            "message": "",
                            "side_file_open": false,
                            "view_app_selected_request_fields": [],
                            "request_fields": this.initRequestableFields()
                        });
                        clearTimeout(this.timer); this.timer = null;
                        window.close();
                    }}
                >
                    Close
                </Button>
              </DialogActions>
          </div>
      );
  }
}

export default withStyles(extendedFormsStyle)(ViewApplicationStatusDialog);
