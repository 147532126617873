import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import ReactTable from "react-table";
import ThumbUp from "@material-ui/icons/ThumbUp";
// import ThumbDown from "@material-ui/icons/ThumbDown";
// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";
import {doLogout} from "../../store/actions/authActions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import TextField from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "../../customs/components/Pagination";
import Refresh from "@material-ui/icons/Refresh";
import Backspace from "@material-ui/icons/Backspace";
import axios from "../../axios/axios";
import axiosHelper from "axios/axiosHelper";
import helpers from "customs/helpers/helpers";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";
import moment from "moment";
import AccessControl from "customs/components/AccessControl";
// import Icon from '@material-ui/core/Icon';
import AddManagerToClientDialog from "customs/components/AddManagerToClientDialog";
import Assignment from "@material-ui/icons/Assignment";
import TimelineIcon from '@material-ui/icons/Timeline';
import ApplicationTimelineDialog from "customs/components/ApplicationTimelineDialog";
// import ChangeApplicationStatusDialog from "customs/components/ChangeApplicationStatusDialog";
// import ViewApplicationStatusDialog from "customs/components/ViewApplicationStatusDialog";
import EmojiFlags from "@material-ui/icons/EmojiFlags";
import Report from "@material-ui/icons/Report";

class Applications extends React.Component {

    constructor (props) {

        super(props);
        
        let addManager = AccessControl.checkRoutePermissions(
            "/clients",
            this.props.auth,
            "add_manager"
        );
        let updateApplication = AccessControl.checkRoutePermissions(
            "/applications",
            this.props.auth,
            "update"
        );
        let canApprove = AccessControl.checkRoutePermissions(
            "/applications",
            this.props.auth,
            "can_approve"
        );
        let open_application = props.location && props.location.state && props.location.state.application ? props.location.state.application : null;
        this.state = {
            "approvals": [],
            "approvals_pages": 1,
            "loading": false,
            "current_tab": "Details",
            "current_files": [],
            "current_approval": "",
            "expanded_details": false,
            "doneModalMsg": "",
            "tr_color": "success",
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": "",
            "approval_file": null,
            "selectManagerModal": false,
            "client_selected": null,
            "application_history": [],
            "selected_client_id": "",
            "selected_client_script_id": "",
            "selected_client_script_step_id": "",
            "selected_approval": null,
            "page_notes": null,
            "approval_message": [],
            "available_fields": {},
            "addManager": addManager,
            "updateApplication": updateApplication,
            "canApprove": canApprove,
            "applicationTimeline": false,
            "applicationView": false,
            "appViewClosed": true,
            "application_details": "",
            "application_message_sort": "received",
            "application_message_direction": "desc",
            "client_files": [],
            "mccs": [],
            "business_types": {},
            "registry_entries": [],
            "changeStatus": false,
            "application_status": null,
            "selected_application_id": null,
            "additional_fields": null,
            "changeStatusCallback": null,
            "addNoteCallback": null,
            "flagPageCallback": null,
            "high_risk_mccs": ["7995"],
            "nexxtap_supported_android_versions": ["11", "12", "13", "14"],
            "nexxtap_supported_android_min_version": "11",
            "onboarding_name": "Nexxtap",
            "filtered": open_application ? [
                {'id': "id", "value": open_application.id},
                // {'id': "company", "value": open_application.merchant_user.company},
                // {'id': "status", "value": open_application.status},
                // {'id': "name", "value": open_application.merchant_user.first_name + ' ' + open_application.merchant_user.last_name}
            ] : [],
            "open_application_details": 0, //open_application ? open_application.id : 0,
            "dont_focus_child": false
        };
        this.changeTabCallBack = this.changeTabCallBack.bind(this);
        this.getApprovalsFiles = this.getApprovalsFiles.bind(this);
        this.setStatus = this.setStatus.bind(this);
        this.handleAddManagerFromAddDialog = this.handleAddManagerFromAddDialog.bind(this);
        this.handleAddClientManager = this.handleAddClientManager.bind(this);
        this.handleDeleteClientManager = this.handleDeleteClientManager.bind(this);
        this.viewStatusTimeline = this.viewStatusTimeline.bind(this);
        this.viewApplication = this.viewApplication.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.refreshApprovalsTable = this.refreshApprovalsTable.bind(this);
        this.handleAddNote = this.handleAddNote.bind(this);
        this.setUsersNotification = this.setUsersNotification.bind(this);
        this.handleFlagPage = this.handleFlagPage.bind(this);

        this.parentComponent = this.parentComponent.bind(this);
        window.parentComponent = this.parentComponent;
        this.runComponentMethod = this.runComponentMethod.bind(this);
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.location && nextProps.location.state && nextProps.location.state.application && nextProps.location.state.from && nextProps.location.state.from === "dashboard") {
            let open_application = nextProps.location.state.application;
            this.setState({
                "filtered": [
                    {'id': "id", "value": open_application.id},
                    // {'id': "company", "value": open_application.merchant_user.company},
                    // {'id': "status", "value": open_application.status},
                    // {'id': "name", "value": open_application.merchant_user.first_name + ' ' + open_application.merchant_user.last_name}
                ],
                // "open_application_details": open_application.id
            }, function() {setTimeout(() => {this.refreshApprovalsTable()}, 100);});
        }
    }

    parentComponent(action, params) {
        this.runComponentMethod(action, params);
    }

    runComponentMethod(action, params) {
        if (this.state.applicationView && typeof this[action] === "function") {
            this.setState({'dont_focus_child': action === 'viewApplication'}, function() {
                this[action](...params);
            });
        }
    }

    handleDeleteClientManager (event, client_id, manager_id) {
        event.preventDefault();
        helpers.showLoading();
        this.setState({"btnDisabled": true});
        axios(this.props)
        .post(
                "/application/remove_manager",
                {
                    client_id,
                    "cm_user_ids": [manager_id]
                }
            )
            .then((response) => this.processDeleteManager(
                response,
                manager_id
            ))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    processDeleteManager (results, id) {
        if (this.isUnmounted) {
            return;
        }
        this.refReactApprovalsTable.fireFetchData();
    }

    handleAddManagerFromAddDialog (event, id) {
        event.preventDefault();
        this.handleAddManager(id);
    }

    handleAddManager (id) {
        helpers.showLoading();
        this.setState({"btnDisabled": true});
        axios(this.props)
          .post(
                "/application/assign_manager",
                {
                    "client_id": this.state.client_selected,
                    "cm_user_ids": [id]
                }
            )
            .then((response) => this.processAddManager(
                response,
                id
            ))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    handleAddClientManager (event, id) {
        event.preventDefault();
        helpers.showLoading();
        axios(this.props)
          .get(`/get_available_managers/${id}?limit=vc`)
          .then((response) => this.processFetchAvailableCMs(response, id))
          .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }
  
    processFetchAvailableCMs (results, client_id) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "selectManagerModal": true,
            "available_managers": results.data,
            "client_selected": client_id
        });
        helpers.hideLoading();
    }

    processAddManager (results, id) {
        if (this.isUnmounted) {
            return;
        }
        
        this.setState({
            "selectManagerModal": false,
            "available_managers": null,
            "client_selected": null
        }, function() {
            this.refReactApprovalsTable.fireFetchData();
        });
    }

    viewStatusTimeline (event, id) {
        event.preventDefault();
        helpers.showLoading();
        axios(this.props)
          .get(`/applications/${id}/status/history`)
          .then((response) => this.processViewStatusTimeline(response, id))
          .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }
  
    processViewStatusTimeline (results, id) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "applicationTimeline": true,
            "application_history": results.data
        });
        helpers.hideLoading();
    }

    viewApplication (event, approval, status, showLoading = true, callback = null, direction = 'desc', sort = 'received') {
        // let path = '/admin/application/' + approval.id;
        // if (this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed && this.viewApplicationWindow.location.href.endsWith(path)) {
        //     this.viewApplicationWindow.focus();
        //     return;
        // }

        if (event !== null && event !== 'messages' && event !== 'escalate_application' && event !== 'deescalate_application' && event !== 'generate_match_report' && event !== 'request_asic_search' && event !== 'request_sign_form') event.preventDefault();
        if (showLoading) helpers.showLoading();
        axios(this.props)
          .get('/applications/' + approval.id + '?sort=' + sort + '&direction=' + direction)
          .then((response) => {
            this.setState({
                "application_message_sort": sort,
                "application_message_direction": direction
            }, function() {
                this.processViewApplication(response, approval, status, callback, showLoading);
                if (event === 'escalate_application' || event === 'deescalate_application') {
                    this.refreshApprovalsTable();
                }
            });
          })
          .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }
  
    processViewApplication (results, approval, status, callback = null, showLoading = true) {
        if (this.isUnmounted) {
            return;
        }
        if (callback !== null) {
            callback();
        }
        if (status === 'application_submitted' || status === 'application_resubmitted') {
            this.refreshApprovalsTable(() => {this.loadViewApplication(results, approval, status, showLoading, false);});
        } else {
            this.loadViewApplication(results, approval, status, showLoading)
        }
    }

    componentDidMount () {
        window.onunload = function() {
            if (this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
                this.viewApplicationWindow.close();
                this.viewApplicationWindow = null;
            }
        }.bind(this);
    }

    componentWillUnmount() {
        if (this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
            this.viewApplicationWindow.close();
            this.viewApplicationWindow = null;
        }
    }

    viewApplicationWindow = null;
    loadViewApplication(results, approval, status, showLoading, clearLocalStorage = true) {
        status = results.data.application.status;
        approval.name = results.data.application.merchant_user.first_name + ' ' + results.data.application.merchant_user.last_name;
        approval.company = results.data.application.merchant_user.company;
        approval.risk = <span>
            <span title={results.data.application.risk === 'red' ? 'High Risk' : (results.data.application.risk === 'orange' ? 'Medium Risk' : (results.data.application.risk === 'green' ? 'Low Risk' : 'N/A'))}>
                <EmojiFlags style={{fontSize: "18px", color: results.data.application.risk ? results.data.application.risk : 'green'}} />
            </span>
            {
                approval.app_escalation !== null && approval.app_escalation.escalated === 1 && (
                    (this.props.auth.user.role === 'admin' && approval.app_escalation.to === 'admin') ||
                    (this.props.auth.user.role === 'tl' && (approval.app_escalation.to === 'tl' || approval.app_escalation.to === 'admin')) ||
                    (this.props.auth.user.role === 'vc')
                )
                ? <span title={'Escalated to ' + approval.app_escalation.to.toUpperCase()}>
                    <Report style={{fontSize: "18px", color: 'red'}} />
                </span>
                : ""
            }
        </span>;
        // approval.company = <span><EmojiFlags style={{fontSize: "18px", color: results.data.application.risk ? results.data.application.risk : 'green'}} />{results.data.application.merchant_user.company}</span>;
        approval.email = results.data.application.merchant_user.email;
        approval.created_at = moment.utc(results.data.created_at).format(helpers.date_format() + ' HH:mm:ss')
        approval.status = <span style={{"color": helpers.application_status_colors()[status] }}>{helpers.fixStatusLabel(status)}</span>;
        approval.page_notes = results.data.application.page_notes;
        approval.details = results.data.application.details;
        let app_state = {
            "applicationView": !this.state.appViewClosed,
            "appViewClosed": false,
            "client_files": results.data.client_files,
            "mccs": results.data.mccs,
            "business_types": results.data.business_types,
            "registry_entries": results.data.registry_entries,
            "application_history": results.data.status_history,
            "available_fields": results.data.available_fields,
            "selected_client_id": approval.merchant_user.client.id,
            "selected_client_script_id": results.data.selected_client_script_id,
            "selected_client_script_step_id": results.data.selected_client_script_step_id,
            "selected_approval": approval,
            "page_notes": approval.page_notes,
            "selected_application_id": approval.id,
            "high_risk_mccs": results.data.high_risk_mccs,
            "nexxtap_supported_android_versions": results.data.nexxtap_supported_android_versions,
            "nexxtap_supported_android_min_version": results.data.nexxtap_supported_android_min_version,
            "application_status": status,
            "approval_message": results.data.messages,
            "application_details": [results.data.application.merchant_user.company, approval.abn.length > 0 ? '(ABN: ' + helpers.abnFormat(approval.abn) + ')' : ''].join(' '),
            "onboarding_name": results.data.onboarding_name,
            "updateApplication": this.state.updateApplication,
            "canApprove": this.state.canApprove
        };
        this.setState(app_state);
        if (showLoading) helpers.hideLoading();

        if (clearLocalStorage) helpers.cleanLocalStorage('admin_app_');
        localStorage.setItem('admin_app_' + approval.id + '_state', JSON.stringify(app_state));
        let path = '/admin/application/' + approval.id;
        if (this.viewApplicationWindow === null || this.viewApplicationWindow.closed) {
            localStorage.setItem('admin_app_' + approval.id + '_props', JSON.stringify(this.props));
            try {
                this.viewApplicationWindow = window.open(path, '');
            } catch(e) {
                console.log(e);
                // this.setState({
                //     "doneModalMsg": "Cannot open application details, please try again. Error: " + e,
                //     "tr_color": "danger"
                // });
                // this.showUsersNotification();
            } finally {
                // if (!this.viewApplicationWindow) {
                //     this.setState({
                //         "doneModalMsg": "Cannot open application details, please try again.",
                //         "tr_color": "danger"
                //     });
                //     this.showUsersNotification();
                // }
            }
        } else {
            if (!this.viewApplicationWindow.location.href.endsWith(path)) {
                localStorage.setItem('admin_app_' + approval.id + '_props', JSON.stringify(this.props));
                this.viewApplicationWindow.location.href = path;
            }
        }
        if (!this.state.dont_focus_child && this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
            this.viewApplicationWindow.focus();
        }
        this.setState({"dont_focus_child": false}, function() {
            try {
                if (app_state.applicationView) this.viewApplicationWindow.updateComponent();
            } catch(e) {}
        });
    }

    changeStatus (event, id, status) {
        event.preventDefault();
        this.setState({
            "changeStatus": true,
            "application_status": status,
            "selected_application_id": id
        });
    }

    handleStatusChange(id, status, message, additional_fields_values, selected_request_fields, approved_signatory_type, approved_signatory_email, approved_signatory_name, callback = null, draftMessage = '') {
        this.setStatus(id, status, draftMessage ? draftMessage : message, additional_fields_values, selected_request_fields, approved_signatory_type, approved_signatory_email, approved_signatory_name, callback);
    }

    setStatus(id, status, message, additional_fields_values, selected_request_fields, approved_signatory_type, approved_signatory_email, approved_signatory_name, callback) {
        let additional_fields = [];
        for (var key of Object.keys(additional_fields_values)) {
            if (additional_fields_values[key] === 1) additional_fields.push({'key': key});
        }
        this.setState({
            // "confirmModal": true,
            // "confirmModalMsg": 'Change status to ' + helpers.fixStatusLabel(status) + ". Are you sure?",
            // "confirmModalTitle": "Change Application Status",
            // "action": "set_status",
            "selected_application_id": id,
            "application_status": status,
            "application_message": message,
            "additional_fields": status === 'awaiting_customer_response' ? additional_fields : null,
            "selected_request_fields": status === 'incomplete_-_please_provide_more_information' ? selected_request_fields : null,
            "approved_signatory_type": approved_signatory_type,
            "approved_signatory_email": approved_signatory_email,
            "approved_signatory_name": approved_signatory_name,
            "changeStatusCallback": callback
        }, function() {
            this.doSetStatus();
        });
    }

    doSetStatus () {
        this.setState({"confirmModal": false});
        const url = "/applications/" + this.state.selected_application_id + "/status",
            postObject = {
                "status": this.state.application_status,
                "message": this.state.application_message,
                "additional_fields": this.state.additional_fields,
                "selected_request_fields": this.state.selected_request_fields,
                "signatory": {
                    "type": this.state.approved_signatory_type,
                    "email": this.state.approved_signatory_type === 'custom' ? this.state.approved_signatory_email : null,
                    "name": this.state.approved_signatory_type === 'custom' ? this.state.approved_signatory_name : null
                }
            };

        helpers.showLoading();
        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then(() => {
                let notification = {
                    "doneModalMsg": "Status changed",
                    "tr_color": "success",
                    "action": null,
                    // "selected_application_id": null,
                    // "additional_fields": null,
                    // "application_status": null,
                    // "application_message": null,
                    "changeStatus": false,
                    // "changeStatusCallback": null
                };
                if (this.state.changeStatusCallback !== null && this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
                    // this.state.changeStatusCallback();
                    this.viewApplicationWindow.callbackComponent(this.state.changeStatusCallback, notification);
                }
                this.refreshApprovalsTable();
                this.setState(notification);
                this.showUsersNotification();

            })
            .catch((error) => {

                console.log(error);
                this.setState({
                    "doneModalMsg": error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('error') ? error.response.data.error : "An error has occurred, please try again.",
                    "tr_color": "danger"
                });
                this.showUsersNotification();
                helpers.hideLoading();

            });
    }

    handleAddNote(id, message, page, callback) {
        this.setState({
            // "confirmModal": true,
            // "confirmModalMsg": "Are you sure?",
            // "confirmModalTitle": "Add Note",
            // "action": "add_note",
            "selected_application_id": id,
            "application_message": message,
            "application_page": page,
            "addNoteCallback": callback
        }, function() {
            this.sendApplicationAddNoteRequest();
        });
    }

    sendApplicationAddNoteRequest () {
        this.setState({"confirmModal": false});
        const url = "/applications/" + this.state.selected_application_id + "/notes",
            postObject = {
                "notes": this.state.application_message,
                "page": this.state.application_page
            };

        helpers.showLoading();
        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then(() => {
                let notification = {
                    "doneModalMsg": "Note added",
                    "tr_color": "success",
                    "action": null,
                    // "selected_application_id": null,
                    // "additional_fields": null,
                    // "application_status": null,
                    // "application_message": null,
                    // "application_page": false,
                    // "addNoteCallback": null
                };
                this.refreshApprovalsTable(this.state.addNoteCallback !== null ? this.state.addNoteCallback : null);
                if (this.state.addNoteCallback !== null && this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
                    // this.state.addNoteCallback();
                    this.viewApplicationWindow.callbackComponent(null, notification);
                }
                this.setState(notification);
                this.showUsersNotification();

            })
            .catch((error) => {

                console.log(error);
                this.setState({
                    "doneModalMsg": "An error has occurred, please try again.",
                    "tr_color": "danger"
                });
                this.showUsersNotification();
                helpers.hideLoading();

            });
    }

    handleFlagPage(id, message, page, action, callback) {
        this.setState({
            "selected_application_id": id,
            "application_flag_message": message,
            "application_flag_action": action,
            "application_page": page,
            "flagPageCallback": callback
        }, function() {
            this.sendFlagApplicationPageRequest();
        });
    }

    sendFlagApplicationPageRequest () {
        this.setState({"confirmModal": false});
        const url = "/applications/" + this.state.selected_application_id + (this.state.application_flag_action !== 1 ? "/unflag" : "/flag"),
            postObject = {
                "notes": this.state.application_flag_message,
                "page": this.state.application_page
            };

        helpers.showLoading();
        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then(() => {
                let notification = {
                    "doneModalMsg": this.state.application_flag_action !== 1 ? "Page unflagged" : "Page flagged",
                    "tr_color": "success",
                    "action": null
                };
                this.refreshApprovalsTable(this.state.flagPageCallback !== null ? this.state.flagPageCallback : null);
                if (this.state.flagPageCallback !== null && this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
                    this.viewApplicationWindow.callbackComponent(null, notification);
                }
                this.setState(notification);
                this.showUsersNotification();

            })
            .catch((error) => {

                console.log(error);
                this.setState({
                    "doneModalMsg": "An error has occurred, please try again.",
                    "tr_color": "danger"
                });
                this.showUsersNotification();
                helpers.hideLoading();

            });
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
        if (modal === 'applicationView') {this.setState({"appViewClosed": true});}
    }
    
    handleConfirmClick () {
        if (this.state.action === "set_status") {
            this.doSetStatus();
        } else if (this.state.action === "submit_for_signing") {
            this.sendApplicationSignatureRequest(this.state.approval_file);
        } else if (this.state.action === "add_note") {
            this.sendApplicationAddNoteRequest();
        } else if (this.state.action === "flag_note") {
            this.sendFlagApplicationPageRequest();
        }
    }

    setFileProgress(file) {
        if (file.envelope_details !== null && file.envelope_details.hasOwnProperty('progress')
            && file.envelope_details.progress.hasOwnProperty('signed') && file.envelope_details.progress.hasOwnProperty('total')) {
            return 'Signatures: ' + file.envelope_details.progress.signed + ' of ' + file.envelope_details.progress.total + 
                (file.envelope_details.progress.hasOwnProperty('declined') && file.envelope_details.progress.declined !== 0
                    ? " %0ADeclined to sign: " + file.envelope_details.progress.declined : "") + 
                (file.envelope_details.progress.hasOwnProperty('error') && file.envelope_details.progress.error !== 0
                    ? " %0ASend email failed: " + file.envelope_details.progress.error : "");
        }
        return '';
    }

    processApprovalData (res) {
        // let open_dashboard_selected_application = null;
        // let open_dashboard_selected_application_status = null;
        this.setState({"available_fields": res.data.available_fields});
        const approvals = res.data.data.map((approval, index) => {
            let status = approval.status;
            approval.name = approval.merchant_user.first_name + ' ' + approval.merchant_user.last_name;
            approval.company = approval.merchant_user.company;
            approval.risk = <span>
                <span title={approval.risk === 'red' ? 'High Risk' : (approval.risk === 'orange' ? 'Medium Risk' : (approval.risk === 'green' ? 'Low Risk' : 'N/A'))}>
                    <EmojiFlags style={{fontSize: "18px", color: approval.risk ? approval.risk : 'green'}} />
                </span>
                {
                    approval.app_escalation !== null && approval.app_escalation.escalated === 1 && (
                        (this.props.auth.user.role === 'admin' && approval.app_escalation.to === 'admin') ||
                        (this.props.auth.user.role === 'tl' && (approval.app_escalation.to === 'tl' || approval.app_escalation.to === 'admin')) ||
                        (this.props.auth.user.role === 'vc')
                    )
                    ? <span title={'Escalated to ' + approval.app_escalation.to.toUpperCase()}>
                        <Report style={{fontSize: "18px", color: 'red'}} />
                    </span>
                    : ""
                }
            </span>;
            // approval.company = <span><EmojiFlags style={{fontSize: "18px", color: approval.risk ? approval.risk : 'green'}} />{approval.merchant_user.company}</span>;
            approval.email = approval.merchant_user.email;
            approval.created_at = moment.utc(approval.created_at).format(helpers.date_format() + ' HH:mm:ss')
            approval.status = <span style={{"color": helpers.application_status_colors()[status] }}>{helpers.fixStatusLabel(status)}</span>;
            approval.managers = approval.merchant_user.client.managers.map(
                (prop, key) => <div key={prop.id} className={'no-wrap'}>
                    {this.state.addManager
                        ? <i title={"Remove vetting clerk"} className={"fas fa-times"} style={{"color": "red", fontSize: "1rem", marginRight: "0.1rem", cursor: "pointer"}}
                            onClick={(event) => {
                                this.handleDeleteClientManager(
                                    event,
                                    approval.merchant_user.client.id,
                                    prop.id
                                )
                            }} />
                        : ''}
                    <span>{prop.first_name + ' ' + prop.last_name /*+ ' (' + prop.company + ')'*/}</span>
                </div>
            );
            if (this.state.addManager) {
                approval.managers[approval.merchant_user.client.managers.length] = 
                    <div key={9999} className={'no-wrap'}>
                        <span style={{cursor: "pointer"}}
                            onClick={(event) => {
                                this.handleAddClientManager(
                                    event,
                                    approval.merchant_user.client.id
                                )
                            }} >
                            <i title={"Add vetting clerk"} className={"fas fa-plus"} style={{"color": "green", fontSize: "0.9rem", marginRight: "0.1rem"}}/>
                            <span>{"Add Vetting Clerk"}</span>
                        </span>
                    </div>;
            }

            approval.actions =
                <div key={index}>
                    <Button
                        title={'Details'}
                        className="edit"
                        color="info"
                        justIcon
                        onClick={(event) => {
                            this.setState({"appViewClosed": false}, function() {
                                this.viewApplication(event, approval, status);
                            });
                        }}
                        round
                        simple
                    >
                        <Assignment />
                    </Button>
                    {/* {this.state.updateApplication
                        ?<Button
                            title={'Change Status'}
                            className="edit"
                            color="facebook"
                            justIcon
                            onClick={(event) => {this.changeStatus(event, approval.id, status);}}
                            round
                            simple
                        >
                            <ThumbUp />
                        </Button> : ''} */}
                    <Button
                        title={'Status Timeline'}
                        className="edit"
                        color="google"
                        justIcon
                        onClick={(event) => {
                            this.viewStatusTimeline(event, approval.id);
                        }}
                        round
                        simple
                    >
                        <TimelineIcon />
                    </Button>
                </div>
            ;

            if (this.state.applicationView === true && this.state.selected_application_id === approval.id) {
                let app_state = {
                    "applicationView": this.state.applicationView,
                    "appViewClosed": this.state.appViewClosed,
                    "client_files": this.state.client_files,
                    "mccs": this.state.mccs,
                    "business_types": this.state.business_types,
                    "registry_entries": this.state.registry_entries,
                    "application_history": this.state.status_history,
                    "available_fields": this.state.available_fields,
                    "selected_client_id": approval.merchant_user.client.id,
                    "selected_client_script_id": this.state.selected_client_script_id,
                    "selected_client_script_step_id": this.state.selected_client_script_step_id,
                    "selected_approval": approval,
                    "page_notes": approval.page_notes,
                    "selected_application_id": approval.id,
                    "high_risk_mccs": this.state.high_risk_mccs,
                    "nexxtap_supported_android_versions": this.state.nexxtap_supported_android_versions,
                    "nexxtap_supported_android_min_version": this.state.nexxtap_supported_android_min_version,
                    "application_status": status,
                    "approval_message": approval.messages,
                    "application_details": [approval.merchant_user.company, approval.abn.length > 0 ? '(ABN: ' + helpers.abnFormat(approval.abn) + ')' : ''].join(' '),
                    "onboarding_name": this.state.onboarding_name,
                    "updateApplication": this.state.updateApplication,
                    "canApprove": this.state.canApprove
                };
                this.setState(app_state);
                try {
                    // helpers.cleanLocalStorage('admin_app_');
                    localStorage.setItem('admin_app_' + approval.id + '_state', JSON.stringify(app_state));
                    if (app_state.applicationView && this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
                        this.viewApplicationWindow.updateComponent();
                    }
                } catch(e) {}
            }

            if (this.state.refresh_approvals_callback && this.state.refresh_approvals_callback !== null) {
                this.state.refresh_approvals_callback();
                this.setState({"refresh_approvals_callback": null});
            }

            if (this.state.refresh_approvals_childCallback && this.state.refresh_approvals_childCallback !== null) {
                try {
                    if (this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
                        this.viewApplicationWindow.callbackComponent(this.state.refresh_approvals_childCallback);
                    }
                } catch(e) {}
                this.setState({"refresh_approvals_childCallback": null});
            }
            // if (this.state.open_application_details && this.state.open_application_details !== 0 && this.state.open_application_details === approval.id) {
            //     open_dashboard_selected_application = approval;
            //     open_dashboard_selected_application_status = status;
            // }

            return approval;

        });
        this.setState({
            // "roles": res.data.roles,
            approvals,
            approvals_pages: res.data.last_page,
            loading: false,
            "items_data": res.data.from && res.data.to && res.data.total
              ? `${res.data.from} - ${res.data.to} of ${res.data.total}`
              : "",
        });

        // if (open_dashboard_selected_application !== null) {
        //     this.setState({'open_application_details': 0}, function() {
        //         try {
        //             this.viewApplication(null, open_dashboard_selected_application, open_dashboard_selected_application_status, true);
        //         } catch(e) {}
        //     });
        // }

    }

    setUsersNotification (msg, color) {
        this.setState({"doneModalMsg": msg, "tr_color": color});
        this.showUsersNotification();
    }

    hideNotification;
    showUsersNotification () {
        if (!this.state.tr) {
            this.setState({"tr": true});
        } else {
            clearTimeout(this.hideNotification);
        }
        this.setHideNotificationTimeout();
    }

    setHideNotificationTimeout () {
        this.hideNotification = setTimeout(
            () => {
                this.handleCloseNotification();
            },
            this.state.tr_color === "success" ? 5000 : 10000
        );
    }

    handleCloseNotification () {
        clearTimeout(this.hideNotification);
        this.setState({"tr": false, "doneModalMsg": ""});
    }

    getApprovalsData (page, pageSize, sorted, filtered, handleRetrievedData) {

        const url = "/applications",
            postObject = {
                "page": page + 1,
                "per_page": pageSize,
                "sort": sorted,
                "filter": filtered,
            };

        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then((response) => {

                handleRetrievedData(response);
                helpers.hideLoading();

            })
            .catch((error) => {

                console.log(error);

            });

    }

    getApprovalsFiles (files, approval_id) {
        this.setState({"current_files": files, "approval_id": approval_id});
    }

    changeTabCallBack (tab) {
        this.setState({"current_tab": tab});
    }

  CustomTableFilter = ({filter, onChange}) => (<form>
        <TextField
          className="w-100"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            )
          }}
          onChange={event => onChange(event.target.value)}
          type="search"
          value={filter ? filter.value : ""}
          />
      </form>)
    ;

  handleDownloadFile = (event, approval) => {
    event.preventDefault();
    const FileDownload = require("js-file-download");
    helpers.showLoading();
      axios(this.props)
        .get(
            `client/${approval.client_id}/file/${approval.client_file_id}/download`,
            {
                "responseType": "blob",
                "timeout": 30000
            }
        )
        .then((response) => {
            helpers.hideLoading();
            FileDownload(
                response.data,
                approval.file_store.filename
            );
        })
        .catch((error) => this.processErrorAxios(
            error,
            null
        ));

  };

    handleApplicationSignature = (event, file) => {
        event.preventDefault();
        if (file.signature_status === "PENDING") {
          this.sendApplicationSignatureRequest(file);
        } else if (file.signature_status === "NOT SUBMITTED") {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Request Signature",
                "action": "submit_for_signing",
                "approval_file": file
            });
        }
    };

    sendApplicationSignatureRequest(file) {
        helpers.showLoading();
        this.setState({"confirmModal": false});
        axios(this.props)
            .post(`client/${file.client_id}/sign/application/${file.id}` + (file.signature_status === "NOT SUBMITTED" ? '' : '/status'))
            .then((response) => {
                helpers.hideLoading();
                if (file.signature_status === "NOT SUBMITTED") {
                    this.setState({
                        "doneModalMsg": response.data.status === "success" ? "Signature request submitted." : "An error has occurred, please try again.",
                        "tr_color": response.data.status === "success" ? "success" : "warning"
                    });
                } else {
                    this.setState({
                        "doneModalMsg": (response.data.status === "SIGNED" ? "Application already signed." : "Awaiting signature(s).") +
                                    (response.data.hasOwnProperty('error_msg') ? "<br>" + response.data.error_msg : ""),
                        "tr_color": response.data.status === "SIGNED" ? "success" : "warning"
                    });
                }
                this.showUsersNotification();

                this.refreshApprovalsTable();
            })
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

  processErrorAxios (error, prop) {

        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            () => {
                this.showUsersNotification();
            },
            () => {
                this.props.doLogout({...this.props});
            }
        );

    }

    refreshApprovalsTable(callback = null, childCallback = null) {
        try {
            if (callback !== null) {this.setState({"refresh_approvals_callback": callback});}
            if (childCallback !== null) {this.setState({"refresh_approvals_childCallback": childCallback});}
            this.refReactApprovalsTable.fireFetchData();
        } catch(e) {}
    }

  render () {

      const authService = new AuthService(
          this.state,
          this.props
      );
      if (!authService.getToken()) {
          return <Redirect to="/auth/login" />;
      }


      if (authService.isPasswordExpired()) {
          return <Redirect to="/admin/changepassword" />;
      }
      const {classes} = this.props,
          {approvals} = this.state;
      return (
          <GridContainer>
              <GridItem xs={12}>
                  <Card>
                      <CardHeader
                          color="success"
                          icon
                      >
                          <CardIcon color="success">
                              <ThumbUp />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                              Applications
                          </h4>
                          <CardIcon
                              title={'Refresh applications table'}
                              className="float-button"
                              color="warning"
                              onClick={() => this.refreshApprovalsTable()}
                          >
                              <Refresh />
                          </CardIcon>
                          <CardIcon
                              title={'Reset filter'}
                              className="float-button"
                              color="info"
                              onClick={() => {this.setState({'filtered': []}, function () {setTimeout(() => {this.refreshApprovalsTable()}, 100);});}}
                          >
                              <Backspace />
                          </CardIcon>
                      </CardHeader>
                      <CardBody>
                          <ReactTable
                            filtered={this.state.filtered}
                            onFilteredChange={filtered => {this.setState({ filtered });}}
                            defaultSorted={[{ id: 'created_at', desc: true }]}
                              className="-striped -highlight"
                              columns={
                                [
                                    {
                                        "Header": "Risk",
                                        "accessor": "risk",
                                        "width": 75,
                                        "Filter": ({filter, onChange}) => (<select
                                                onChange={(event) => onChange(event.target.value)}
                                                style={{"width": "100%", "height": "3.2rem",
                                                    fontSize: ".875rem"}}
                                                value={filter ? filter.value : ""}
                                            >
                                                <option value="" />
                                                <option key={1} value={'red'}>{'High'}</option>
                                                <option key={2} value={'orange'}>{'Medium'}</option>
                                                <option key={3} value={'green'}>{'Low'}</option>
                                                <option key={4} value={'silver'}>{'N/A'}</option>
                                            </select>)
                                    },
                                    {
                                        "Header": "ID",
                                        "accessor": "id",
                                        "width": 70,
                                        "Filter": this.CustomTableFilter
                                    },
                                    {
                                        "Header": "Submitted",
                                        "accessor": "created_at",
                                        "sortable": true,
                                        "filterable": false
                                    },
                                    {
                                        "Header": "Name",
                                        "accessor": "name",
                                        "Filter": this.CustomTableFilter
                                    },
                                    {
                                        "Header": "Email",
                                        "accessor": "email",
                                        "Filter": this.CustomTableFilter
                                    },
                                    // {
                                    //     "Header": "Company",
                                    //     "accessor": "company",
                                    //     "Filter": this.CustomTableFilter
                                    // },
                                    {
                                        "Header": "Merchant Name",
                                        "accessor": "merchant_name",
                                        "Filter": this.CustomTableFilter
                                    },
                                    {
                                        "Header": "Business Name",
                                        "accessor": "business_name",
                                        "Filter": this.CustomTableFilter
                                    },
                                    {
                                        "Header": "Approval Status",
                                        "accessor": "status",
                                        "sortable": true,
                                        "Filter": ({filter, onChange}) => (<select
                                                onChange={(event) => onChange(event.target.value)}
                                                style={{"width": "100%", "height": "3.2rem",
                                                    fontSize: ".875rem"}}
                                                value={filter ? filter.value : "all"}
                                            >
                                                <option value="" />
                                                <option key={1} value={'approved'}>{'Approved'}</option>
                                                <option key={2} value={'conditional_approved'}>{'Conditional Approved'}</option>
                                                <option key={3} value={'declined'}>{'Declined'}</option>
                                                <option key={4} value={'application_submitted'}>{'Application Submitted'}</option>
                                                <option key={5} value={'application_resubmitted'}>{'Application Resubmitted'}</option>
                                                <option key={6} value={'application_in_review'}>{'Application In Review'}</option>
                                                <option key={7} value={'awaiting_customer_response'}>{'Awaiting Customer Response'}</option>
                                                <option key={8} value={'not_suitable'}>{'Not Suitable'}</option>
                                                <option key={9} value={'incomplete_-_please_provide_more_information'}>{'Incomplete - Please Provide More Information'}</option>
                                            </select>)
                                    },
                                    {
                                        "Header": "Vetting Clerk(s)",
                                        "accessor": "managers",
                                        "show": this.state && this.state.hasOwnProperty('addManager') && this.state.addManager,
                                        "sortable": false,
                                        "Filter": this.CustomTableFilter,
                                        // "Filter": ({filter, onChange}) => (<select
                                        //         onChange={(event) => onChange(event.target.value)}
                                        //         style={{"width": "100%", "height": "3.2rem",
                                        //             fontSize: ".875rem"}}
                                        //         value={filter ? filter.value : "all"}
                                        //     >
                                        //         <option value="" />
                                        //         {this.state.roles
                                        //             ? this.state.roles.map((prop, key) => 
                                        //         <option key={key} value={prop["id"]}>
                                        //           {prop["description"]}
                                        //         </option>
                                        //       )
                                        //             : ""}
                                        //     </select>)
                                    },
                                    {
                                        "Header": "Actions",
                                        "accessor": "actions",
                                        "sortable": false,
                                        "filterable": false,
                                        "width": 150
                                    }
                                ]
                              }
                              data={approvals}
                              defaultPageSize={10}
                              filterable
                              manual
                              loading={this.state.loading}
                              onFetchData={(state, instance) => {

                                this.setState({"loading": true});
                                  this.getApprovalsData(
                                      state.page,
                                      state.pageSize,
                                      state.sorted,
                                      state.filtered,
                                      (res) => this.processApprovalData(res)
                                  );

                              }}
                              pages={this.state.approvals_pages}
                              PaginationComponent={Pagination}
                              ref={(refReactApprovalsTable) => {

                                  this.refReactApprovalsTable = refReactApprovalsTable;

                              }}
                              itemsCountText={this.state.items_data}
                              showPageSizeOptions
                              showPaginationBottom
                              showPaginationTop={false}
                          />
                      </CardBody>
                  </Card>
              </GridItem>
              <GridItem xs={12}>
                  <style>
                      {"@media (min-width: 768px) {.MuiTabs-flexContainer {float: right;}}" +
                ".CustomTabs-cardTitle-1018 {font-weight: bold;} .MuiTabs-scroller {overflow: auto !important;}" +
                ".MuiTableCell-head {font-size: 0.9rem !important;}" +
                ".MuiTableCell-body {padding: 0px 0px 0px 8px! important;}"}
                  </style>
              </GridItem>
                  {this.state.doneModalMsg
                      ? <Snackbar
                          close
                          closeNotification={() => {
                              this.handleCloseNotification();
                          }}
                          color={this.state.tr_color}
                          icon={AddAlert}
                          message={this.state.doneModalMsg}
                          open={this.state.tr}
                          place="tr"
                      />
                      : ""}
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.handleClose("confirmModal")}
                    onYesClick={() => this.handleConfirmClick()}
                />
                <AddManagerToClientDialog
                    handleAddManagerFromAddDialog={this.handleAddManagerFromAddDialog}
                    handleAddNewManager={null}
                    onClose={() => this.handleClose("selectManagerModal")}
                    {...this.state}
                    dialogTitle={'Vetting Clerks'}
                />
                <ApplicationTimelineDialog
                    onClose={() => this.handleClose("applicationTimeline")}
                    {...this.state}
                />
                {/* <ViewApplicationStatusDialog
                    onClose={() => this.handleClose("applicationView")}
                    handleStatusChange={this.handleStatusChange}
                    handleAddNote={this.handleAddNote}
                    refreshApprovalsTable={this.refreshApprovalsTable}
                    viewApplication={this.viewApplication}
                    {...this.state}
                    auth={this.props.auth}
                    setUsersNotification={this.setUsersNotification}
                /> */}
                {/* <ChangeApplicationStatusDialog
                    onClose={() => this.handleClose("changeStatus")}
                    handleStatusChange={this.handleStatusChange}
                    {...this.state}
                /> */}
          </GridContainer>
      );

  }
}

Applications.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doLogout": (props) => dispatch(doLogout(props))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(Applications));
